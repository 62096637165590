import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  justify-content: 'center';
  align-items: 'center';
`;

export const Header = styled.header`
  flex: 1;
  padding: 30px;
  background: #28262e;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  > img {
    height: 70px;
  }
`;

export const HeaderTitle = styled.p`
  font-family: 'Arial', serif;
  font-size: 25px;
  color: #cccccc;
  margin-top: 25px;
  border-width: 2px;
  border-color: #cccccc;
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 60px auto;
  padding-bottom: 30px;
  border-radius: 7px;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }
  p {
    font-size: 40px;
    color: #ffffff;
    margin-top: 20px;
  }
`;

export const ContentButton = styled.main`
  max-width: 220px;
  margin: 60px auto;
`;
