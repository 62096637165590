import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import Swal from 'sweetalert2';

import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeaderDados,
  ContainerDados,
  ContainerDadosArquivo,
  ContainerButtonCancelar,
  ContainerButtonSalvar,
} from './modalStyles';

interface ModalArquivo {
  onClose(): void;
  onSave?(arg: FileList): void;
  nome: string;
}

const ModalArquivo: React.FC<ModalArquivo> = ({
  nome,
  onClose = () => {
    ('');
  },
  onSave = () => {
    ('');
  },
}) => {
  const [temArquivo, setTemArquivo] = useState(false);
  const [fileData, setFileData] = useState<FileList>();
  // const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUploadArquivo = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // try-catch para pegar o arquivo
      try {
        if (e.target.files) {
          setFileData(e.target.files);
          // console.log(e.target.files);
          setTemArquivo(true);
        }
      } catch (err) {
        Swal.fire({
          icon: 'info',
          title: 'Erro ao carregar dados, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [],
  );

  const handleAlert = useCallback(() => {
    Swal.fire({
      icon: 'info',
      title: 'Campo carregar arquivo é obrigatório!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }, []);

  return (
    <>
      <div>
        <ContainerModal id="modal">
          <Container>
            <ContainerButton>
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                X
              </button>
            </ContainerButton>
            <ContainerHeaderDados>
              <p>{`Envio de Documentos do ${nome} para o Derpartamento Pessoal`}</p>
            </ContainerHeaderDados>
            <ContainerDados>
              <label htmlFor="pdf">
                <div className="Container">
                  {!temArquivo && (
                    <div className="ContainerBloco">
                      <div className="teste">
                        <div className="ContainerIcon">
                          <FiUploadCloud />
                        </div>
                      </div>
                      <p>Carregar Arquivo - Solicitação de Vaga</p>
                    </div>
                  )}
                  {temArquivo && (
                    <ContainerDadosArquivo>
                      {fileData && (
                        <div className="ContainerArquivo">
                          <p>{`Nome do Arquivo: ${fileData[0].name}`}</p>
                          <p>
                            {`Tamanho do Arquivo ${(
                              fileData[0].size /
                              1024 /
                              1024
                            ).toFixed(2)} MB`}
                          </p>
                        </div>
                      )}
                    </ContainerDadosArquivo>
                  )}
                  <input
                    type="file"
                    id="pdf"
                    onChange={(e) => handleUploadArquivo(e)}
                    accept="application/pdf"
                  />
                </div>
              </label>
            </ContainerDados>
            <ContainerButtonCancelar>
              <button type="button" onClick={() => onClose()}>
                Cancelar
              </button>
            </ContainerButtonCancelar>
            <ContainerButtonSalvar>
              <button
                type="button"
                onClick={() => {
                  if (fileData) {
                    onSave(fileData);
                  } else {
                    handleAlert();
                  }
                }}
              >
                Enviar DP
              </button>
            </ContainerButtonSalvar>
          </Container>
        </ContainerModal>
      </div>
    </>
  );
};

export default ModalArquivo;
