import React, { useState, useEffect, useMemo } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { ContainerItem } from './styles';

interface Paginate {
  total: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (arg: number) => void;
}

const PaginationComponent: React.FC<Paginate> = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
}: Paginate) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (itemsPerPage === 1) {
      setTotalPages(1);
    } else if (total > 0 && itemsPerPage > 0) {
      setTotalPages(Math.ceil(total / itemsPerPage));
    }
  }, [total, itemsPerPage, totalPages]);

  const paginationItems = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i += 1) {
      // console.log(`teste page--${totalPages} -- ${i}`);
      if (!(currentPage > i + 5 || currentPage < i - 5)) {
        pages.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => onPageChange(i)}
          >
            {i}
          </Pagination.Item>,
        );
      }
    }

    return pages;
  }, [totalPages, currentPage]); // eslint-disable-line

  if (totalPages === 0) return null;

  return (
    <>
      <ContainerItem>
        <Pagination>
          {/* <Pagination.Prev
          disabled={currentPage === 1}
          //onClick={() => onPageChange(currentPage - 1)}
          />
         */}
          {paginationItems}
          {/* <Pagination.Next
            //onClick={ () => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          /> */}
        </Pagination>
      </ContainerItem>
    </>
  );
};

export default PaginationComponent;
