export const headers = [
  { name: 'Nome', field: 'nome' },
  { name: 'CPF', field: 'cpf' },
  { name: 'Filial', field: 'filial' },
  { name: 'Matrícula', field: 'matricula' },
  { name: 'Ficha Médica', field: 'fichaMedicaNumber' },
  { name: 'Possui Ficha Médica', field: 'fichaMedica' },
  { name: 'Código de Ausência', field: 'codAusencia' },
  { name: 'Afastamento RAIS', field: 'afastRais' },
  { name: 'ID Atestado', field: 'idAtestado' },
  { name: 'Possui motivo para abonar', field: 'falta' },
  { name: 'Abono', field: 'abono' },
  { name: 'Médico', field: 'medico' },
  { name: 'Tipo do código', field: 'typeMedico' },
  { name: 'CRM', field: 'crm' },
  { name: 'Possui CRM no Protheus', field: 'hasCRMProtheus' },
  { name: 'CRO', field: 'cro' },
  { name: 'Possui CRO no Protheus', field: 'hasCROProtheus' },
  { name: 'CID Grupo', field: 'cidgrupo' },
  { name: 'CID', field: 'cid' },
  { name: 'Local', field: 'local' },
  { name: 'Data Inicial', field: 'dataInicio' },
  { name: 'Data Final', field: 'dataFinal' },
  { name: 'Quantidade de Dias', field: 'qtdDias' },
  { name: 'Número alterações', field: 'qdtDias' },
  { name: 'Arquivo', field: 'arquivo' },
  { name: 'Enviado para o Protheus', field: 'envProtheus' },
  { name: 'Validar', field: 'validar' },
  { name: 'Motivo', field: 'motivo' },
];
