import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import * as MdIcons from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';
import {
  Container,
  ContainerLinha,
  Profile,
  ContainerButtonDrawer,
  ButtonSignOut,
} from './styles';
import MenuLateral from '../Menu';
import avatar from '../../assets/avatar2.png';
import { useAuth } from '../../hooks/auth';
import logoMenu from '../../assets/logoMenu.png';
import 'antd/dist/antd.css';

const DrawerApp: React.FC = () => {
  const { signOut } = useAuth();
  const [visible, setVisible] = useState(false);
  const { user } = useAuth();

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
  };

  return (
    <>
      <Container>
        <ContainerButtonDrawer>
          <Button type="text" onClick={showDrawer}>
            <MdIcons.MdMenu size={40} color="#fff" />
          </Button>
          <Link to="/dashboard">
            <img src={logoMenu} alt="Logo Servnac" />
          </Link>
          <ButtonSignOut onClick={signOut}>
            <FiPower color="#ffffff" style={{ height: 25, width: 25 }} />
          </ButtonSignOut>
        </ContainerButtonDrawer>

        <Drawer
          // title="Basic Drawer"/
          width="auto"
          bodyStyle={{
            paddingLeft: 10,
            paddingRight: 10,
            background: '#004d00',
          }}
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <Profile>
            <Link to="/profile">
              <div>
                <img
                  src={user.avatar_url ? user.avatar_url : avatar}
                  alt="Foto Avatar"
                  style={{
                    backgroundColor: '#f2f2f2',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </Link>
            <Link to="/profile">
              <div>
                {/* <span>Bem-vindo</span> */}
                <span>{user.nome}</span>
                <strong>{user.email}</strong>
              </div>
            </Link>
          </Profile>
          <ContainerLinha />
          <MenuLateral />
        </Drawer>
      </Container>
      <div style={{ height: 50 }} />
    </>
  );
};

export default DrawerApp;
