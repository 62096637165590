/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  FiEye,
  FiCheckCircle,
  FiXCircle,
  FiFileText,
  FiSave,
} from 'react-icons/fi';
import { AiFillDislike, AiFillLike } from 'react-icons/ai';
import { BsFillQuestionSquareFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import Search from '../../components/Search';
import HeaderTable from '../../components/HeaderTable';
import { maskCpf } from '../../components/Input/mask';
import ModalConexao from '../../components/ModalConexao';

import {
  Container,
  ContainerSearch,
  Aba,
  ContainerAba,
  ContainerTable,
  ContainerValidar,
  ContainerTableValidar,
  Content,
  ButtonBorder,
  ButtonBorderIconTrue,
  ButtonBorderIconFalse,
  ContainerVisualizarVazio,
  ButtonBorderIconFalseRespostaDp,
  ButtonBorderIconTrueRespostaDp,
} from './styles';
import DrawerApp from '../../components/Drawer';

interface ValidacaoSolVaga {
  Id_UserPrecadastro: number;
  NOME: string;
  CPF: string;
  DOC_ACEITO: boolean;
  DOCUMENTACAO: string;
  Id_Dom_Documentacao: number;
  LINK_IMAGEM: string;
  DP_APROVADO: boolean;
  DATA_APROVACAO_DP: string;
  RH_ENVIADO_DP_DATA_HORA: string;
  USER_DP_ID: number;
  USER_DP_NOME: string;
  USER_RH_ID: number;
  USER_RH_NOME: string;
}

const setores = [
  { valor: 'Departamento Pessoal', id: 14, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const SolicitacaoVaga: React.FC = () => {
  const { user, userAcesso } = useAuth();
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [searchAprovadorRH, setSearchAprovadorRH] = useState('');
  const [response, setResponse] = useState<ValidacaoSolVaga[]>([]);
  const [copiaResponse, setCopiaResponse] = useState<ValidacaoSolVaga[]>([]);
  const [dataValidacao, setDataValidacao] = useState<ValidacaoSolVaga[]>([]);
  const [userDados, setUserDados] = useState<ValidacaoSolVaga>();
  const [loading, setLoading] = useState(false);
  const [visualizarValidacao, setVisualizarValidacao] = useState(false);
  const [dadoIcon, setDadoIcon] = useState(false);
  const [docId, setDocId] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [visualizarVazio, setVisualizarVazio] = useState(false);
  const [aceito, setAceito] = useState(false);

  const headers = [
    { name: 'Documento', field: 'doc' },
    { name: 'Arquivo', field: 'arq' },
    { name: 'Situação RH', field: 'situacaoRH' },
    { name: 'Validar', field: 'validar' },
  ];

  const headersValidacao = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Aprovador RH', field: 'aprRH' },
    { name: 'Data Aprovação RH', field: 'data' },
    { name: 'Solicitação de Vaga', field: 'solicitacaoVaga' },
    { name: 'Validar Documentação', field: 'validar' },
  ];

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((u) => {
      access = setores.filter(
        (setor) => setor.id === u.SetorID && u.NivelACesso >= setor.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`/documentacao/getDocumentacaoDP`)
        .then((data) => {
          setResponse(data.data);
          // console.log(data.data);
          setCopiaResponse(JSON.parse(JSON.stringify(data.data)));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    }
  }, [history, userAcesso]);

  useMemo(() => {
    dataValidacao.forEach((data: ValidacaoSolVaga) => {
      if (data.Id_Dom_Documentacao === docId) {
        // eslint-disable-next-line no-param-reassign
        data.DP_APROVADO = dadoIcon;
      }
      return data;
    });
    setDataValidacao(dataValidacao);
  }, [dataValidacao, docId, dadoIcon]);

  const handlePreencher = useCallback(
    (dados: ValidacaoSolVaga) => {
      // setNome(!dados.nome);
      let computedResponses: ValidacaoSolVaga[] = [];
      computedResponses = response;
      computedResponses = computedResponses.filter(
        (cpf) => cpf.CPF === dados.CPF,
      );
      setDataValidacao(computedResponses);
    },
    [response],
  );

  const handlePreencherESalvarDados = useCallback(async () => {
    const arrayDocId: number[] = [];
    const arrayDocAceito: boolean[] = [];
    dataValidacao.forEach((data: ValidacaoSolVaga) => {
      // console.log(data);
      // console.log(data.MOTIVO_REJEICAO_RH);
      copiaResponse.forEach((dados) => {
        if (
          data.DP_APROVADO !== null &&
          data.DP_APROVADO !== dados.DP_APROVADO &&
          data.Id_Dom_Documentacao === dados.Id_Dom_Documentacao &&
          data.CPF === dados.CPF
        ) {
          if (data.DP_APROVADO !== null) {
            arrayDocId.push(data.Id_Dom_Documentacao);
            arrayDocAceito.push(data.DP_APROVADO);
          }
        }
      });
    });
    try {
      setLoading(true);
      await api
        .post('/documentacao/aprovarDocumentacaoDP', {
          userId,
          documentoId: arrayDocId,
          aprova_dp: arrayDocAceito,
          user_dp_nome: user.nome,
          user_dp_id: user.id,
          user_dp_mei: '',
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Aprovação de Solicitação de Vaga aprovado com sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setVisualizarVazio(false);
          setLoading(false);
          const vis = setTimeout((): void => {
            history.go(0);
          }, 1500);
          return () => {
            clearTimeout(vis);
          };
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao aprovar Solicitação de vaga.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          setLoading(false);
          setVisualizarValidacao(true);
        });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Erro, ao aprovar Solicitação de vaga. Tente Novamente.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setLoading(false);
      setVisualizarValidacao(true);
    }
    setDataValidacao(dataValidacao);
  }, [dataValidacao, copiaResponse, userId, user.nome, user.id, history]);

  function arrayUnique(data: ValidacaoSolVaga[]): ValidacaoSolVaga[] {
    const result: ValidacaoSolVaga[] = [];
    const map = new Map();
    data.forEach((item) => {
      if (item.Id_Dom_Documentacao === 24) {
        if (!map.has(item.CPF) && item.CPF !== null) {
          map.set(item.CPF, true);
          result.push({
            Id_UserPrecadastro: item.Id_UserPrecadastro,
            NOME: item.NOME,
            CPF: item.CPF,
            DOC_ACEITO: item.DOC_ACEITO,
            DOCUMENTACAO: item.DOCUMENTACAO,
            Id_Dom_Documentacao: item.Id_Dom_Documentacao,
            LINK_IMAGEM: item.LINK_IMAGEM,
            DATA_APROVACAO_DP: item.DATA_APROVACAO_DP,
            DP_APROVADO: item.DP_APROVADO,
            RH_ENVIADO_DP_DATA_HORA: item.RH_ENVIADO_DP_DATA_HORA,
            USER_DP_ID: item.USER_DP_ID,
            USER_DP_NOME: item.USER_DP_NOME,
            USER_RH_ID: item.USER_RH_ID,
            USER_RH_NOME: item.USER_RH_NOME,
          });
        }
      }
    });
    return result;
  }

  const responseData = useMemo(() => {
    let computedResponses: ValidacaoSolVaga[] = [];
    computedResponses = arrayUnique(response);
    if (aceito) {
      computedResponses = computedResponses.filter(
        (res: ValidacaoSolVaga) =>
          res.DP_APROVADO || !(res.DP_APROVADO === null),
      );
    }
    if (!aceito) {
      computedResponses = computedResponses.filter(
        (res: ValidacaoSolVaga) => res.DP_APROVADO === null,
      );
    }

    if (search) {
      computedResponses = computedResponses.filter((res: ValidacaoSolVaga) =>
        res.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ValidacaoSolVaga) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    if (searchAprovadorRH) {
      computedResponses = computedResponses.filter((res: ValidacaoSolVaga) =>
        res.USER_RH_NOME?.toLowerCase().includes(
          searchAprovadorRH.toLowerCase(),
        ),
      );
    }

    return computedResponses;
  }, [aceito, response, search, searchAprovadorRH, searchCPF]);

  const handleErrorVisualizarValidacao = useCallback(() => {
    Swal.fire({
      icon: 'info',
      title: 'Por favor, cancele a visualização da validação de documentos!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }, []);

  function formatarData(data: string | undefined): string {
    if (data) {
      const ano = data.substring(0, 4);
      const mes = data.substring(5, 7);
      const dia = data.substring(8, 10);
      return `${dia}-${mes}-${ano}`;
    }
    return '';
  }

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      <Container>
        {!visualizarValidacao && (
          <div>
            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder="Buscar Nome"
              />
              <div className="search">
                <Search
                  onSearch={(value: string) => {
                    setSearchCPF(value);
                  }}
                  nomePlaceHolder="Buscar CPF"
                />
              </div>
              <div className="searchRH">
                <Search
                  onSearch={(value: string) => {
                    setSearchAprovadorRH(value);
                  }}
                  nomePlaceHolder="Buscar Aprovador RH"
                />
              </div>
              <Aba>
                <ContainerAba cor={aceito}>
                  <button
                    style={{ borderTopLeftRadius: 7 }}
                    type="button"
                    onClick={() => setAceito(true)}
                  >
                    Avaliado
                  </button>
                </ContainerAba>
                <ContainerAba cor={!aceito}>
                  <button
                    style={{ borderTopRightRadius: 7 }}
                    type="button"
                    onClick={() => setAceito(false)}
                  >
                    Pendente
                  </button>
                </ContainerAba>
              </Aba>
            </ContainerSearch>
            <ContainerTable>
              <table id="pendentes">
                <HeaderTable headers={headersValidacao} />
                <tbody>
                  {responseData.map((res: ValidacaoSolVaga) => (
                    <tr key={res.CPF}>
                      <td style={{ minWidth: 250 }}>{res.NOME}</td>
                      <td>{maskCpf(res.CPF)}</td>
                      <td style={{ minWidth: 200 }}>{res.USER_RH_NOME}</td>
                      <td>{formatarData(res.RH_ENVIADO_DP_DATA_HORA)}</td>
                      <td
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {res.DP_APROVADO === true ? (
                          <ButtonBorderIconTrueRespostaDp>
                            <AiFillLike
                              style={{ color: '#f2f2f2' }}
                              size={19}
                            />
                          </ButtonBorderIconTrueRespostaDp>
                        ) : res.DP_APROVADO === false ? (
                          <ButtonBorderIconFalseRespostaDp>
                            <AiFillDislike
                              style={{ color: '#f2f2f2' }}
                              size={19}
                            />
                          </ButtonBorderIconFalseRespostaDp>
                        ) : (
                          <BsFillQuestionSquareFill
                            style={{
                              color: '#737373',
                              height: '26',
                              width: '28',
                            }}
                          />
                        )}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              visualizarValidacao &&
                              visualizarVazio !== true
                            ) {
                              handleErrorVisualizarValidacao();
                            } else {
                              setVisualizarValidacao(true);
                              handlePreencher(res);
                              setUserDados(res);
                              setUserId(res.Id_UserPrecadastro);
                              if (res.DOCUMENTACAO === null) {
                                setVisualizarVazio(true);
                              } else {
                                setVisualizarVazio(false);
                              }
                            }
                          }}
                        >
                          <FiEye />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>
          </div>
        )}
        {visualizarValidacao && (
          <Container>
            {visualizarVazio && (
              <ContainerVisualizarVazio>
                <h3>Não existe documento para esse usuário.</h3>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setVisualizarValidacao(false);
                      setVisualizarVazio(false);
                    }}
                  >
                    Voltar
                  </button>
                </div>
              </ContainerVisualizarVazio>
            )}
            {!visualizarVazio && (
              <ContainerValidar>
                <div>
                  <p>{`Validação da Solicitação de Vaga do: ${userDados?.NOME}`}</p>
                  <p className="cabecalhoRH">Aceito pelo Recursos Humanos</p>
                  <p className="corpoRH">
                    {`Data: ${formatarData(
                      userDados?.RH_ENVIADO_DP_DATA_HORA,
                    )}`}
                  </p>
                  <p className="corpoRH">{`Nome: ${userDados?.USER_RH_NOME}`}</p>
                </div>
                <ContainerTableValidar>
                  <table>
                    <HeaderTable headers={headers} />
                    <tbody>
                      {dataValidacao.map((res: ValidacaoSolVaga) => (
                        <tr key={res.Id_Dom_Documentacao}>
                          <td>{`${res.Id_Dom_Documentacao} - ${res.DOCUMENTACAO}`}</td>
                          <td style={{ width: 30 }}>
                            <a
                              href={res.LINK_IMAGEM}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FiFileText />
                            </a>
                          </td>
                          {res.Id_Dom_Documentacao !== 24 && (
                            <td style={{ minWidth: 150 }}>
                              <div className="aceitoFlex">
                                <div className="aceito">
                                  {(res.DOC_ACEITO === null ||
                                    res.DOC_ACEITO === true) && (
                                    <FiXCircle
                                      style={{
                                        color: '#737373',
                                        marginTop: -1,
                                        marginLeft: -1,
                                      }}
                                      size={25}
                                    />
                                  )}
                                  {!(
                                    res.DOC_ACEITO === null ||
                                    res.DOC_ACEITO === true
                                  ) && (
                                    <ButtonBorderIconFalse>
                                      <FiXCircle
                                        style={{ color: '#f2f2f2' }}
                                        size={25}
                                      />
                                    </ButtonBorderIconFalse>
                                  )}
                                </div>
                                <div className="aceito">
                                  {res.DOC_ACEITO && (
                                    <ButtonBorderIconTrue>
                                      <FiCheckCircle
                                        style={{ color: '#f2f2f2' }}
                                        size={25}
                                      />
                                    </ButtonBorderIconTrue>
                                  )}
                                  {!res.DOC_ACEITO && (
                                    <FiCheckCircle
                                      style={{
                                        color: '#737373',
                                        marginTop: -1,
                                        marginLeft: -1,
                                      }}
                                      size={25}
                                    />
                                  )}
                                </div>
                              </div>
                            </td>
                          )}
                          {res.Id_Dom_Documentacao === 24 && <td>-</td>}
                          {res.Id_Dom_Documentacao === 24 && (
                            <td style={{ minWidth: 150 }}>
                              <button
                                type="button"
                                onClick={() => {
                                  setDadoIcon(false);
                                  setDocId(res.Id_Dom_Documentacao);
                                }}
                              >
                                {(res.DP_APROVADO === null ||
                                  res.DP_APROVADO === true) && (
                                  <FiXCircle
                                    style={{
                                      color: '#737373',
                                      marginTop: -1,
                                      marginLeft: -1,
                                    }}
                                    size={25}
                                  />
                                )}
                                {!(
                                  res.DP_APROVADO === null ||
                                  res.DP_APROVADO === true
                                ) && (
                                  <ButtonBorderIconFalse>
                                    <FiXCircle
                                      style={{ color: '#f2f2f2' }}
                                      size={25}
                                    />
                                  </ButtonBorderIconFalse>
                                )}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setDadoIcon(true);
                                  setDocId(res.Id_Dom_Documentacao);
                                }}
                              >
                                {res.DP_APROVADO && (
                                  <ButtonBorderIconTrue>
                                    <FiCheckCircle
                                      style={{ color: '#f2f2f2' }}
                                      size={25}
                                    />
                                  </ButtonBorderIconTrue>
                                )}
                                {!res.DP_APROVADO && (
                                  <FiCheckCircle
                                    style={{
                                      color: '#737373',
                                      marginTop: -1,
                                      marginLeft: -1,
                                    }}
                                    size={25}
                                  />
                                )}
                              </button>
                            </td>
                          )}
                          {res.Id_Dom_Documentacao !== 24 && <td>-</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ContainerTableValidar>
                <Content>
                  <ButtonBorder cor={false}>
                    <button
                      type="button"
                      onClick={() => {
                        setVisualizarValidacao(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </ButtonBorder>
                  <ButtonBorder cor>
                    <button
                      type="button"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        handlePreencherESalvarDados();
                      }}
                    >
                      <FiSave />
                      Salvar
                    </button>
                  </ButtonBorder>
                </Content>
              </ContainerValidar>
            )}
          </Container>
        )}
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default SolicitacaoVaga;
