import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerSelectProps {
  select: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  padding: 20px;
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  animation: ${appearFromRight} 1s;

  h1 {
    margin-top: 14px;
    font-size: 25px;
  }

  form {
    margin: 30px 0;
    div {
      margin-top: 0px;
      margin-right: 10px;
      }
      & + div {
        margin-right: 10px;
      }
    }
  }
`;

export const ContainerInput = styled.div<ContainerSelectProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div.divInput {
    margin-top: 10px !important;
  }

  select.selectCargo {
    width: 263px;
    height: 60px;
    border-radius: 10px;
    background: #232129;
    border: 2px solid #232129;
    padding: 0 10px 0 10px;
    color: #fff;
    border: ${(props) => (props.select ? '2px solid #439133' : '')};
  }

  button {
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    background: #439133;
    border: 0px;
    height: 30px;
    border-radius: 5px;
    min-width: 150px;
    color: #ffffff;
    transition: background 0.2s;

    &:hover {
      background: ${shade(0.2, '#439133')};
    }
  }
`;

export const ContainerCodigo = styled.div`
  display: flex;
  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #232129;
    border-radius: 10px;
    padding: 16px 10px;
    border: 2px solid #605b71;
    color: #fff;
    min-width: 261px;
    height: 60px;
    margin-top: 10px;
    svg {
      color: #665360;
      margin-right: 16px;
    }
  }
  div {
    margin-left: 10px;
  }
`;
