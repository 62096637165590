import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

export const Container = styled.div`
  width: 70%;
  min-width: 70vh;
  height: 90%;
  min-height: 90vh;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
  overflow-y: auto;
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: transparent;
    padding: 2px;
    margin-left: auto;
  }
`;

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;
  p {
    margin: 0px auto;
    font-size: 24px;
  }
`;

export const ContainerHeaderDados = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 50px;
`;

export const ContainerPessoais = styled.div`
  td {
    font-weight: bold;
    & + td {
      padding-left: 10px;
      font-weight: normal;
    }
  }
`;

export const ContainerBodyDados = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  margin-top: 20px;
  table {
    width: 100%;
    td,
    th {
      border: 1px solid black;
      border-collapse: collapse;
      padding: 0 3px;
      text-align: center;
    }
  }
`;

export const DivContainerDados = styled.div`
  display: flex;
  width: 100%;
  .coluna {
    width: 25%;
    p {
      margin-left: 5px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
`;

export const ContainerDados = styled.div`
  th {
    font-size: 18px;
    justify-content: center;
    min-width: 50px;
    text-align: center;
  }
  td {
    text-align: center;
    font-weight: bold;
    height: 40px;
    & + td {
      padding-left: 10px;
      font-weight: normal;
    }
  }
`;

export const ContainerDadosDetalhes = styled.div`
  margin-top: 30px;
  padding: 0px 50px;
  margin-bottom: 30px;
`;

export const ContainerBoxBorder = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #111111;
`;
export const DadosDetalhes = styled.div`
  table {
    & + table {
      margin-top: 10px;
    }
  }
  td {
    font-weight: bold;
    & + td {
      padding-left: 10px;
      font-weight: normal;
      text-align: end;
    }
  }
  .tdColumn {
    min-width: 200px;
  }
  td,
  th {
    padding: 0 5px;
  }
`;

export const ContainerButtonPDF = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    background-color: #006600;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
  }
`;
