import styled from 'styled-components';

export const Container = styled.div`
  :hover {
    * {
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

export const ContentTextBoolean = styled.label`
  font-family: 'Arial', serif;
  font-size: 18px;
  color: #000000;
  margin-right: 25px;
  margin-left: 5px;
  border-width: 2px;
  border-color: #cccccc;
`;
