import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import DrawerApp from "../../components/Drawer";
import ModalConexao from "../../components/ModalConexao";
import { ContainerPesquisaGeral } from "../VFiltroPonto/styles";
import { Container, ContainerInfFilter, ContainerSupFilter } from "./styled";
import api from "../../services/api";
import { criarPdf } from "./criarPdf";
import { IDecimoTerceiro } from '../../models/DecimoTerceiro';
import { Loading } from "../../components/Loading";
import Swal from 'sweetalert2';

const DecimoTerceiro: React.FC = () => {

    const [dataInicial, setDataInicial] = useState("2022");
    const [dadosApi, setDadosApi] = useState<IDecimoTerceiro[]>([]);
    const [dadosParcela, setDadosParcela] = useState("1");
    const [loading, setLoading] = useState(false);
    

    const handleSubmit = () => {
        getDecimoTerceiro()
    }

    const getDecimoTerceiro = () => {
        console.log(dataInicial, dadosParcela)
        setLoading(true)
        api.get(`contraChequeDecimoTerceiro/${dataInicial}/${dadosParcela}`).then(Response => {
            if(dadosParcela === "1"){
                setDadosApi([{...Response.data[0], periodo: "11", ano: dataInicial}])
            }else{
                setDadosApi([{...Response.data[0], periodo: "12", ano: dataInicial}])
            }
            
            console.log(Response.data)
            setLoading(false)
        }).catch(Error => {
            console.log(Error)
            Swal.fire({
                icon: 'error',
                title: 'Dados não encontrados.',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            setLoading(false)
        })
    }

    return (
        <Container>
            <><ModalConexao />
                <DrawerApp />
                <ContainerPesquisaGeral>
                    <ContainerSupFilter>

                        {/* <h4>{JSON.stringify(dadosApi)}</h4> */}

                        <p style={{ marginRight: -3, marginLeft: 5 }}>Ano: </p>

                        <input placeholder="2022" type="number"
                            onChange={
                                (event) => {
                                    setDadosApi([])
                                    var ano = event.currentTarget.value
                                    if (ano.length !== 4) {
                                        alert("O ano precisa ter 4 números")
                                    }
                                    else {
                                        setDataInicial(ano)
                                    }

                                }
                            } value={dataInicial}></input>

                        <p style={{ marginRight: 3, marginLeft: 5 }}>Parcela: </p>

                        <select onChange={
                            (event) => {
                                setDadosApi([])
                                var parcela = event.currentTarget.value
                                setDadosParcela(parcela)
                            }
                        }>
                            <option value={1}>1° parcela</option>
                            <option value={2}>2° parcela</option>    
                        </select>

            

                        <ContainerInfFilter>
                            <button type="button" onClick={() => 
                                handleSubmit()
                                }>
                                Pesquisar
                            </button>
                            {dadosApi.length>0 && (
                                <button className="VisualizarPdf" type="button" onClick={() => 
                                    criarPdf(dadosApi[0])
                                    }>
                                    Visualizar PDF
                                </button>
                            )}
                        </ContainerInfFilter>
                    </ContainerSupFilter>
                </ContainerPesquisaGeral></>
                {loading && <Loading/>}
        </Container>
    );
}

export default DecimoTerceiro;

function handleSubmit(): void {
    throw new Error("Function not implemented.");
}
