import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.7);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  animation: ${appearFromRight} 300ms;
  position: relative;
  width: 500px;
  height: 300px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;

  .divGeral {
    width: 480px;
    height: 168px;
    margin-bottom: 20px;
    overflow-y: auto;
    background: #d9d9d9;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: transparent;
    padding: 8px;
    margin-left: auto;
  }
`;

export const ContainerHeader = styled.div`
  width: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  font-weight: bold;
  font-size: 22px;

  p {
    margin-bottom: 10px;
  }
`;

export const ContainerHeaderDados = styled.div`
  width: auto;
  align-items: center;
  justify-content: center;
  .titulo {
    text-align: center;
    //font-weight: bold;
    margin: 0;
    font-size: 16px;
  }
  #imgLogoId2 {
    display: none;
    margin: 0px auto;
  }
`;

export const ContainerButtonAtualizar = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;
  button {
    padding: 10px;
    background-color: #006600;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
  }
`;
