import React, { useState } from 'react';
import Swal from 'sweetalert2';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import api from '../../services/api';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import DrawerApp from '../../components/Drawer';
import {
  Container,
  ContainerPesquisaGeral,
  ContainerSupFilter,
  ContainerInfFilter,
  ContainerImprimir,
  ContainerVisualizarPDF,
} from './styles';
import { formatarData } from '../../components/Formulas/formulas';
import { maskCpf } from '../../components/Input/mask';

const ContraCheque: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataInicial, setDataInicial] = useState(new Date());
  const [linkPDF, setLinkPDF] = useState<string>('');
  const [htmlPDF, setHtmlPDF] = useState<string>('');

  function retornarEndereco(endereco: string): string[] {
    return endereco.split(',');
  }

  function moedaReal(moeda: number): string {
    return moeda.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  const handleSendPDF = (html: string): void => {
    api
      .post('pdf', {
        html,
      })
      .then(({ data }) => {
        setLoading(false);
        setLinkPDF(data.url);
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar PDF.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    api
      // .get('/users/findAll')
      .get(
        `/contraChequeJuridico/${formatarData(dataInicial?.toLocaleString())
          .slice(0, 7)
          // @ts-ignore
          .replaceAll('-', '')}`,
      )
      .then(({ data: dados }) => {
        const objPdf = dados.jsonContraCheque[0];
        const hash = dados.HASH;

        const linkSuportWhatsapp = `https://api.whatsapp.com/send?phone=558591124042&text=Ol%C3%A1+marketing.+Meu+nome+%C3%A9+${
          objPdf.nome.split(' ')[0]
        }+e+CPF:+${maskCpf(
          objPdf.cpf,
        )},+venho+do+App+Colaborador+Contra+Cheque.+Gostaria+de+saber+mais+informa%C3%A7%C3%B5es+sobre+os+produtos.`;

        const endereco: string[] = retornarEndereco(objPdf.endereco);
        const dataFol = `${objPdf.periodoFol.substr(
          4,
          5,
        )}/${objPdf.periodoFol.substr(0, 4)}`;
        // const dataFile = dataFol.replace('/', '-');
        // let adicionouTamanhoDoModal = 90;

        let verbas = '';
        let proventos = '';
        let indice = 0; // variavel para guardar o index dos valores do inss
        let contador = 0; // conta os provendos quando chegarem 18 quebra para nova page
        let page = 0; // se for 0 tem so uma page se 1 tem duas pages

        const cabecalhoRodapePrincipalPage1 =
          `<div style="height: 1000px;">` +
          `<div style="margin: 0px 15px;">` +
          `<table width="100%"><tbody><tr><td>` +
          `<img style="margin-top: 10px" width="190px" height="120px" src="https://atasreunioes.com/static/media/logo-servnac.37835b15.png" />` +
          `</td>` +
          `<td style="vertical-align: bottom; text-align: right">` +
          `<label style=": 10;"><strong>${objPdf.nomeEmpresa}</strong></label><br/>` +
          `<label style=": 10;">${endereco[0]}</label><br/>` +
          `<label style=": 10;">${endereco[1]}, ${endereco[2]}</label><br/>` +
          `<label style=": 10;">${endereco[3]}</label><br/>` +
          `</td></tr></tbody></table>` +
          `</div>`;
        const cabecalhoRodapePrincipalPage2 =
          `<div style="height: 1000px;">` +
          `<div style="margin: 0px 15px;">` +
          `<table width="100%"><tbody><tr><td>` +
          `<img style="margin-top: 10px" width="190px" height="120px" src="https://atasreunioes.com/static/media/logo-servnac.37835b15.png" />` +
          `</td>` +
          `<td style="vertical-align: bottom; text-align: right">` +
          `<label style=": 10;"><strong>${objPdf.nomeEmpresa}</strong></label><br/>` +
          `<label style=": 10;">${endereco[0]}</label><br/>` +
          `<label style=": 10;">${endereco[1]}, ${endereco[2]}</label><br/>` +
          `<label style=": 10;">${endereco[3]}</label><br/>` +
          `</td></tr></tbody></table>` +
          `</div>`;
        const fechamentoTagEClickPage1 =
          `</div>` +
          `</div>` +
          `<a href=${linkSuportWhatsapp}><div style="height: 80px; width: 400px; margin: 0px auto; justify-content: end;"><p> </p></div></a>` +
          // `</div>` +
          `</div>`;
        const fechamentoTagEClickPage2 =
          `</div>` +
          `</div>` +
          `<a href=${linkSuportWhatsapp}><div style="height: 80px; width: 400px; margin: 0px auto; justify-content: end;"><p> </p></div></a>`;
        // +`</div>`;
        const cabecalhoTableProventosVerbas =
          `<div style="margin-left: 15px; margin-top: 10px; margin-right: 15px; border: 1px solid; padding: 5px; display: flex;">` +
          `<table style="width: 100%;">` +
          `<tr><td style="width: 40px; text-align: center; margin-left: 10px;"><strong>Cód</strong></td>` +
          `<td style="width: 220px; text-align: center; margin-left: 30px;"><strong>Descrição</strong></td>` +
          `<td style="width: 40px; text-align: center; margin-left: 10px;"><strong>Referência</strong></td>` +
          `<td style="width: 120px; text-align: center; margin-left: 10px;"><strong>Vencimentos</strong></td>` +
          `<td style="width: 120px; text-align: center; margin-left: 10px;"><strong>Descontos</strong></td>` +
          `</tr>`;
        const dadosSubHeader = `${
          `<div style="margin-left: 15px; margin-top: 10px; margin-right: 15px; border: 1px solid; padding: 5px; display: flex;">` +
          `<table width="100%"><tr>` +
          `<td style=": 12px;"><strong><p>Código</p></strong></td>` +
          `<td style=": 12px; text-align: center;"><strong><p>Nome Funcionário: </p></strong></td>` +
          `<td style=": 12px; text-align: center;"><strong><p>CBO Emp. Local Depto. Setor Seção FI.</p></strong></td>` +
          `</tr><tr>` +
          `<td style=": 13px;"><p">${objPdf.matricula}</p></td>` +
          `<td style=": 13px; text-align: center;"><p>${objPdf.nome}</p></td>` +
          `<td style=": 13px; text-align: center;"><p">${objPdf.descCentroCusto}</p></td>` +
          `</tr></table></div>`
        }${cabecalhoTableProventosVerbas}`;

        if (objPdf.descProventos.length > 0) {
          // @ts-ignore
          objPdf.descProventos.forEach((provento, index) => {
            contador += 1;
            if (contador === 18) {
              page = 1;
              proventos +=
                `</table></div>` +
                `${fechamentoTagEClickPage1}${cabecalhoRodapePrincipalPage2}${cabecalhoTableProventosVerbas}`;
            }
            proventos +=
              `<tr>` +
              `<td style="width: 40px; text-align: center; margin-left: 10px;">${objPdf.codVerbasProventos[index]}</td>` +
              `<td style="width: 220px; text-align: left; margin-left: 30px;">${provento}</td> ` +
              `<td style="width: 40px; text-align: center; margin-left: 10px;">${objPdf.referenciaProventos[index]}</td>` +
              `<td style="width: 120px; text-align: center; margin-left: 10px;">${moedaReal(
                objPdf.proventosValores[index],
              )}</td>` +
              `<td style="width: 120px; text-align: center; margin-left: 10px;">-</td>` +
              `</tr>`;

            // indice += 1;
          });
        }
        indice = 0;
        if (objPdf.verbas.length > 0) {
          // @ts-ignore
          objPdf.verbas.forEach((verba, index) => {
            contador += 1;
            if (verba.includes('INSS')) {
              indice = index;
            }
            if (contador === 18) {
              page = 1;
              verbas +=
                `</table></div>` +
                `${fechamentoTagEClickPage1}${cabecalhoRodapePrincipalPage2}${cabecalhoTableProventosVerbas}`;
            }
            verbas +=
              `<tr>` +
              `<td style="width: 40px; text-align: center; margin-left: 10px;">${objPdf.codVerbasDescontos[index]}</td>` +
              `<td style="width: 220px; text-align: left; margin-left: 30px;">${verba}</td> ` +
              `<td style="width: 20px; text-align: center; margin-left: 10px;">${objPdf.referenciaDescontos[index]}</td>` +
              `<td style="width: 120px; text-align: center; margin-left: 10px;">-</td>` +
              `<td style="width: 120px; text-align: center; margin-left: 10px;">${moedaReal(
                objPdf.descontosVerbas[index],
              )}</td>` +
              `</tr>`;
          });
        }
        verbas += `</table></div>`;
        if (
          objPdf.verbas.length + objPdf.descProventos.length >= 11 &&
          objPdf.verbas.length + objPdf.descProventos.length < 18
        ) {
          page = 1;
          verbas += `${fechamentoTagEClickPage1}${cabecalhoRodapePrincipalPage2}`;
        }
        const dadosSomaEValor =
          `<div style="margin-left: 15px; margin-top: 10px; margin-right: 15px; border: 1px solid; padding: 5px; display: flex;">` +
          `<table style="width: 100%;">` +
          `<tr><td style="width: 55%"></td>` +
          `<td style="width: 15%; text-align: center; margin-left: 20px; : 12px;">Total de Vencimentos</td>` +
          `<td style="width: 15%; text-align: center; margin-left: 10px; : 12px;">Total de Descontos</td>` +
          `<td style="width: 15%; text-align: center; margin-left: 20px; : 12px;">Valor Líquido</td>` +
          `</tr>` +
          `<tr><td style="width: 55%"></td>` +
          `<td style="width: 15%; text-align: center; margin-left: 20px;"><strong>${moedaReal(
            objPdf.proventosTotais,
          )}</strong></td>` +
          `<td style="width: 100px; text-align: center; margin-left: 10px;"><strong>${moedaReal(
            objPdf.descontosTotais,
          )}</strong></td>` +
          `<td style="width: 15%; text-align: center; margin-left: 10px;"><strong>${moedaReal(
            objPdf.salL,
          )}</strong></td>` +
          `</tr>` +
          `</table></div>`;

        const dadosFinais =
          `<div style="margin-left: 15px; margin-top: 20px; margin-right: 15px; border: 1px solid; padding: 5px; display: flex;">` +
          `<table style="width: 100%;">` +
          `<tr><td style=" text-align: center; margin-left: 10px;: 12px;"><strong>Salário Base</strong></td>` +
          `<td style="text-align: center; margin-left: 30px;: 12px;"><strong>Sal. Contr. INSS</strong></td>` +
          `<td style="text-align: center; margin-left: 10px;: 12px;"><strong>Base Cálc. FGTS</strong></td>` +
          `<td style="text-align: center; margin-left: 20px;: 12px;"><strong>FGTS do Mês</strong></td>` +
          `<td style="text-align: center; margin-left: 10px;: 12px;"><strong>Base Cálc. IRRF</strong></td>` +
          `<td style="text-align: center; margin-left: 10px;: 12px;"><strong>Faixa IRRF</strong></td>` +
          `</tr>` +
          `<tr><td style="text-align: center; margin-left: 10px;">${moedaReal(
            objPdf.salB,
          )}</td>` +
          `<td style="text-align: center; margin-left: 30px;">${moedaReal(
            objPdf.descontosVerbas[indice],
          )}</td>` +
          `<td style="text-align: center; margin-left: 10px;">${moedaReal(
            objPdf.descontosVerbas[indice],
          )}</td>` +
          `<td style="text-align: center; margin-left: 10px;">${moedaReal(
            objPdf.descontosVerbas[indice],
          )}</td>` +
          `<td style="text-align: center; margin-left: 20px;">-</td>` +
          `<td style="text-align: center; margin-left: 10px;">-</td>` +
          `</tr>` +
          `</table></div>`;
        const html =
          `<style>` +
          `@page { ` +
          `size: A4;  margin: 0mm; }` +
          `</style>` +
          `${cabecalhoRodapePrincipalPage1}` +
          `<div style="margin-left: 15px; margin-top: 10px; margin-right: 15px; border: 1px solid; padding: 5px; display: flex;">` +
          `<table style="width: 100%"><tbody><tr>` +
          `<td style="=14px;"><strong><p>${objPdf.nomeEmpresa}</p></strong></td>` +
          `<td style="=18px; text-align: center;"><strong><p>RECIBO DE PAGAMENTO DE SALÁRIO</p></strong></td>` +
          `</tr>` +
          `<tr>` +
          `<td style="=14px;"><strong><p>CNPJ: ${objPdf.cnpj}</p></strong></td>` +
          `<td style="=14px; text-align: center;"><strong><p">${dataFol}</p></strong></td>` +
          `</tr></tbody></table>` +
          `</div>` +
          // `<div style="display: flex; justify-content: center; margin-bottom: 40px; align-items: center;"><img src="${imgBase64}" alt="logo" />` +
          // `<h1 style="text-align: center; =20px; margin-left: 20px;"><strong>Contra Cheque ${dataFol}</strong></h1>` +
          `${dadosSubHeader}` +
          // dados de descontos e proventos
          `${proventos}` +
          `${verbas}` +
          `${dadosSomaEValor}` +
          `${dadosFinais}` +
          `<p style="margin-left: 15px; margin-right: 15px; text-align: center;">Contra Cheque gerado em ` +
          `${moment()
            .tz('America/Fortaleza')
            .format('DD/MM/YYYY - HH:mm:ss')} código: ${hash}` +
          `</p>` +
          `${
            page === 1 ? fechamentoTagEClickPage2 : fechamentoTagEClickPage1
          }` +
          ``;
        const htmlFinalPDF = html.replace(
          '<div style="height: 1000px;">',
          '<div style="height: 1000px; font-size: 0.85em">',
        );
        const htmlMobile = html.replace(
          '<div style="height: 1000px;">',
          '<div style="height: 1000px; font-size: 0.53em">',
        );
        // setLoading(false);
        handleSendPDF(htmlFinalPDF);
        setHtmlPDF(htmlMobile);
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title:
            'Não foi possível encontrar um contracheque nessa data informada.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
        setLinkPDF('');
      });
  };

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />
      <ContainerPesquisaGeral>
        <ContainerSupFilter>
          <p style={{ marginRight: -3, marginLeft: 5 }}>Período: </p>
          <ReactDatePicker
            locale="pt-BR"
            selected={dataInicial}
            onChange={(date) => {
              // @ts-ignore
              setDataInicial(date);
            }}
            placeholderText="mm/aaaa"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
          <ContainerInfFilter>
            <button type="button" onClick={() => handleSubmit()}>
              Pesquisar
            </button>
          </ContainerInfFilter>
          {linkPDF && (
            <ContainerImprimir>
              <a href={linkPDF} target="_blank" rel="noreferrer noopener">
                Imprimir
              </a>
            </ContainerImprimir>
          )}
        </ContainerSupFilter>
        {linkPDF && (
          <ContainerVisualizarPDF
            dangerouslySetInnerHTML={{ __html: htmlPDF }}
          />
        )}
      </ContainerPesquisaGeral>
      {loading && <Loading />}
    </Container>
  );
};

export default ContraCheque;
