import styled from 'styled-components';

interface ContainerProps {
  classNameProps: string;
}

export const Container = styled.div`
  max-width: auto;
  display: flex;
  input {
    font-family: 'Arial';
    align-self: 'center';
    justify-self: 'center';

    //margin-right: 30px;
    margin-right: 50px;

    border-left-width: 2px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-color: '#439133';
    border-bottom-width: 10px;
    border: 10px;
    border-top-right-radius: 15px;
    padding: 5px;
    padding-left: 10px;
    height: 40px;
    width: 250px;
  }
`;

export const ContainerLupa = styled.div<ContainerProps>`
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  //border-top-left-radius: 15px;
  border-top-left-radius: ${(props) =>
    props.classNameProps === 'search1' || props.classNameProps === 'search4'
      ? '15px'
      : '0px'};
  padding: 8px;
  padding-left: 12px;
  margin-left: 20px;
`;
