import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container, ContentTextBoolean } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  value: number;
  type: string;
  disabledRadio?: boolean;
}

const RadioButton: React.FC<InputProps> = ({
  name,
  label,
  value,
  type,
  disabledRadio = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <input
        name={name}
        // onFocus={handleInputFocus}
        // onBlur={handleInputBlur}
        defaultValue={defaultValue}
        type={type}
        value={value}
        disabled={disabledRadio}
        ref={inputRef}
        {...rest}
        style={{ width: 25, height: 20, color: 'red' }}
      />
      {label && <ContentTextBoolean>{label}</ContentTextBoolean>}
    </Container>
  );
};

export default RadioButton;
