import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  status?: string;
  cor: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  width: 93%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  animation: ${appearFromRight} 1s;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerAba = styled.div<ContainerProps>`
  button {
    margin: 0;
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    color: #111111;
    border-width: 0px;
    min-width: 150px;
    padding: 0 10px 3px 10px;
    border: 1px solid #a6a6a6;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerSearch = styled.div`
  max-width: auto;
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;

  div {
    display: flex;
    margin-left: 0 !important;

    svg {
      margin-bottom: 2px;
    }
    border-bottom-left-radius: 15px;
  }
  .search1 {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const ContainerFundoCadastro = styled.div<ContainerProps>`
  overflow: hidden;
  border-radius: 10px;
  background: ${(props) => (props.cor ? ' #3d3946' : 'transparent')};
  .divTable {
    //margin: 5px;
    border-radius: 10px;
    animation: ${appearFromRight} 1s;
    //box-shadow: 0 2px 4px #0d0d0d;
  }
`;

export const ContainerCadastro = styled.div`
  margin: 0px auto;
  animation: ${appearFromRight} 1s;
  .divAmbiente {
    width: 100%;
    align-items: center;
    p {
      font-size: 18px;
      font-family: arial;
      margin-bottom: 3px;
    }
  }
  .divDropDown {
    width: 100%;
    div {
      width: 100%;
    }
    p {
      align-items: center;
      margin: 0 10px 4px 0;
      font-size: 18px;
      font-family: Roboto, sans-serif;
    }
    select {
      width: 100%;
      height: 60px;
      margin-left: 0px;
      background-color: #28262e;
      border-radius: 10px;
      padding: 0 10px 0 10px;
      border: none;
    }
    & + .divDropDown {
      margin-top: 12px;
    }
  }
  .divFuncoes {
    align-self: center;
    margin: 0px auto;
    width: 100%;
    margin-top: 20px;
    p {
      font-size: 18px;
      font-family: Roboto, sans-serif;
    }
  }
  .divButton {
    display: flex;
    width: 100%;
    margin: 10px auto;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 5px;
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  max-width: 800px;
  flex-direction: column;
  margin: 0px auto;
  padding: 10px;
  animation: ${appearFromRight} 1s;
  h1 {
    margin-top: 14px;
    font-size: 25px;
    font-family: Roboto, sans-serif;
  }
  form {
    margin: 30px 0;
    margin-bottom: 5px;
    width: 100%;
    margin-left: 0px;
    div {
      margin-top: 0px;
      select.selectEmpresa {
        width: 100%;
        height: 60px;
        border-radius: 10px;
        background: #232129;
        border: 2px solid #232129;
        padding: 0 10px 0 10px;
        color: #fff;
      }
      & + div {
        margin-top: 10px;
      }
    }
    a {
      color: #f4ede8;
      display: block;
      margin-top: 14px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
    .divRevisao {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      p {
        font-size: 18px;
        font-family: Roboto, sans-serif;
      }
      div {
        display: flex;
        margin: 0;
        width: 100%;
        div.divRevisaoCheck {
          & + div.divRevisaoCheck {
            margin-left: 10px;
          }
        }
      }
    }
    .divHorarioRevisao {
      display: flex;
      background: #494554;
      padding: 10px;
      margin-top: 10px;
      border-radius: 10px;
      animation: ${appearFromRight} 0.6s;
      div {
        width: 100%;
        margin: 0;
        & + div {
          margin-left: 10px;
        }
      }
    }

    div.divAddDelete {
      display: flex;
      flex-direction: row;
      button {
        width: 24%;
        background: #439133;
        outline: none;
        border: none;
        border-radius: 7px;
        padding: 2px 2px 4px 2px;
        transition: background 0.2s;
        &:hover {
          background: ${shade(0.2, '#439133')};
        }

        & + button {
          margin-left: 15px;
          background: #660000;
          transition: background 0.2s;

          &:hover {
            background: ${shade(0.2, '#660000')};
          }
        }
      }
    }
  }
  > a {
    color: #fff;
    display: block;
    text-decoration: none;
    transition: color 0.2s;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#fff')};
    }
    display: flex;
    align-items: center;
  }
`;

export const ContainerFuncoes = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
  background: #494554;
  padding: 2px 10px 10px 10px;
  border-radius: 10px;
  input {
    height: 20px;
    width: 20px;
  }
  .divCheck {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 0px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #595959;
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 10px !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #595959;
    background-color: #8c8c8c;
  }
`;

export const ContainerLabel = styled.label<ContainerProps>`
  background: ${(props) => (props.cor ? `${props.status}` : '#312e38')};
  margin-top: 8px;
  margin-right: 4px;
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  width: 150px;
  height: 100px;
  overflow-y: auto;
  line-height: 16px !important;
  padding: 10px 5px;
  border-radius: 7px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #595959;
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #595959;
    background-color: #8c8c8c;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px !important;
  div {
    display: flex;
    width: 100%;
    button {
      padding-bottom: 13px;
      & + button {
        margin-left: 20px;
      }
    }
  }
  .divButtonCancelar {
    width: 100%;
    background-color: rgb(102, 0, 0);
    &:hover {
      background-color: ${shade(0.2, 'rgb(102, 0, 0)')};
    }
  }
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    &:hover {
      background-color: ${shade(0.2, '#439133')};
    }
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 45vh;
  overflow-x: auto;
  border-radius: 10px 10px 0 0;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 10px;
      background-color: #006600;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  align-items: center;
  background-color: #008000;
  border-radius: 0 0 10px 10px;
  height: auto;
  .divPaginacao {
    display: flex;
    width: 0;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    select {
      background-color: ${shade(0.2, '#008000')}!important;
      &:hover {
        background: ${shade(0.3, '#008000')} !important;
      }
    }
  }
  .btn {
    margin-left: auto;
    margin-right: 15px;
    height: 40px;
    background-color: #006600;
    //box-shadow: 0 2px 4px #0d0d0d;
    color: #fff;
    border-width: 0px;
    padding: 0 10px;
    font-weight: bold;
    padding-bottom: 3px;
    &:hover {
      background: ${shade(0.2, '#006600')};
    }
  }

  /* max-width: auto;
  display: flex;
  //padding: 0px 20px;
  margin-left: 40px;
  margin-right: 40px;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  .btn {
    height: 40px;
    background-color: #006600;
    color: #ffffff;
    border-width: 0px;
    padding: 10px;
    margin-left: 40px;
    border-radius: 7px;
    margin-top: 2px;
    &:hover {
      background: ${shade(0.2, '#006600')};
    }
  } */
`;
