import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import { formatarDataAnoMes } from '../../../components/Formulas/formulas';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Modal from '../ModalPdf/indexModal';
import { ResponseRelatorio } from '../Interface/index';
import {
  Container,
  ContainerButton,
  ContainerButtonCancelar,
  ContainerButtonSalvar,
  ContainerDados,
  ContainerHeaderDados,
  ContainerModal,
} from './stylesModal';
import { differenceTime, horasATrabalharEmMS } from '../../Indicadores';

interface ModalArquivo {
  onClose(): void;
  onSave?(arg: FileList): void;
  cpf: string;
}
export const parseResponseRelatorio = (resp: any[]): any[] => {
  return resp.map((dado) => {
    const text = dado.ESCALA;
    const regex = /\d{2}:\d{2}/g;
    const matches = text.match(regex);

    let tempoTrabalhadoEmMs = 0;
    // Todos os pontos
    if (
      dado.PONTO_HORA_ENTRADA &&
      dado.PONTO_HORA_PAUSA &&
      dado.PONTO_HORA_VOLTA &&
      dado.PONTO_HORA_SAIDA
    ) {
      tempoTrabalhadoEmMs =
        differenceTime(
          `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
          `${dado.PONTO_DATA_PAUSA}T${dado.PONTO_HORA_PAUSA}`,
        ) +
        differenceTime(
          `${dado.PONTO_DATA_VOLTA}T${dado.PONTO_HORA_VOLTA}`,
          `${dado.PONTO_DATA_SAIDA}T${dado.PONTO_HORA_SAIDA}`,
        );
    }
    // Tudo menos saída
    else if (
      dado.PONTO_HORA_ENTRADA &&
      dado.PONTO_HORA_PAUSA &&
      dado.PONTO_HORA_VOLTA &&
      !dado.PONTO_HORA_SAIDA
    ) {
      tempoTrabalhadoEmMs =
        differenceTime(
          `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
          `${dado.PONTO_DATA_PAUSA}T${dado.PONTO_HORA_PAUSA}`,
        ) +
        (matches && matches.length === 4
          ? Math.abs(
              differenceTime(
                `${dado.PONTO_DATA_VOLTA}T${dado.PONTO_HORA_VOLTA}`,
                `${dado.PONTO_DATA_VOLTA}T${matches[3]}:00`,
              ),
            )
          : 0);
      // (matches && matches.length === 4
      //   ? differenceTime(
      //       `${dado.PONTO_DATA_VOLTA}T${dado.PONTO_HORA_VOLTA}`,
      //       `${dado.PONTO_DATA_VOLTA}T${matches[2]}:00`,
      //     )
      //   : 0);
    }
    // Só entrada e pausa
    else if (
      dado.PONTO_HORA_ENTRADA &&
      dado.PONTO_HORA_PAUSA &&
      !dado.PONTO_HORA_VOLTA &&
      !dado.PONTO_HORA_SAIDA
    ) {
      tempoTrabalhadoEmMs = differenceTime(
        `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
        `${dado.PONTO_DATA_PAUSA}T${dado.PONTO_HORA_PAUSA}`,
      );
    }
    // Só entrada
    else if (
      dado.PONTO_HORA_ENTRADA &&
      !dado.PONTO_HORA_PAUSA &&
      !dado.PONTO_HORA_VOLTA &&
      !dado.PONTO_HORA_SAIDA
    ) {
      if (matches?.length === 4)
        tempoTrabalhadoEmMs = differenceTime(
          `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
          `${dado.PONTO_DATA_ENTRADA}T${matches[1]}:00`,
        );
      // else tempoTrabalhadoEmMs = 4 * 3600000;
    }
    // Só entrada e saída
    else if (
      dado.PONTO_HORA_ENTRADA &&
      !dado.PONTO_HORA_PAUSA &&
      !dado.PONTO_HORA_VOLTA &&
      dado.PONTO_HORA_SAIDA
    ) {
      tempoTrabalhadoEmMs = differenceTime(
        `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
        `${dado.PONTO_DATA_SAIDA}T${dado.PONTO_HORA_SAIDA}`,
      );
    }
    // Entrada, volta e saída
    else if (
      dado.PONTO_HORA_ENTRADA &&
      !dado.PONTO_HORA_PAUSA &&
      dado.PONTO_HORA_VOLTA &&
      dado.PONTO_HORA_SAIDA
    ) {
      tempoTrabalhadoEmMs =
        differenceTime(
          `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
          `${dado.PONTO_DATA_VOLTA}T${dado.PONTO_HORA_VOLTA}`,
        ) +
        differenceTime(
          `${dado.PONTO_DATA_VOLTA}T${dado.PONTO_HORA_VOLTA}`,
          `${dado.PONTO_DATA_SAIDA}T${dado.PONTO_HORA_SAIDA}`,
        );
    }
    // Entrada, pausa e saída
    else if (
      dado.PONTO_HORA_ENTRADA &&
      dado.PONTO_HORA_PAUSA &&
      !dado.PONTO_HORA_VOLTA &&
      dado.PONTO_HORA_SAIDA
    ) {
      tempoTrabalhadoEmMs =
        differenceTime(
          `${dado.PONTO_DATA_ENTRADA}T${dado.PONTO_HORA_ENTRADA}`,
          `${dado.PONTO_DATA_PAUSA}T${dado.PONTO_HORA_PAUSA}`,
        ) +
        differenceTime(
          `${dado.PONTO_DATA_PAUSA}T${dado.PONTO_HORA_PAUSA}`,
          `${dado.PONTO_DATA_SAIDA}T${dado.PONTO_HORA_SAIDA}`,
        );
    }
    if (dado.FALTA_ABONADA)
      tempoTrabalhadoEmMs = horasATrabalharEmMS(dado.HORAS_A_TRABALHAR);

    const diferencaTempoEmMs =
      horasATrabalharEmMS(dado.HORAS_A_TRABALHAR) - tempoTrabalhadoEmMs;
    return {
      ...dado,
      DIFERENCA_TEMPO_MS: diferencaTempoEmMs,
      TEMPO_TRABALHADO_MS: tempoTrabalhadoEmMs,
    };
  });
};
const ModalData: React.FC<ModalArquivo> = ({
  cpf,
  onClose = () => {
    ('');
  },
  onSave = () => {
    ('');
  },
}) => {
  const [dataInicialGet, setDataInicialGet] = useState(
    formatarDataAnoMes(new Date().toLocaleString()),
  );
  const [dataInicial, setDataInicial] = useState();
  const [verModal, setVerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseRelatorio[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGerarRelatorio = useCallback(async () => {
    // try-catch para pegar o arquivo
    try {
      setLoading(true);
      await api
        .get<ResponseRelatorio[]>(
          `/pontoEletronico/getEscalaWebCompetenciaFuncionario/${dataInicialGet}/${cpf}`,
        )
        .then(({ data }) => {
          setResponse(parseResponseRelatorio(data));
          // console.log(parseData);
          setVerModal(true);
          setLoading(false);
        });
    } catch (err) {
      setLoading(true);
      Swal.fire({
        icon: 'info',
        title: 'Erro ao carregar dados, tente novamente!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [cpf, dataInicialGet]);

  return (
    <>
      <div>
        <ContainerModal id="modal">
          <Container>
            <ContainerButton>
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                X
              </button>
            </ContainerButton>
            <ContainerHeaderDados>
              <p>Escolha um período para o relatório.</p>
            </ContainerHeaderDados>
            <ContainerDados>
              <DatePicker
                locale="pt-BR"
                selected={dataInicial || new Date()}
                onChange={(date) => {
                  setDataInicialGet(formatarDataAnoMes(date?.toLocaleString()));
                  setDataInicial(date);
                }}
                selectsStart
                startDate={new Date()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </ContainerDados>
            <ContainerButtonCancelar>
              <button type="button" onClick={() => onClose()}>
                Cancelar
              </button>
            </ContainerButtonCancelar>
            <ContainerButtonSalvar>
              <button
                type="button"
                onClick={() => {
                  handleGerarRelatorio();
                }}
              >
                Relatório Ponto
              </button>
            </ContainerButtonSalvar>
          </Container>
        </ContainerModal>
      </div>
      {verModal && (
        <Modal dados={response} onClose={() => setVerModal(false)} />
      )}
      {loading && <Loading />}
    </>
  );
};

export default ModalData;
