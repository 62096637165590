/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import jsPDF from 'jspdf';
import { ResponseRelatorio } from '..';
import {
  formatarHoraMin,
  formatarDataAnoMesDia,
  formatarMinEmHoraMin,
  CalcularFalta,
} from '../../../components/Formulas/formulas';
import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerPessoais,
  ContainerBodyDados,
  ContainerButtonPDF,
  TableRegistro,
  ContainerColunas,
  ContainerColunasHeader,
  ContainerColunasBody,
} from './styles';
import { tempoMsParaHorasEMin } from '../../Indicadores';

interface PontoMes {
  dados: ResponseRelatorio[];
  onClose(): void;
}

export const ModalRegistroPdf: React.FC<PontoMes> = ({
  dados,
  onClose = () => {
    ('');
  },
}) => {
  // const [horasATrabalhadas, setHorasATrabalhadas] = useState<number[]>([]);
  // const [horasTrabalhadas, setHorasTrabalhadas] = useState<number[]>([]);
  // const [horasFalta, setHorasFalta] = useState<number[]>([]);
  // const [horasExtra, setHorasExtra] = useState<number[]>([]);
  // const [copiaDados, setCopiaDados] = useState<
  //   Record<string, ResponseRelatorio[]>
  // >();

  // colunas
  const [colunasTab, setColunasTab] = useState(false);

  const [clData, setClData] = useState(true);
  const [clDia, setClDia] = useState(true);
  const [clPontos, setClPontos] = useState(true);
  const [clAbono, setClAbono] = useState(true);
  // const [clHN, setClHN] = useState(true);
  // const [clEN, setClEN] = useState(true);
  const [clHT, setClHT] = useState(true);
  const [clEX, setClEX] = useState(true);
  const [clFA, setClFA] = useState(true);

  // const textoAviso = '* - Abono possui mais de 1 motivo.';

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  function groupBy<K>(
    items: K[],
    key: string,
  ): Record<string, ResponseRelatorio[]> {
    return items.reduce(
      (result: any, item: any) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {},
    );
  }
  const dadosTabela = useMemo(() => groupBy(dados, 'CPFSEMFORMATO'), [dados]);
  // const copiaDadosComCorrecaoAbono = useCallback(() => {
  //   const copia = dadosTabela;
  //   Object.keys(copia).forEach((a) =>
  //     copia[a].map((d) => {
  //       if (
  //         d.DESC_DIA ||
  //         d.DESC_PERIODO ||
  //         d.DESC_FALTA ||
  //         d.DESC_ENTRADA ||
  //         d.DESC_PAUSA ||
  //         d.DESC_VOLTA ||
  //         d.DESC_SAIDA
  //       )
  //         return {
  //           ...d,
  //           HORAS_TRABALHADAS_MENOS_HORA_PAUSA: d.HORAS_A_TRABALHAR,
  //           DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS: 0,
  //         };
  //       return d;
  //     }),
  //   );
  //   setCopiaDados(copia);
  // }, [dadosTabela]);

  const preencheMotivoPdf = useCallback((dado: ResponseRelatorio): string => {
    let respostaMotivo = '';
    let soma = 0;
    if (dado.DESC_DIA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_DIA;
      }
    }
    if (dado.DESC_PERIODO) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_PERIODO;
      }
    }
    if (dado.DESC_FALTA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_FALTA;
      }
    }
    if (dado.DESC_ENTRADA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_ENTRADA;
      }
    }
    if (dado.DESC_PAUSA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_PAUSA;
      }
    }
    if (dado.DESC_VOLTA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_VOLTA;
      }
    }
    if (dado.DESC_SAIDA) {
      soma += 1;
      if (soma === 1) {
        respostaMotivo = dado.DESC_SAIDA;
      }
    }
    if (soma > 1) {
      respostaMotivo = `*${respostaMotivo}`;
    }
    return respostaMotivo;
  }, []);

  const handleDownloadPDF = useCallback(() => {
    const doc = new jsPDF(); // eslint-disable-line
    // doc.html(document.getElementById('modalRelatorio') ?? document.body, {
    //   callback: (docs) => {
    //     docs.save();
    //   },
    // });
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.setFontType('bold');
    doc.text('Registro de Pontos', 103, 10, 'center');
    doc.setFontSize(11);
    // tamanho 207 tela a4 eixo horizontal
    let posY: number;

    Object.keys(dadosTabela).forEach((a) => {
      posY = 19;
      doc.setFontType('bold');
      doc.text('Período:', 10, posY, 'left');
      doc.setFontType('normal');
      doc.text(
        `${dadosTabela[a][0].DATA_INICIAL_DIA_AGENDA} a ${
          dadosTabela[a][dadosTabela[a].length - 1].DATA_INICIAL_DIA_AGENDA
        }`,
        30,
        posY,
        {
          // align: 'justify',
          maxWidth: 70,
        },
      );
      posY += 5;
      doc.setFontType('bold');
      doc.text('Empresa:', 10, posY, 'left');
      doc.setFontType('normal');
      let quebraLinha = doc.splitTextToSize(
        `${dadosTabela[a][0].NOME_EMPRESA}`,
        70,
      );
      doc.text(quebraLinha, 30, posY, {
        // align: 'justify',
        maxWidth: 70,
      });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }
      posY += 5;
      doc.setFontType('bold');
      doc.text('Nome:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(`${dadosTabela[a][0].NOME}`, 70);
      doc.setFontType('normal');
      doc.text(quebraLinha, 30, posY, {
        // align: 'justify',
        maxWidth: 70,
      });
      if (quebraLinha.length > 1) {
        posY += 5 * (quebraLinha.length - 1);
      }
      posY += 5;
      doc.setFontType('bold');
      doc.text('CPF: ', 10, posY, 'left');
      doc.setFontType('normal');
      doc.text(`${dadosTabela[a][0].CPF}`, 30, posY, 'left');
      posY += 5;
      // doc.setFontType('bold');
      // doc.text('Email: ', 10, posY, 'left');
      // quebraLinha = doc.splitTextToSize(`${dadosTabela[a][0].EMAIL}`, 70);
      // doc.setFontType('normal');
      // doc.text(quebraLinha, 30, posY, { align: 'justify', maxWidth: 70 });
      // if (quebraLinha.length > 1) {
      //   posY += 5 * (quebraLinha.length - 1);
      // }
      // posY += 5;
      // doc.setFontType('bold');
      // doc.text('Telefone: ', 10, posY, 'left');
      // doc.setFontType('normal');
      // doc.text(`${dadosTabela[a][0].Telefone}`, 30, posY, 'left');
      // fim bloco dadosTabela[a][0]s pessoais
      // inicio bloco escala
      posY = 14;
      posY += 5;
      doc.setFontType('bold');
      doc.text('Escala:', 112, posY, 'left');
      doc.setFontType('normal');
      doc.setFontSize(11);
      doc.text(`${dadosTabela[a][0].ESCALA}`, 126, posY, 'left');
      posY += 10;
      doc.setFontType('bold');
      doc.text('Posto:', 112, posY, 'left');
      doc.setFontType('normal');
      doc.setFontSize(11);
      doc.text(`${dadosTabela[a][0].POSTO}`, 126, posY, 'left');
      // fim bloco de escala
      posY = 49;
      posY += 15;
      doc.setFontType('bold');
      doc.setFontSize(13);
      // header da Tabela
      doc.text('Dia', 20, posY, 'center');
      doc.text('Pontos', 68, posY, 'center');
      doc.text('Abono', 120, posY, 'center');
      doc.text('HAT', 159, posY, 'center');
      doc.text('HT', 171, posY, 'center');
      doc.text('EX', 183, posY, 'center');
      doc.text('FA', 195, posY, 'center');
      doc.setFontType('normal');
      doc.setFontSize(10);
      posY += 5;
      dadosTabela[a].forEach((dado) => {
        doc.text(
          `${formatarDataAnoMesDia(dado.DATA_INICIAL_DIA_AGENDA)}`,
          10,
          posY,
          'left',
        );
        doc.text(dado.DIAS_SEMANA, 35, posY, 'left');
        doc.text(
          `${
            dado.PONTO_LATITUDE_ENTRADA
              ? formatarHoraMin(dado.PONTO_HORA_ENTRADA)
              : '-'
          }`,
          50,
          posY,
          'center',
        );
        doc.text(
          `${
            dado.PONTO_HORA_PAUSA ? formatarHoraMin(dado.PONTO_HORA_PAUSA) : '-'
          }`,
          63,
          posY,
          'center',
        );
        doc.text(
          `${
            dado.PONTO_HORA_VOLTA ? formatarHoraMin(dado.PONTO_HORA_VOLTA) : '-'
          }`,
          76,
          posY,
          'center',
        );
        doc.text(
          `${
            dado.PONTO_HORA_SAIDA ? formatarHoraMin(dado.PONTO_HORA_SAIDA) : '-'
          }`,
          89,
          posY,
          'center',
        );
        doc.text(`${preencheMotivoPdf(dado)}`, 120, posY, {
          align: 'center',
          maxWidth: 50,
        });
        doc.text(
          dado.HORAS_A_TRABALHAR.slice(0, dado.HORAS_A_TRABALHAR.length - 3),
          159,
          posY,
          'center',
        ); // hat
        doc.text(
          `${
            dado.TEMPO_TRABALHADO_MS! > 0
              ? tempoMsParaHorasEMin(dado.TEMPO_TRABALHADO_MS!)
              : '-'
          }`,
          171,
          posY,
          'center',
        ); // ht
        doc.text(
          `${
            dado.DIFERENCA_TEMPO_MS! < 0
              ? tempoMsParaHorasEMin(dado.DIFERENCA_TEMPO_MS! * -1)
              : '-'
          }`,
          183,
          posY,
          'center',
        ); // ex
        doc.text(
          `${
            dado.DIFERENCA_TEMPO_MS! > 0
              ? tempoMsParaHorasEMin(dado.DIFERENCA_TEMPO_MS!)
              : '-'
          }`,
          195,
          posY,
          'center',
        ); // fa
        posY += 4;
      });
      posY += 13;
      // doc.setFontSize(10);
      // posY = 193;
      // doc.rect(10, 207, 190, 85);
      // doc.setFontType('bold');
      // posY += 20;
      // doc.text('HT:', 12, posY);
      // doc.setFontType('normal');
      // doc.text(tempoMsParaHorasEMin(horasTrabalhadas), 30, posY);
      // posY += 5;
      // doc.setFontType('bold');
      // doc.text('HAT:', 12, posY);
      // doc.setFontType('normal');
      // doc.text(tempoMsParaHorasEMin(horasATrabalhar), 30, posY);
      // posY += 5;
      // doc.setFontType('bold');
      // doc.text('FA:', 12, posY);
      // doc.setFontType('normal');
      // doc.text(tempoMsParaHorasEMin(horasFalta), 30, posY);
      // posY += 5;
      // doc.setFontType('bold');
      // doc.text('EX:', 12, posY);
      // doc.setFontType('normal');
      // doc.text(tempoMsParaHorasEMin(horasExtra), 30, posY);
      // posY += 8;
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text(
        'Reconheço como verdadeiros meus horários de entrada e saída contidos neste relatório.',
        12,
        posY,
        'left',
      );
      posY += 5;
      doc.setFontType('bold');
      doc.setFontSize(9.5);
      doc.text(
        `Como responsável, reconheço como verdadeiros os horários de entrada e saída do funcionário.`,
        12,
        posY,
        {
          // align: 'justify',
          maxWidth: 165,
        },
      );
      posY += 10;
      doc.setFontSize(10);
      doc.line(12, posY + 3, 100, posY + 3);
      doc.setFontType('normal');
      doc.text('Assinatura do Funcionário', 56, posY + 6, 'center');
      doc.setFontType('bold');
      doc.line(112, posY + 3, 199, posY + 3);
      doc.setFontType('normal');
      doc.text('Assinatura do Responsável', 156, posY + 6, 'center');
      doc.addPage();
    });
    doc.save(`Registro_ponto.pdf`);
    // fim bloco ponto
    // psy 193
    // posY = 205;
    // doc.setFontSize(9);
    // doc.text(`${textoAviso}`, 12, posY, 'left');
    // doc.setFontSize(11);
    // posY = 193;
    // doc.rect(10, 207, 190, 85);
    // doc.setFontType('bold');
    // posY += 20;
    // doc.text('HT - Horas trabalhadas:', 12, posY, 'left');
    // doc.text('EX 1 a 50%', 98, posY, 'left');
    // doc.text('EN 1 a 50%', 153, posY, 'left');
    // doc.setFontType('normal');
    // doc.text(`${formatarMinEmHoraMin(horasTrabalhadas)}`, 85, posY, 'right');
    // doc.text(`${formatarMinEmHoraMin(horasExtra)}`, 140, posY, 'right');
    // doc.text('00:00', 195, posY, 'right');
    // posY += 5;
    // doc.setFontType('bold');
    // doc.text('HN - Horas Noturnas:', 12, posY, 'left');
    // doc.text('EX 2 a 100%', 98, posY, 'left');
    // doc.text('EN 2 a 100%', 153, posY, 'left');
    // doc.setFontType('normal');
    // doc.text('00:00', 85, posY, 'right');
    // doc.text('00:00', 140, posY, 'right');
    // doc.text('00:00', 195, posY, 'right');
    // posY += 5;
    // doc.setFontType('bold');
    // doc.text('FA - Faltas:', 12, posY, 'left');
    // doc.setFontType('normal');
    // doc.text(`${formatarMinEmHoraMin(horasFalta)}`, 85, posY, 'right');
    // posY += 5;
    // doc.setFontType('bold');
    // doc.text('Horas Nortunas = Normais:', 12, posY, 'left');
    // doc.setFontType('normal');
    // doc.text('00:00', 85, posY, 'right');
    // posY += 8;
    // doc.setFontType('bold');
    // doc.text('Total a Trabalhar:', 12, posY, 'left');
    // doc.setFontType('normal');
    // doc.text(`${formatarMinEmHoraMin(horasATrabalhadas)}`, 85, posY, 'right');
    // posY += 5;
    // doc.setFontType('bold');
    // doc.text('Dias Completos de Falta:', 12, posY, 'left');
    // doc.setFontType('normal');
    // doc.text(
    //   `${CalcularFaltaDiasCompleto(dados[0].HORAS_A_TRABALHAR, horasFalta)}`,
    //   85,
    //   posY,
    //   'right',
    // );
    // posY += 8;
    // doc.setFontType('bold');
    // doc.text('Total a Trabalhar - Faltas:', 12, posY, 'left');
    // doc.setFontType('normal');
    // doc.text(
    //   `${formatarMinEmHoraMin(horasATrabalhadas - horasFalta)}`,
    //   85,
    //   posY,
    //   'right',
    // );
  }, [dadosTabela, preencheMotivoPdf]);

  // useEffect(() => {
  //   copiaDadosComCorrecaoAbono();
  // }, [copiaDadosComCorrecaoAbono]);

  // useEffect(() => {
  //   const hatFinal: number[] = []; // horas a trabalhar
  //   const htFinal: number[] = []; // horas trabalhadas
  //   const hfFinal: number[] = []; // horas de falta
  //   const heFinal: number[] = []; // horas de Extra
  //   // console.log(dados);
  //   const copia = dadosTabela;
  //   Object.keys(copia).forEach((a) =>
  //     copia[a].map((d) => {
  //       if (
  //         d.DESC_DIA ||
  //         d.DESC_PERIODO ||
  //         d.DESC_FALTA ||
  //         d.DESC_ENTRADA ||
  //         d.DESC_PAUSA ||
  //         d.DESC_VOLTA ||
  //         d.DESC_SAIDA
  //       )
  //         return {
  //           ...d,
  //           HORAS_TRABALHADAS_MENOS_HORA_PAUSA: d.HORAS_A_TRABALHAR,
  //           DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS: 0,
  //         };
  //       return d;
  //     }),
  //   );
  //   const copiaDados = copia;
  //   Object.keys(copiaDados).forEach((a) =>
  //     copiaDados[a].forEach((dado) => {
  //       let hat = 0; // horas a trabalhar
  //       let ht = 0; // horas trabalhadas
  //       let hf = 0; // horas de falta
  //       let he = 0; // horas de Extra
  //       hat += formatarStringToNumber(dado.HORAS_A_TRABALHAR);
  //       ht += formatarStringToNumber(dado.HORAS_TRABALHADAS_MENOS_HORA_PAUSA);
  //       if (
  //         dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS &&
  //         dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS > 0
  //       ) {
  //         he += dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS;
  //       } else if (
  //         dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS
  //       ) {
  //         hf += Math.abs(
  //           dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS,
  //         );
  //       }
  //       if (
  //         dado.HORAS_TRABALHADAS_MENOS_HORA_PAUSA === null &&
  //         !(
  //           dado.DIAS_SEMANA.toLowerCase() === 'sábado' ||
  //           dado.DIAS_SEMANA.toLowerCase() === 'domingo'
  //         )
  //       ) {
  //         hf += CalcularFalta(
  //           dado.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS ?? 0,
  //           dado.HORAS_TRABALHADAS_MENOS_HORA_PAUSA,
  //           dado.DATA_INICIAL_DIA_AGENDA,
  //           dado.HORAS_A_TRABALHAR,
  //         );
  //       }
  //       hatFinal.push(hat);
  //       htFinal.push(ht);
  //       hfFinal.push(hf);
  //       heFinal.push(he);
  //       console.log('hat---', hat);
  //       console.log('ht----', ht);
  //       console.log('hf-----', hf);
  //       console.log('he------', he);
  //     }),
  //   );
  //   setHorasATrabalhadas(hatFinal);
  //   setHorasTrabalhadas(htFinal);
  //   setHorasFalta(hfFinal);
  //   setHorasExtra(heFinal);
  //   console.log('hatFinal-', hatFinal);
  //   console.log('htFinal--', htFinal);
  //   console.log('hfFinal---', hfFinal);
  //   console.log('heFinal----', heFinal);
  // }, [dadosTabela]);

  return (
    <ContainerModal id="modalRelatorio" onClick={(e) => handleOutSideClick(e)}>
      {dados.length > 0 && (
        <Container>
          <ContainerColunasHeader>
            Registro de Pontos
            <ContainerButton>
              <button type="button" onClick={() => onClose()}>
                X
              </button>
            </ContainerButton>
          </ContainerColunasHeader>
          {Object.keys(dadosTabela).map((a) => (
            <React.Fragment key={a}>
              <ContainerHeaderDados>
                <ContainerPessoais>
                  <table>
                    <tbody>
                      <tr>
                        <td>Empresa:</td>
                        <td>{dadosTabela[a][0].NOME_EMPRESA}</td>
                      </tr>
                      <tr>
                        <td>Nome:</td>
                        <td>{dadosTabela[a][0].NOME}</td>
                      </tr>
                      <tr>
                        <td>CPF:</td>
                        <td>{dadosTabela[a][0].CPF}</td>
                      </tr>
                    </tbody>
                  </table>
                </ContainerPessoais>
                <ContainerPessoais>
                  <table>
                    <tbody>
                      <tr>
                        <td>Escala:</td>
                        <td>{dadosTabela[a][0].ESCALA}</td>
                      </tr>
                      <tr>
                        <td>Posto:</td>
                        <td>{dadosTabela[a][0].POSTO}</td>
                      </tr>
                    </tbody>
                  </table>
                </ContainerPessoais>
              </ContainerHeaderDados>
              <ContainerBodyDados>
                <TableRegistro
                  style={{ width: '100%' }}
                  id={dadosTabela[a][0].CPFSEMFORMATO}
                >
                  <thead>
                    <tr>
                      {clData && <th>Data</th>}
                      {clDia && <th>Dia</th>}
                      {clPontos && <th colSpan={4}>Pontos</th>}
                      {clAbono && <th>Abono</th>}
                      {/* {clHN && <th>HN</th>}
                  {clEN && <th>EN</th>} */}
                      {clHT && <th>HT</th>}
                      {clEX && <th>EX</th>}
                      {clFA && <th>FA</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {dadosTabela[a].map(
                      (res: ResponseRelatorio, index: number) => (
                        <tr key={`${res.DATA_INICIAL_DIA_AGENDA + index}`}>
                          {clData && (
                            <td>
                              {formatarDataAnoMesDia(
                                res.DATA_INICIAL_DIA_AGENDA,
                              )}
                            </td>
                          )}
                          {clDia && <td>{res.DIAS_SEMANA.toLowerCase()}</td>}

                          {clPontos && (
                            <>
                              <td>
                                {res.PONTO_HORA_ENTRADA
                                  ? formatarHoraMin(res.PONTO_HORA_ENTRADA)
                                  : '-'}
                              </td>
                              <td>
                                {res.PONTO_HORA_PAUSA
                                  ? formatarHoraMin(res.PONTO_HORA_PAUSA)
                                  : '-'}
                              </td>

                              <td>
                                {res.PONTO_HORA_VOLTA
                                  ? formatarHoraMin(res.PONTO_HORA_VOLTA)
                                  : '-'}
                              </td>

                              <td>
                                {res.PONTO_HORA_SAIDA
                                  ? formatarHoraMin(res.PONTO_HORA_SAIDA)
                                  : '-'}
                              </td>
                            </>
                          )}
                          {clAbono && (
                            <td>
                              {preencheMotivoPdf(res).length !== 0
                                ? preencheMotivoPdf(res)
                                : '-'}
                            </td>
                          )}
                          {/* <td>-</td>
                    <td>-</td> */}
                          {clHT && (
                            <td>
                              {res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA
                                ? formatarHoraMin(
                                    res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA,
                                  )
                                : '-'}
                            </td>
                          )}
                          {clEX && (
                            <td>
                              {res.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS &&
                              res.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS >
                                0
                                ? formatarMinEmHoraMin(
                                    res.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS,
                                  )
                                : '-'}
                            </td>
                          )}
                          {clFA && (
                            <td>
                              {(res.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS &&
                                res.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS <
                                  0) ||
                              (res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA ===
                                null &&
                                !(
                                  res.DIAS_SEMANA.toLowerCase() === 'sábado' ||
                                  res.DIAS_SEMANA.toLowerCase() === 'domingo'
                                ))
                                ? formatarMinEmHoraMin(
                                    CalcularFalta(
                                      res.DIFERENCA_EM_MINUTOS_HORAS_A_TRABALHAR_HORAS_TRABALHADAS ??
                                        0,
                                      res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA,
                                      res.DATA_INICIAL_DIA_AGENDA,
                                      res.HORAS_A_TRABALHAR,
                                    ),
                                  )
                                : '-'}
                            </td>
                          )}
                        </tr>
                      ),
                    )}
                  </tbody>
                </TableRegistro>
              </ContainerBodyDados>
            </React.Fragment>
          ))}
          {/* <ContainerDadosDetalhes>
            <p>{textoAviso}</p>
            <ContainerBoxBorder>
              <DadosDetalhes>
                <table>
                  <tbody>
                    <tr>
                      <td className="tdColumn">HT - Horas trabalhadas:</td>
                      <td style={{ minWidth: 55 }}>
                        {formatarMinEmHoraMin(horasTrabalhadas)}
                      </td>
                    </tr>
                    <tr>
                      <td>HN - Horas Noturnas:</td>
                      <td>00:00</td>
                    </tr>
                    <tr>
                      <td>FA - Faltas:</td>
                      <td>{formatarMinEmHoraMin(horasFalta)}</td>
                    </tr>
                    <tr>
                      <td>Horas Nortunas = Normais:</td>
                      <td>00:00</td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td className="tdColumn">Total a Trabalhar:</td>
                      <td style={{ minWidth: 55 }}>
                        {formatarMinEmHoraMin(horasATrabalhadas)}
                      </td>
                    </tr>
                    <tr>
                      <td>Dias Completos de Falta:</td>
                      <td>
                        {CalcularFaltaDiasCompleto(
                          dados[0].HORAS_A_TRABALHAR,
                          horasFalta,
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td className="tdColumn">Total a Trabalhar - Faltas:</td>
                      <td style={{ minWidth: 55 }}>
                        {formatarMinEmHoraMin(horasATrabalhadas - horasFalta)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </DadosDetalhes>
              <DadosDetalhes>
                <table>
                  <tbody>
                    <tr>
                      <td> EX 1 a 50%</td>
                      <td>{formatarMinEmHoraMin(horasExtra)}</td>
                    </tr>
                    <tr>
                      <td> EX 2 a 100%</td>
                      <td>00:00</td>
                    </tr>
                  </tbody>
                </table>
              </DadosDetalhes>
              <DadosDetalhes>
                <table>
                  <tbody>
                    <tr>
                      <td> EN 1 a 50%</td>
                      <td>00:00</td>
                    </tr>
                    <tr>
                      <td> EN 2 a 100%</td>
                      <td>00:00</td>
                    </tr>
                  </tbody>
                </table>
              </DadosDetalhes>
            </ContainerBoxBorder>
          </ContainerDadosDetalhes> */}
          <ContainerButtonPDF>
            <button type="button" onClick={() => setColunasTab(true)}>
              Colunas
            </button>
            <button type="button" onClick={handleDownloadPDF}>
              Gerar PDF
            </button>
          </ContainerButtonPDF>
        </Container>
      )}
      {!(dados.length > 0) && (
        <Container>
          <ContainerButton>
            <button type="button" onClick={onClose}>
              X
            </button>
          </ContainerButton>
          <ContainerHeader>
            <p>Não existe registro para esse mês.</p>
          </ContainerHeader>
        </Container>
      )}
      {colunasTab && (
        <ContainerColunas>
          <ContainerColunasHeader>
            Colunas
            <ContainerButton>
              <button type="button" onClick={() => setColunasTab(false)}>
                X
              </button>
            </ContainerButton>
          </ContainerColunasHeader>
          <ContainerColunasBody>
            <label htmlFor="clData">
              <input
                type="checkbox"
                checked={clData}
                onChange={(e) => setClData(e.currentTarget.checked)}
                name="clData"
                id="clData"
              />
              Data
            </label>

            <label htmlFor="clDia">
              <input
                type="checkbox"
                checked={clDia}
                onChange={(e) => setClDia(e.currentTarget.checked)}
                name="clDia"
                id="clDia"
              />
              Dia
            </label>
            <label htmlFor="clPontos">
              <input
                type="checkbox"
                checked={clPontos}
                onChange={(e) => setClPontos(e.currentTarget.checked)}
                name="clPontos"
                id="clPontos"
              />
              Pontos
            </label>
            <label htmlFor="clAbono">
              <input
                type="checkbox"
                checked={clAbono}
                onChange={(e) => setClAbono(e.currentTarget.checked)}
                name="clAbono"
                id="clAbono"
              />
              Abono
            </label>
            <label htmlFor="clHT">
              <input
                type="checkbox"
                checked={clHT}
                onChange={(e) => setClHT(e.currentTarget.checked)}
                name="clHT"
                id="clHT"
              />
              HT
            </label>
            <label htmlFor="clEX">
              <input
                type="checkbox"
                checked={clEX}
                onChange={(e) => setClEX(e.currentTarget.checked)}
                name="clEX"
                id="clEX"
              />
              EX
            </label>
            <label htmlFor="clFA">
              <input
                type="checkbox"
                checked={clFA}
                onChange={(e) => setClFA(e.currentTarget.checked)}
                name="clFA"
                id="clFA"
              />
              FA
            </label>
          </ContainerColunasBody>
          {/* <ContainerColunasFooter>
            <button type="button" onClick={() => setColunasTab(false)}>
              Salvar
            </button>
          </ContainerColunasFooter> */}
        </ContainerColunas>
      )}
    </ContainerModal>
  );
};
