import React from 'react';
import { Container } from './styles';

interface CheckInterface {
  valorCheck?: boolean;
  disabledCheck?: boolean;
  onChangeCheck: (arg: boolean) => void;
}

const CheckBox: React.FC<CheckInterface> = ({
  onChangeCheck,
  disabledCheck,
  valorCheck,
}: CheckInterface) => {
  const onChangeItem = (value: boolean): void => {
    onChangeCheck(value);
  };

  return (
    <Container>
      <input
        type="checkbox"
        defaultChecked={valorCheck}
        disabled={disabledCheck}
        onChange={(e) => onChangeItem(e.target.checked)}
      />
    </Container>
  );
};

export default CheckBox;
