import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.appcolaborador.com/', // producao
  // baseURL: 'http://localhost:3333/', // igor
  // baseURL: 'https://89e45d6c0ed8.ngrok.io',
});

export const apiSesmt = axios.create({
  baseURL: 'https://api.appfluigservnac.com/', // producao
  // baseURL: 'https://e5671d929967.ngrok.io/', // igor
  // baseURL: 'https://b4870d36e71a.ngrok.io', // Kewton
});

export const apiJsonDoc = axios.create({
  baseURL: 'https://apiocr.approvalservnac.com/api/v1/imagedata/',
});

export default api;
