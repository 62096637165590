import React from 'react';
import { Menu } from 'antd';
import Swal from 'sweetalert2';

import { AiFillHome } from 'react-icons/ai';
import { IoIosPeople } from 'react-icons/io';
import {
  FaFileInvoiceDollar,
  FaFingerprint,
  FaSearchDollar,
  FaTrash,
  FaUsersCog,
  FaWarehouse,
} from 'react-icons/fa';

import { useHistory } from 'react-router-dom';
import { RiAdminFill, RiFileUserLine } from 'react-icons/ri';
import { FiBriefcase, FiPhoneCall } from 'react-icons/fi';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { GiHealthNormal } from 'react-icons/gi';
import { ImExit } from 'react-icons/im';
import { useAuth } from '../../hooks/auth';
import { Container } from './styles';

const MenuLateral: React.FC = () => {
  const { SubMenu } = Menu;
  const history = useHistory();
  const { signOut, userAcesso } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleClick(e: any): void {
    // console.log(userAcesso);
    if (e.key === '/signout') {
      Swal.fire({
        title: 'Tem certeza que deseja sair?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          signOut();
        }
      });

      // signOut();
    } else {
      history.push(e.key);
    }
  }

  return (
    <>
      <Container>
        <Menu
          onClick={handleClick}
          style={{ width: 280, background: '#004d00' }}
          mode="vertical"
          theme="dark"
        >
          <Menu.Item key="/" icon={<AiFillHome />} style={{ color: '#fff' }}>
            Dashboard
          </Menu.Item>
          <Menu.Item
            key="/contra-cheque"
            icon={<FaFileInvoiceDollar />}
            style={{ color: '#fff' }}
          >
            Contracheque
          </Menu.Item>
          <Menu.Item
            key="/imposto-de-renda"
            icon={<FaSearchDollar />}
            style={{ color: '#fff' }}
          >
            Imposto de renda
          </Menu.Item>
          {(userAcesso.map(({ SetorID }) => SetorID).includes(13) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub2"
              icon={<IoIosPeople />}
              title="Recursos Humanos"
              style={{
                background: '#004d00',
                color: '#fff',
                padding: 0,
                margin: 0,
              }}
            >
              <Menu.Item
                key="/cadastro-admissao"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Cadastro Admissão
              </Menu.Item>
              <Menu.Item
                key="/cadastro-cargo"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Cadastro de Cargo
              </Menu.Item>
              <Menu.Item
                key="/validacao-documentos-admissao"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Validar Documentos de Admissão
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(14) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub3"
              icon={<RiFileUserLine />}
              title="Dep. Pessoal"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/validacao-documentos-funcionarios"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Validar Documentos de Funcionários
              </Menu.Item>
              <Menu.Item
                key="/solicitacao-vaga"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Aprovação de Admissão
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(7) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub5"
              icon={<RiAdminFill />}
              title="Administração"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/auto-declaracao-analise"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Auto Declaração de Saúde
              </Menu.Item>
              <Menu.Item
                key="/user-access"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Nível de Acesso
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(3) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub6"
              icon={<FiPhoneCall />}
              title="Call Center"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/restore-password"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Recuperar Senha
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(2) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub7"
              icon={<FaUsersCog />}
              title="Operacional"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/visualizar-ausencia"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Falta Programada
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(16) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub8"
              icon={<HiOutlineLightBulb />}
              title="Marketing"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/upload-post"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Upload Feed
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(11) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub9"
              icon={<FaWarehouse />}
              title="Estoque"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/list-users-qrcode"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Usuários QR Code
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(17) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <>
              <SubMenu
                key="sub10"
                icon={<FaFingerprint />}
                title="Ponto"
                style={{ background: '#004d00', color: '#fff' }}
              >
                <Menu.Item
                  key="/view-ponto"
                  style={{
                    background: '#004d00',
                    color: '#fff',
                    margin: 0,
                  }}
                >
                  Gestão Ponto
                </Menu.Item>
                <Menu.Item
                  key="/indicadores"
                  style={{
                    background: '#004d00',
                    color: '#fff',
                    margin: 0,
                  }}
                >
                  Indicadores
                </Menu.Item>
                <Menu.Item
                  key="/visualizar-ponto"
                  style={{
                    background: '#004d00',
                    color: '#fff',
                    margin: 0,
                  }}
                >
                  Relatório Ponto
                </Menu.Item>
                <Menu.Item
                  key="/cadastro-abono"
                  style={{
                    background: '#004d00',
                    color: '#fff',
                    margin: 0,
                  }}
                >
                  Cadastro Abono
                </Menu.Item>
              </SubMenu>
            </>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(6) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub11"
              icon={<GiHealthNormal />}
              title="SESMT"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/declaracao-vacinacao"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Gerar Declaração de Vacinação
              </Menu.Item>
              <Menu.Item
                key="/visualizar-atestados-medicos"
                style={{ backgroundColor: '#004d00', color: '#fff', margin: 0 }}
              >
                Atestados Médicos
              </Menu.Item>
            </SubMenu>
          )}

          {(userAcesso.map(({ SetorID }) => SetorID).includes(6) ||
            userAcesso.map(({ SetorID }) => SetorID).includes(15)) && (
            <SubMenu
              key="sub12"
              icon={<FiBriefcase />}
              title="Trabalhe Conosco"
              style={{ background: '#004d00', color: '#fff' }}
            >
              <Menu.Item
                key="/vagas"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Gestão de Vagas
              </Menu.Item>
              <Menu.Item
                key="/curriculos"
                style={{
                  background: '#004d00',
                  color: '#fff',
                  margin: 0,
                }}
              >
                Currículos Enviados
              </Menu.Item>
            </SubMenu>
          )}
          <Menu.Item
            key="/apagar-conta"
            icon={<FaTrash />}
            style={{ color: '#fff' }}
          >
            Apagar conta
          </Menu.Item>

          <Menu.Item
            key="/signout"
            icon={<ImExit />}
            style={{ background: '#004d00', color: '#fff' }}
          >
            Sair
          </Menu.Item>
        </Menu>
      </Container>
    </>
  );
};

export default MenuLateral;
