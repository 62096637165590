import React from 'react';

import HeaderTable from '../../components/HeaderTable';
import { formatarDataAnoMesDia } from '../../components/Formulas/formulas';
import { maskCpf } from '../../components/Input/mask';
import { AusenciaAtestado } from './interface/atestado';
import { ContainerTable } from './styles';
import { headers } from './interface/headers';
import { FaltaApontamento } from './interface/faltaApontamento';

interface DadosTable {
  dados: AusenciaAtestado[];
}

const TableAtestados: React.FC<DadosTable> = ({ dados }) => {
  function viewTableFaltaApont(faltas: FaltaApontamento[]): JSX.Element[] {
    return faltas.map((item, index) => (
      <div key={index.toString()}>
        {formatarDataAnoMesDia(item.DATA_MANUTENCAO)} - {item.DESC_MOTIVO_FALTA}
      </div>
    ));
  }

  function viewNotCRMProtheus(type: string, existeProthueus: boolean): string {
    if (type !== 'CRM') return '-';
    if (existeProthueus) return 'SIM';
    return 'NÃO';
  }

  function viewNotCROProtheus(type: string, existeProthueus: boolean): string {
    if (type !== 'CRO') return '-';
    if (existeProthueus) return 'SIM';
    return 'NÃO';
  }

  return (
    <ContainerTable>
      <table id="AtestadosExcel">
        <HeaderTable headers={headers} />
        <tbody>
          {dados.map((res) => (
            <tr key={res.ID}>
              <td style={{ minWidth: 200 }}>{res.NOME_FUNC}</td>
              <td style={{ minWidth: 120 }}>{maskCpf(res.CPF)}</td>
              <td style={{ minWidth: 60 }}>{res.FILIAL}</td>
              <td style={{ minWidth: 60 }}>{res.MATRICULA}</td>
              <td style={{ minWidth: 80 }}>
                {res.FICHA_MEDICA ? res.FICHA_MEDICA : '-'}
              </td>
              <td>{res.FICHA_MEDICA ? 'SIM' : 'NÃO'}</td>
              <td>{res.afastRAIS}</td>
              <td>{res.codAusencia}</td>
              <td>{res.ID}</td>
              <td style={{ minWidth: 100 }}>
                {res.FaltaApontamento.length !== 0
                  ? viewTableFaltaApont(res.FaltaApontamento)
                  : '-'}
              </td>
              <td>{res.ABONO ? 'SIM' : 'NÃO'}</td>
              <td style={{ minWidth: 200 }}>{res.NOME_MEDICO}</td>
              <td style={{ minWidth: 100 }}>{res.TYPE}</td>
              <td style={{ minWidth: 100 }}>{res.CRM ? res.CRM : '-'}</td>
              <td>{viewNotCRMProtheus(res.TYPE, res.TEM_CRM_PROTHEUS)}</td>
              <td style={{ minWidth: 100 }}>{res.CRO ? res.CRO : '-'}</td>
              <td>{viewNotCROProtheus(res.TYPE, res.TEM_CRO_PROTHEUS)}</td>
              <td style={{ minWidth: 100 }}>{res.CIDGrup}</td>
              <td style={{ minWidth: 100 }}>{res.CID}</td>
              <td style={{ minWidth: 120 }}>{res.LOCAL_ATESTADO}</td>
              <td style={{ minWidth: 100 }}>
                {formatarDataAnoMesDia(res.DATA_INICIO)}
              </td>
              <td style={{ minWidth: 100 }}>
                {formatarDataAnoMesDia(res.DATA_FINAL)}
              </td>
              <td>{res.QUANTIDADE_DIAS}</td>
              <td style={{ minWidth: 60 }}>{res.VEZES_ALTERADO}</td>
              <td style={{ width: 500 }}>
                {res.IMAGEM_ORIGINAL_NAME === null
                  ? '-'
                  : res.IMAGEM_ORIGINAL_NAME}
              </td>
              <td>{res.ENVIADO_PROTHEUS ? 'SIM' : 'NÃO'}</td>
              <td style={{ minWidth: 90 }}>
                {(res.APROVADO === null || res.APROVADO === true) && (
                  <p>Ainda não foi visualizado</p>
                )}
                {!(res.APROVADO === null || res.APROVADO === true) && (
                  <p>Não foi aceito</p>
                )}
                {res.APROVADO && <p>Foi aceito</p>}
              </td>
              <td>{res.MOTIVO_APROVACAO}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ContainerTable>
  );
};

export default TableAtestados;
