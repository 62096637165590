import React, { useState, useCallback, useEffect } from 'react';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { Loading } from '../../../components/Loading';
import { ResponseGet } from '../Interface';

import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeaderDados,
} from './styles';

interface DropdownInterface {
  id: number;
  valor: string;
}

interface ModalArquivo {
  onClose(): void;
  onSave?(): void;
  dados: ResponseGet[];
  idUser: string;
  abono: DropdownInterface[];
}

const ModalAbono: React.FC<ModalArquivo> = ({
  dados,
  idUser,
  abono,
  onClose = () => {
    ('');
  },
  onSave = () => {
    ('');
  },
}) => {
  const [motivoAbono, setMotivoAbono] = useState<string>('Nenhum');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log('ModalAbono', dados);
  console.log('idUser', idUser);
  const handleSalvarAbono = useCallback(async () => {
    let id = 0;
    const arrayData: string[] = [];
    const arrayAbono: boolean[] = [];
    const arrayMotivo: string[] = [];
    try {
      setLoading(true);
      dados.forEach((dado) => {
        if (!dado.ABONO && dado.ATRASO) {
          dado.MOTIVO_ABONO = motivoAbono;
          id = dado.Id_UsersAppColaborador;
          arrayData.push(dado.DATA_INICIAL_DIA_AGENDA);
          arrayAbono.push(true);
          arrayMotivo.push(motivoAbono);
        }
      });
      await api
        .post('/pontoEletronico/abonoFaltas', {
          id_UsersAppColaborador: id,
          dataAbono: arrayData,
          abono: arrayAbono,
          motivo_abono: arrayMotivo,
          Id_UsersAppColaborador_Abono_Falta: idUser,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Abono salvo com sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          onSave();
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao salvar o abono.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'info',
        title: 'Erro ao carregar dados, tente novamente!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [dados, idUser, motivoAbono, onSave]);

  const handleAlert = useCallback(() => {
    Swal.fire({
      icon: 'info',
      title: 'Motivo é obrigatório!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }, []);

  return (
    <>
      <div>
        <ContainerModal id="modal">
          <Container>
            <ContainerButton>
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                X
              </button>
            </ContainerButton>
            <ContainerHeaderDados>
              <p>{`Abonar todos os atrasos do(a) ${dados[0].NOME}.`}</p>
            </ContainerHeaderDados>
          </Container>
        </ContainerModal>
      </div>
      {loading && <Loading />}
    </>
  );
};

export default ModalAbono;
