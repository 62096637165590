import React, { useState } from 'react';

import { AiFillCaretLeft, AiFillCaretDown } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa';

import {
  ContainerFiltro,
  Aba,
  DivIcons,
  DivIconCheck,
  DivIconClose,
} from './styles';

interface PropsBottoms {
  isJustBottom?: boolean;
  isAbonoBatida?: boolean;
  isAbonoDia?: boolean;
  isAbonoPeriodo?: boolean;
  isApagarAbono?: boolean;
  returnAbonoBatida?(): void;
  returnAbonoDia?(): void;
  returnAbonoPeriodo?(): void;
  returnApagarAbono?(): void;
}
interface PropsIcons {
  whichIcons: string;
}

export const BottomsExportAndAbonoIndex: React.FC<PropsBottoms> = ({
  /** isJustBottom igual a TRUE ele renderizará apenas os buttons sem
   *   os components <ContainerFiltro> e <Aba> caso isJustBottom seja
   *   FALSE irá renderizar com os components <ContainerFiltro> e <Aba>.
   */
  isJustBottom = false,
  /** Se deve ser renderizado os buttons de Abono (Batida, Dia e
   *  Período.)
   */
  isAbonoBatida = true,
  isAbonoDia = true,
  isAbonoPeriodo = true,
  isApagarAbono = true,
  /** São os onClick de cada botton. Eles vão escutar o código que está
   * dentro do returno do componente que chamou esse componente BottomsExportAndAbonoIndex
   */
  returnAbonoBatida = () => {
    ('');
  },
  returnAbonoDia = () => {
    ('');
  },
  returnAbonoPeriodo = () => {
    ('');
  },
  returnApagarAbono = () => {
    ('');
  },
}) => {
  const [setaAbono, setSetaAbono] = useState('');

  return (
    <>
      {isJustBottom === true ? (
        <div className="dropdown">
          <button
            type="button"
            className="dropbtn"
            onFocus={(): void => {
              setSetaAbono('AiFillCaretDown');
            }}
            onMouseOver={(): void => {
              setSetaAbono('AiFillCaretDown');
            }}
            onBlur={(): void => {
              setSetaAbono('AiFillCaretLeft');
            }}
            onMouseOut={(): void => {
              setSetaAbono('AiFillCaretLeft');
            }}
          >
            <p style={{ margin: 0, padding: 0 }}>Abono</p>
            {setaAbono === 'AiFillCaretLeft' ? (
              <AiFillCaretLeft />
            ) : (
              <AiFillCaretDown />
            )}
          </button>
          <div
            className="dropdown-content"
            onFocus={(): void => {
              setSetaAbono('AiFillCaretDown');
            }}
            onMouseOver={(): void => {
              setSetaAbono('AiFillCaretDown');
            }}
            onBlur={(): void => {
              setSetaAbono('AiFillCaretLeft');
            }}
            onMouseOut={(): void => {
              setSetaAbono('AiFillCaretLeft');
            }}
          >
            {isAbonoBatida && (
              <button
                className="buttonAbonar"
                type="button"
                onClick={() => {
                  returnAbonoBatida();
                }}
              >
                Abonar Batida
              </button>
            )}
            {isAbonoDia && (
              <button
                className="buttonAbonar"
                type="button"
                onClick={() => {
                  returnAbonoDia();
                }}
              >
                Abonar Dia
              </button>
            )}
            {isAbonoPeriodo && (
              <button
                className="buttonAbonar"
                type="button"
                onClick={() => {
                  returnAbonoPeriodo();
                }}
              >
                Abonar Período
              </button>
            )}
            {isApagarAbono && (
              <button
                className="buttonAbonar"
                type="button"
                onClick={() => {
                  returnApagarAbono();
                }}
              >
                Apagar Abono
              </button>
            )}
          </div>
        </div>
      ) : (
        <ContainerFiltro>
          <Aba>
            <div className="dropdown">
              <button
                type="button"
                className="dropbtn"
                onFocus={(): void => {
                  setSetaAbono('AiFillCaretDown');
                }}
                onMouseOver={(): void => {
                  setSetaAbono('AiFillCaretDown');
                }}
                onBlur={(): void => {
                  setSetaAbono('AiFillCaretLeft');
                }}
                onMouseOut={(): void => {
                  setSetaAbono('AiFillCaretLeft');
                }}
              >
                <p style={{ margin: 0, padding: 0 }}>Abono</p>
                {setaAbono === 'AiFillCaretLeft' ? (
                  <AiFillCaretLeft />
                ) : (
                  <AiFillCaretDown />
                )}
              </button>
              <div
                className="dropdown-content"
                onFocus={(): void => {
                  setSetaAbono('AiFillCaretDown');
                }}
                onMouseOver={(): void => {
                  setSetaAbono('AiFillCaretDown');
                }}
                onBlur={(): void => {
                  setSetaAbono('AiFillCaretLeft');
                }}
                onMouseOut={(): void => {
                  setSetaAbono('AiFillCaretLeft');
                }}
              >
                {isAbonoBatida && (
                  <button
                    className="buttonAbonar"
                    type="button"
                    onClick={() => {
                      returnAbonoBatida();
                    }}
                  >
                    Abonar Batida
                  </button>
                )}
                {isAbonoDia && (
                  <button
                    className="buttonAbonar"
                    type="button"
                    onClick={() => {
                      returnAbonoDia();
                    }}
                  >
                    Abonar Dia
                  </button>
                )}
                {isAbonoPeriodo && (
                  <button
                    className="buttonAbonar"
                    type="button"
                    onClick={() => {
                      returnAbonoPeriodo();
                    }}
                  >
                    Abonar Período
                  </button>
                )}
                {isApagarAbono && (
                  <button
                    className="buttonAbonar"
                    type="button"
                    onClick={() => {
                      returnApagarAbono();
                    }}
                  >
                    Apagar Abono
                  </button>
                )}
              </div>
            </div>
          </Aba>
        </ContainerFiltro>
      )}
    </>
  );
};

export const Icon: React.FC<PropsIcons> = ({
  /** whichIcons ira vim com o novo do icons que queremos renderizar.
   */
  whichIcons = '',
}) => {
  return (
    <>
      {whichIcons === 'Check' && (
        <DivIcons>
          <DivIconCheck>
            <FaCheck
              style={{
                color: '#ffffff',
                height: 12,
                width: 12,
              }}
            />
          </DivIconCheck>
        </DivIcons>
      )}
      {whichIcons === 'Close' && (
        <DivIcons>
          <DivIconClose>
            <IoClose
              style={{
                color: '#ffffff',
                height: 17,
                width: 17,
              }}
            />
          </DivIconClose>
        </DivIcons>
      )}
    </>
  );
};
