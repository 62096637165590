import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: Arial;
  justify-content: center;
  input {
    height: 20px;
    border-radius: 10px;
    width: 20px;
  }
  padding-bottom: 5px;
`;
