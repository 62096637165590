import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  // FiMail,
  // FiPhone,
  FiUser,
  FiBriefcase,
  // FiCalendar,
  FiMessageSquare,
  // FiMoon,
  // FiSun,
  // FiMap,
  // FiClock,
} from 'react-icons/fi';

import { FaClipboardList, FaHandshake } from 'react-icons/fa';
// import Axios from 'axios';
import Swal from 'sweetalert2';
// import { maskDateOfBirth } from '../../../components/Input/mask';
// import Input from '../../../components/Input';

import { SelectContainer, ButtonStyle } from './styles';
import { TextArea } from '../../../components/TextAreaInput';
import getValidationErrors from '../../../utils/getValidationErrors';
import { VagaForm, Vagas } from '../../../models/Vaga';
import { Filial } from '../../../models/Filial';
import { Funcao } from '../../../models/Funcao';

// import { states } from '../../../utils/state';
// import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

// interface CidadeResponse {
//   id: number;
//   nome: string;
//   'regiao-imediata': {
//     id: number;
//     nome: string;
//     'regiao-intermediaria': {
//       id: number;
//       nome: string;
//       UF: {
//         id: number;
//         nome: string;
//         sigla: string;
//       };
//     };
//   };
// }

interface CreateFormData {
  beneficios: string;
  // dataAbertura: string;
  // dataFechamento: string;
  descricao: string;
  // email: string;
  // phone: string;
  requisitos: string;
}

interface Props {
  submitForm(dados: VagaForm): void;
  submitCancel?(): void;
  titulo: string;
  edit?: boolean;
  dados?: Vagas;
  dataFilial: Filial[];
}

export const Formulario: React.FC<Props> = ({
  submitForm,
  submitCancel,
  titulo,
  dados,
  dataFilial,
}) => {
  // const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [id, setID] = useState(-1);
  const [funcao, setFuncao] = useState('');
  const [funcoes, setFuncoes] = useState<Funcao[]>([]);
  // const [filiais, setFiliais] = useState<Filial[]>([]);
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [dataAbertura, setDataAbertura] = useState('');
  // const [dataFechamento, setDataFechamento] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [beneficios, setBeneficios] = useState('');
  const [requisitos, setRequisitos] = useState('');
  const [descricao, setDescricao] = useState('');
  // const [cidade, setCidade] = useState('');
  // const [estado, setEstado] = useState('CE');
  // const [numberVagas, setNumberVagas] = useState('');
  // const [periodoJob, setPeriodoJob] = useState('');
  // const [tempoJob, setTempoJob] = useState('');
  // const [vinculo, setVinculo] = useState('');
  // const [cidades, setCidades] = useState<CidadeResponse[]>([]);

  // function stringYMDforDMY(anoMesDia: string): string {
  //   const [ano, mes, dia] = anoMesDia.split('-');
  //   return `${dia}/${mes}/${ano}`;
  // }

  // function stringDMYforYMD(anoMesDia: string): string {
  //   const [dia, mes, ano] = anoMesDia.split('/');
  //   return `${ano}-${mes}-${dia}`;
  // }

  // function stringForDate(text: string): Date {
  //   return new Date(`${text} 12:00`);
  // }

  // function dateisMenor(data1: Date, data2: Date): boolean {
  //   return data1.getTime() < data2.getTime();
  // }

  // async function getCidades(): Promise<void> {
  //   // console.log('Entrou aqui');
  //   try {
  //     const response = await Axios.get(
  //       `https://servicodados.ibge.gov.br/api/v1/localidades/municipios`,
  //     );
  //     // console.log(response.data);
  //     setCidades(response.data);
  //   } catch (e) {
  //     setCidades([
  //       {
  //         id: -1,
  //         nome: 'Nenhuma cidade encontrada',
  //         'regiao-imediata': {
  //           id: -1,
  //           nome: '',
  //           'regiao-intermediaria': {
  //             id: -1,
  //             nome: '',
  //             UF: {
  //               id: -1,
  //               nome: '',
  //               sigla: '',
  //             },
  //           },
  //         },
  //       },
  //     ]);
  //   }
  // }

  const getFuncoes = useCallback(() => {
    api
      .get('/funcoes/all')
      .then((respFuncoes) => {
        const funAll: Funcao[] = respFuncoes.data;
        setFuncoes(funAll);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    getFuncoes();
  }, [getFuncoes]);

  useEffect(() => {
    if (dados) {
      // console.log(dados);
      formRef.current?.setData({
        beneficios: dados.beneficio,
        descricao: dados.descricao,
        requisitos: dados.requisitos,
      });
      setID(dados.id);
      setBeneficios(dados.beneficio);
      // setDataFechamento(stringYMDforDMY(dados.dateFechamento));
      // setDataAbertura(stringYMDforDMY(dados.dateAbertura));
      setDescricao(dados.descricao);
      // setEmail(dados.email);
      setEmpresa(dados.filialId);
      setFuncao(dados.funcaoId);
      // setPhone(dados.telefone);
      setRequisitos(dados.requisitos);
      // setEstado(dados.estado);
      // setCidade(dados.cidade);
      // setPeriodoJob(dados.periodoJob);
      // setTempoJob(dados.tempoJob);
      // setVinculo(dados.vinculo);
    }
  }, [dados]);

  // function CodNameEmpresa(cod: string): string {
  //   switch (cod) {
  //     case '010101':
  //       return 'Fortal Empreendimentos';
  //     case '020101':
  //       return 'Fortal Tercerização';
  //     case '040101':
  //       return 'Ello Serviços';
  //     case '050101':
  //       return 'Missao Serviços';
  //     case '030101':
  //       return 'Real Serviços';
  //     default:
  //       return 'Fortal Empreendimentos';
  //   }
  // }

  const handleSubmitForm = useCallback(
    async (data: CreateFormData) => {
      try {
        // console.log('Data', data);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          beneficios: Yup.string().required('Benefícios é obrigatório!'),
          // dataFechamento: Yup.string().required('Data Final é obrigatório!'),
          // dataAbertura: Yup.string().required('Data Inicial é obrigatório!'),
          descricao: Yup.string().required('Descrição é obrigatório!'),
          // email: Yup.string()
          //   .required('E-mail é obrigatório!')
          //   .email('Digite um E-mail válido!'),
          // phone: Yup.string().required('Telefone é obrigatório!'),
          requisitos: Yup.string().required('Requisitos é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (empresa === '' || empresa === '-1') {
          Swal.fire({
            icon: 'error',
            title: 'Filial não selecionada.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          return;
        }

        if (funcao === '' || funcao === '-1') {
          Swal.fire({
            icon: 'error',
            title: 'Função não selecionada.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          return;
        }

        // const dataAberturaMenorFechamento = dateisMenor(
        //   stringForDate(stringDMYforYMD(data.dataAbertura)),
        //   stringForDate(stringDMYforYMD(data.dataFechamento)),
        // );
        // if (!dataAberturaMenorFechamento) {
        //   Swal.fire({
        //     icon: 'info',
        //     title: 'A data de Abertura é maior que a do Fechamento',
        //     showClass: {
        //       popup: 'animate__animated animate__fadeInDown',
        //     },
        //     hideClass: {
        //       popup: 'animate__animated animate__fadeOutUp',
        //     },
        //   });
        //   return;
        // }

        let obj: VagaForm = {
          beneficio: '',
          descricao: '',
          filialId: '',
          funcaoId: '',
          requisitos: '',
          filialFuncoesId: '',
        };

        if (id === -1) {
          obj = {
            beneficio: data.beneficios,
            descricao: data.descricao,
            filialId: empresa,
            funcaoId: funcao,
            requisitos: data.requisitos,
            filialFuncoesId: empresa,
          };
        } else {
          obj = {
            // beneficios: data.beneficios,
            // descricao: data.descricao,
            // requisitos: data.requisitos,
            // empresa,
            id,
            beneficio: data.beneficios,
            descricao: data.descricao,
            filialId: empresa,
            funcaoId: funcao,
            requisitos: data.requisitos,
            filialFuncoesId: empresa,
            // funcao,
            // vinculo,
            // periodoJob,
            // tempoJob,
            // cidade,
            // estado,
            // ativo: true,
            // empresaNome: CodNameEmpresa(empresa),
            // idFuncionario: Number(user.id),
            // nomeFuncionario: user.nome,
          };
        }
        submitForm(obj);
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [empresa, funcao, id, submitForm],
  );

  const handleCancel = useCallback(() => {
    // console.log('cancel');
    formRef.current?.reset();
    setBeneficios('');
    // setDataAbertura('');
    // setDataFechamento('');
    setDescricao('');
    // setEmail('');
    setEmpresa('');
    setFuncao('');
    // setPhone('');
    setRequisitos('');
    // setEstado('CE');
    // setCidade('');
    // setPeriodoJob('');
    // setTempoJob('');
    // setVinculo('');
    if (submitCancel) {
      submitCancel();
    }
  }, [submitCancel]);

  // const cidadesOptions = useMemo(() => {
  //   return cidades.filter(
  //     (item) =>
  //       item['regiao-imediata']['regiao-intermediaria'].UF.sigla === estado,
  //   );
  // }, [cidades, estado]);
  const funcaoMemo = useMemo(() => {
    return funcoes.filter((item) => item.filialId === empresa);
  }, [empresa, funcoes]);

  return (
    <div style={{ margin: 20 }}>
      <h1>{titulo}</h1>
      <Form ref={formRef} onSubmit={handleSubmitForm}>
        <SelectContainer>
          <FiBriefcase size={20} color="#439133" />
          <select
            value={empresa}
            onChange={(item) => {
              setEmpresa(item.target.value);
              setFuncao('');
            }}
          >
            <option value="-1">Escolher empresa</option>
            {dataFilial.map((item) => (
              <option value={item.importId}>{item.name}</option>
            ))}
          </select>
        </SelectContainer>
        <SelectContainer style={{ marginTop: 8 }}>
          <FiUser size={20} color="#439133" />
          <select
            value={funcao}
            onChange={(item) => setFuncao(item.target.value)}
          >
            <option value="-1">Escolher função</option>
            {funcaoMemo.map((item: Funcao) => (
              <option value={item.importId}>{item.name}</option>
            ))}
          </select>
        </SelectContainer>
        {/* <div style={{ display: 'flex', marginTop: 8 }}>
          <Input
            name="email"
            icon={FiMail}
            placeholder="E-mail"
            mask="email"
            value={email}
            onValue={(text) => {
              setEmail(text);
            }}
          />
          <div style={{ margin: 10 }} />
          <Input
            name="phone"
            icon={FiPhone}
            placeholder="Telofone"
            mask="phone"
            // value={phone}
            onValue={(text) => {
              console.log(text);
              setPhone(text);
            }}
          />
        </div>
        <div style={{ display: 'flex', marginTop: 8 }}>
          <Input
            name="dataAbertura"
            icon={FiCalendar}
            placeholder="Data de Abertura"
            // mask="dateOfBirth"
            value={dataAbertura}
            // onValue={setDataAbertura}
            onChange={(event) => {
              setDataAbertura(maskDateOfBirth(event.target.value));
            }}
          />
          <div style={{ margin: 10 }} />
          <Input
            name="dataFechamento"
            icon={FiCalendar}
            placeholder="Data de Fechamento"
            // mask="dateOfBirth"
            value={dataFechamento}
            onChange={(event) => {
              setDataFechamento(maskDateOfBirth(event.target.value));
            }}
            // onValue={setDataFechamento}
          />
        </div>
        <div style={{ display: 'flex', marginTop: 8, marginBottom: 8 }}>
          <SelectContainer>
            <FiBriefcase size={20} color="#439133" />
            <select
              value={vinculo}
              onChange={(item) => setVinculo(item.target.value)}
            >
              <option value="CLT Efetivo">CLT Efetivo</option>
              <option value="CLT Temporário">CLT Temporário</option>
              <option value="Pessoa Jurídica">Pessoa Jurídica</option>
              <option value="Pessoa Jurídica Temporária">
                Pessoa Jurídica Temporária
              </option>
            </select>
          </SelectContainer>
          <div style={{ margin: 10 }} />
          <SelectContainer>
            {periodoJob === 'Noturno' ? (
              <FiMoon size={20} color="#439133" />
            ) : (
              <FiSun size={20} color="#439133" />
            )}
            <select
              value={periodoJob}
              onChange={(item) => setPeriodoJob(item.target.value)}
            >
              <option value="Diurno">Diurno</option>
              <option value="Matutino">Matutino</option>
              <option value="Noturno">Noturno</option>
              <option value="Integral">Integral</option>
            </select>
          </SelectContainer>
          <div style={{ margin: 10 }} />
          <SelectContainer>
            <FiClock size={20} color="#439133" />
            <select
              value={tempoJob}
              onChange={(item) => setTempoJob(item.target.value)}
            >
              <option value="Integral">Integral</option>
              <option value="Meio Período">Meio Período</option>
            </select>
          </SelectContainer>
        </div>
        <div style={{ display: 'flex', marginTop: 8 }}>
          <SelectContainer>
            <FiMap size={20} color="#439133" />
            <select value={estado} onChange={(e) => setEstado(e.target.value)}>
              {states.map((state) => (
                <option key={state.id} value={state.sigla}>
                  {state.sigla} - {state.nome}
                </option>
              ))}
            </select>
          </SelectContainer>
          <div style={{ margin: 10 }} />
          <SelectContainer>
            <FaCity size={20} color="#439133" />
            <select
              value={cidade}
              onChange={(item) => setCidade(item.target.value)}
            >
              {cidadesOptions.map((city) => (
                <option key={city.id} value={city.nome}>
                  {city.nome}
                </option>
              ))}
            </select>
          </SelectContainer>
          <div style={{ margin: 10 }} />
          <Input
            name="numVagas"
            icon={FiBriefcase}
            placeholder="Número de Vagas"
            // mask="number"
            type="number"
            // value={numberVagas}
            onValue={setNumberVagas}
          />
        </div> */}
        <TextArea
          name="requisitos"
          placeholder="Requisitos"
          value={requisitos}
          onChange={setRequisitos}
          icon={FaClipboardList}
        />
        <TextArea
          name="beneficios"
          placeholder="Benefícios"
          value={beneficios}
          onChange={setBeneficios}
          icon={FaHandshake}
        />
        <TextArea
          name="descricao"
          placeholder="Descrição do Cargo"
          value={descricao}
          onChange={setDescricao}
          icon={FiMessageSquare}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonStyle color="#439133" type="submit">
            CADASTRAR
          </ButtonStyle>
          <ButtonStyle color="#cc0000" type="button" onClick={handleCancel}>
            CANCELAR
          </ButtonStyle>
        </div>
      </Form>
    </div>
  );
};
