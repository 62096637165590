import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import QrCode from 'qrcode';

import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
} from './styles';

interface ResponseGet {
  AVATAR: string;
  Ativo: number;
  CPF: string;
  DataCadastro: string;
  DataNascimento: string;
  EMAIL: string;
  ID: number;
  NOME: string;
  Password: string;
  Telefone: string;
  privarDados: boolean;
  token: string;
  param: string;
}

interface ModalQrCode {
  dados: string;
  dadosGeral?: ResponseGet;
  onClose?(): void;
}

const ModalQrCode: React.FC<ModalQrCode> = ({
  onClose = () => {
    ('');
  },
  dados,
  dadosGeral,
}) => {
  const [linkImage, setLinkImage] = useState('');

  useEffect(() => {
    QrCode.toCanvas(document.getElementById('canvas'), dados);
  }, [dados]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    if (canvas !== null) {
      const image = canvas.toDataURL();
      setLinkImage(image);
    }
  }, []);

  return (
    <>
      <div>
        <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
          <Container>
            <ContainerButton>
              <button
                type="button"
                id="btnFechar"
                onClick={() => {
                  onClose();
                }}
              >
                <FiX />
              </button>
            </ContainerButton>
            <ContainerHeader id="qrcode">
              <canvas id="canvas" />
            </ContainerHeader>
            <ContainerHeaderDados>
              <p className="titulo">{dadosGeral?.NOME}</p>
            </ContainerHeaderDados>
            <ContainerButtonAtualizar>
              <a
                href={linkImage}
                id="btnPrint"
                download={`${dadosGeral?.NOME}.png`}
              >
                Salvar
              </a>
            </ContainerButtonAtualizar>
          </Container>
        </ContainerModal>
      </div>
    </>
  );
};

export default ModalQrCode;
