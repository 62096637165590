import React from 'react';
import { Container, ContainerBarra } from './styles';

interface ProgressBarInterface {
  percentage?: number;
  height?: string;
  color?: string;
}
const ProgressBar: React.FC<ProgressBarInterface> = ({
  percentage = 0,
  height = '20px',
  color = '#006600',
}: ProgressBarInterface) => {
  return (
    <Container height={height}>
      <ContainerBarra porcentagem={percentage} colorBar={color} />
    </Container>
  );
};

export default ProgressBar;
