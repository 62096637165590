import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiCalendar, FiLock, FiMail, FiPhone, FiUser } from 'react-icons/fi';

import Swal from 'sweetalert2';
import { useToast } from '../../hooks/toast';

import AlertEmail from '../../components/AlertEmail';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { maskDateOfBirth, maskTel } from '../../components/Input/mask';
import { Loading } from '../../components/Loading';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  Actions,
  AnimationContainer,
  CancelButton,
  CardUser,
  Container,
  DeleteButton,
  InfoUser,
} from './styles';
import logoImg from '../../assets/logo.png';
import avatar from '../../assets/avatar2.png';

interface SignInFormData {
  email: string;
  password: string;
}

interface User {
  ativo: number;
  avatar_url: string;
  cpf: string;
  dataNascimento: string;
  email: string;
  id: string;
  nome: string;
  telefone: string;
}

interface AuthState {
  token: string;
  user: User;
}

export const DeleteAccount: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState<User | null>(null);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido!'),
          password: Yup.string().required('Senha Obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        api
          .post('sessions', {
            email: data.email,
            password: data.password,
          })
          .then((response) => {
            const { token, user } = response.data as AuthState;
            api.defaults.headers.authorization = `Bearer ${token[1]}`;
            setUserData(user);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description:
                'Ocorreu um erro ao fazer login, cheque as credenciais.',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setLoading(false);
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [addToast],
  );

  const handleCancelDelete = useCallback(() => {
    api.defaults.headers.authorization = '';
    setUserData(null);
  }, []);

  const deleteAccount = useCallback(async (): Promise<void> => {
    try {
      if (userData === null) {
        return;
      }
      setLoading(true);

      await api.post('/profile/deleteMyAccount', {
        userId: userData.id,
        ativo: 0,
      });

      addToast({
        type: 'success',
        title: 'Conta deletada com sucesso!',
      });

      setUserData(null);
    } catch (err) {
      // console.log(`ERRO: ${err}`);
      addToast({
        type: 'error',
        title: 'Erro ao deletar conta',
        description: 'Ocorreu um erro ao apagar a conta.',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, userData]);

  const handleDeleteAccount = useCallback(() => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar a conta?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonColor: '#c53030',
      cancelButtonColor: '#ababab',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAccount();
      }
    });
  }, []);

  return (
    <Container>
      <div>
        {loading && <Loading />}
        <AnimationContainer>
          <img src={logoImg} alt="Logo Servnac" />

          {userData !== null ? (
            <InfoUser>
              <img
                src={userData.avatar_url ? userData.avatar_url : avatar}
                style={{ objectFit: 'cover' }}
                alt="Imagem profile"
              />
              <CardUser>
                <div>
                  <FiUser />
                  <p>{userData.nome}</p>
                </div>
                <div>
                  <FiMail />
                  <p>{userData.email}</p>
                </div>
                <div>
                  <FiCalendar />
                  <p>{maskDateOfBirth(userData.dataNascimento)}</p>
                </div>
                <div>
                  <FiPhone />
                  <p>{maskTel(userData.telefone)}</p>
                </div>
              </CardUser>

              <span>
                Atenção! <br />
                Esta ação é irreversível. Ao deletar a conta, tenha certeza da
                sua confirmação, os dados serão excluídos de imediato.
              </span>
              <Actions>
                <CancelButton onClick={handleCancelDelete} type="button">
                  Cancelar
                </CancelButton>
                <DeleteButton type="button" onClick={handleDeleteAccount}>
                  Deletar conta
                </DeleteButton>
              </Actions>
            </InfoUser>
          ) : (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Deletar conta</h1>

              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <AlertEmail email={email} />
              <Input
                name="password"
                icon={FiLock}
                tipo="password"
                placeholder="Senha"
              />

              <Button type="submit" loading={loading}>
                Entrar
              </Button>
            </Form>
          )}
        </AnimationContainer>
      </div>
    </Container>
  );
};
