import styled from 'styled-components';

interface ContainerProps {
  texto?: boolean;
  cor?: boolean;
  corTexto?: string;
}

export const Container = styled.div`
  /**Tem 100% da parte visível da tela que o usuário consegue ver */
  height: auto;
  /**Faz os items de dentro ficar todos um do lado do outro. */
  flex: 1;
  /**Faz os items de dentro ter o mesmo tamanho, no caso, o tamanho de
    100vh */
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: auto;
`;

export const ContainerSearchSup = styled.div`
  width: 90%;
  display: flex;
  margin: 0px auto;
  input {
    margin-right: 0px;
    width: 200px;
    border-top-right-radius: 5px;
    padding: 0px 5px;
  }
  div {
    border-top-left-radius: 5px;
  }
`;

export const ContainerSearchInf = styled.div`
  width: 90%;
  display: flex;
  margin: 0px auto;
  margin-top: 3px;
  input {
    margin-right: 0px;
    width: 200px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    padding: 0px 5px;
  }
  div {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 5px;
  }
`;

export const Aba = styled.div`
  width: 70%;
  display: flex;
  padding: 0px 20px;
  margin: 0px auto;
  margin-top: 40px;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    background-color: ${(props) => props.corTexto};
    color: #111111;
    border-width: 0px;
    min-width: 80px;
    border: 1px solid #a6a6a6;
    padding: 0px 5px;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerTable = styled.div`
  width: 90%;
  align-items: center;
  margin: 0px auto;
  max-height: 50vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px, 40px;
    margin: 0px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerTableMeses = styled.div`
  align-items: center;
  max-width: 70%;
  min-width: 70%;
  margin: 0px auto;
  margin-bottom: 0px;
  max-height: 45vh;
  overflow-x: auto;
  overflow-y: auto;
  table {
    width: 100%;
    margin: 0px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      padding: 5px 0px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      & + th {
        padding-left: 10px;
      }
    }
  }
  tbody {
    td {
      margin: 10px;
      padding: 10px;
      text-align: center;
      font-size: 16px;
      max-width: 200;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 40px;
        }
      }
      a {
        text-decoration: none;
        size: 25px;
        color: #ffffff;
      }
      textarea {
        border-radius: 5px;
        padding: 10px 10px;
        font-size: 16px;
        font-family: Arial;
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerTableEstatica = styled.div`
  width: 70%;
  align-items: center;
  margin: 0px auto;
  padding: 0px;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px, 10px;
    margin: 0px auto;
    margin-top: 1px;
    border-collapse: collapse;
    border: 1px solid #006600;
    th {
      border-bottom: 1px solid #006600;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: Left;
      font-size: 14px;
    }
  }
`;

export const ContainerSemDados = styled.div`
  width: 90%;
  margin: 0px auto;
  padding: 20px;
  background-color: #3e3946;
  overflow-x: auto;
  h3 {
    text-align: center;
  }
`;
