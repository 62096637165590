import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.7);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

export const Container = styled.div`
  animation: ${appearFromRight} 300ms;
  position: relative;
  width: 500px;
  height: 300px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: transparent;
    padding: 8px;
    margin-left: auto;
  }
`;

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;
  canvas {
    margin: 0px auto;
  }
`;

export const ContainerHeaderDados = styled.div`
  display: flex;
  margin: 0px auto;
  justify-content: center;
  .titulo {
    font-weight: bold;
    margin-top: 30px;
    font-size: 24px;
  }
  p {
    margin-top: 10px;
    font-size: 18px;
  }
`;

export const ContainerButtonAtualizar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;
  a {
    text-align: center;
    text-decoration: none;
    padding: 10px;
    background-color: #006600;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
  }
`;
