import React, { useState, useEffect } from 'react';
import { FiFileText, FiX } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  Container,
  Header,
  Content,
  JSONContainer,
  ImagenDoc,
  Row,
  CName,
  CValue,
  PdfView,
} from './styles';
import { ValidacaoAdmissao } from '../index';
import { apiJsonDoc } from '../../../services/api';

interface ModalDocument {
  admissao: ValidacaoAdmissao;
}

const ModalDocument: React.FC<ModalDocument> = ({ admissao }) => {
  const [jsonData, setJsonData] = useState([]);
  const [hide, setDisplay] = useState(true);

  const labelName: Record<string, string> = {
    data_expedicao: 'Data Expedição',
    data_nascimento: 'Data Nascimento',
    doc_origem: 'Doc. origem',
    nome: 'Nome',
    filiacao_1: 'Filiação1',
    filiacao_2: 'Filiação2',
    cpf: 'CPF',
    rg: 'RG',
    naturalidade_cidade: 'Naturalidade Cidade',
    naturalidade_uf: 'Naturalidade UF',
    cat_hab: 'Categoria Habilitação',
    codigo_seguranca: 'Código de segurança',
    data_emissao: 'Data emissão',
    orgao_emissor: 'Órgão emissor',
    numero_espelho_frente: 'Nº espelho frente',
    numero_espelho_verso: 'Nº espelho verso',
    observacoes: 'Observações',
    data_primeira_habilitacao: 'Data primeira hab',
    registro: 'Registro',
    renach: 'Renach',
    data_validade: 'Data validade',
    local_cidade: 'Local cidade',
    local_uf: 'Local UF',
    estado_emissor: 'Estado emissor',
  };

  const handleName = (name: string): string => {
    return labelName[name] || name;
  };

  const isPDF = (filename = ''): boolean => {
    const pdf = filename.split('.').pop();
    return pdf === 'pdf';
  };

  const link = admissao.LINK_IMAGEM;

  useEffect(() => {
    const linkEncoded = encodeURIComponent(`${link}`);
    const erroMsg = [
      {
        name: 'Erro',
        value: 'Não foi possível capturar informações',
      },
    ];

    apiJsonDoc
      .get(`/${linkEncoded}`)
      .then(({ data }) => {
        let fields;
        if (data?.json) {
          const jsonParse = JSON.parse(data.json)[0];
          fields = jsonParse?.fields;
        }
        setJsonData(fields || erroMsg);
      })
      .catch(() => {
        if (navigator.onLine) {
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      });
  }, [link]);

  if (hide) {
    return (
      <button type="button" onClick={() => setDisplay(!hide)}>
        <FiFileText />
      </button>
    );
  }

  return (
    <Container>
      <Header>
        <span>{admissao.NOME}</span>
        <FiX size="30" onClick={() => setDisplay(!hide)} />
      </Header>
      <Content>
        <JSONContainer>
          {jsonData.map(({ name, value }) => (
            <Row key={name}>
              <CName>{handleName(name)}</CName>
              <CValue>{value}</CValue>
            </Row>
          ))}
        </JSONContainer>
        <ImagenDoc>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {isPDF(link) ? (
              <PdfView title={link} src={link} />
            ) : (
              <img src={link} alt="imagem" />
            )}
          </a>
        </ImagenDoc>
      </Content>
    </Container>
  );
};

export default ModalDocument;
