import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FiCheckCircle, FiXCircle, FiSave, FiFileText } from 'react-icons/fi';
import { RiFileExcel2Line } from 'react-icons/ri';
import { BsQuestionCircleFill } from 'react-icons/bs';
// import { GoFilePdf } from 'react-icons/go';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { ptBR } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import DrawerApp from '../../components/Drawer';
import api from '../../services/api';
import Search from '../../components/Search';
import DropDown from '../../components/DropDown';
import HeaderTable from '../../components/HeaderTable';
import { maskCpf } from '../../components/Input/mask';
import ModalConexao from '../../components/ModalConexao';
import TableAusencia from './Export/ausenciaExcel';
import Tooltip from '../../components/Tooltip/index';
import {
  formatarDataAnoMesDia,
  formatarData,
  formatarMaxData,
  formatarDataAno,
} from '../../components/Formulas/formulas';

import {
  Container,
  ContainerSupSearch,
  ContainerSearch,
  ContainerTable,
  ContainerExport,
  ButtonBorder,
  ButtonBorderIconTrue,
  ButtonBorderIconFalse,
  Aba,
  ContainerAba,
  ContainerVisualizarVazio,
} from './styles';

registerLocale('pt-BR', ptBR);

interface AusenciaCiente {
  CIENTE_OP: boolean;
  CPF: string;
  DATA_ATUALIZACAO: string;
  DATA_CIENTE_OP: string;
  DATA_PROGRAMADA: string;
  DESC_MOTIVO: string;
  DESC_MOTIVO_OP: string;
  ID: number;
  Id_MotivosAusencia: number;
  Id_UsersAppColaborador: number;
  NOME: string;
  NOME_USER_CIENTE_OP: string;
  LINK: string;
  FILIAL: string;
}

const setores = [
  { valor: 'Operacional', id: 2, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const Ausencia: React.FC = () => {
  const { userAcesso, user } = useAuth();
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [vencido, setVencido] = useState(false);
  const [todos, setTodos] = useState(true);
  const [ciente, setCiente] = useState(false);
  const [response, setResponse] = useState<AusenciaCiente[]>([]);
  const [copiaResponse, setCopiaResponse] = useState<AusenciaCiente[]>([]);
  const [loading, setLoading] = useState(false);
  const [dadoIcon, setDadoIcon] = useState(false);
  const [IdAusencia, setIdAusencia] = useState<number>(0);
  const [filial, setFilial] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [dataInicialFilter, setDataInicialFilter] = useState('');
  const [dataFinalFilter, setDataFinalFilter] = useState('');

  const vencidos = 'Data da ausência é anterior a data de Hoje.';
  const naoVencidos = 'Data da ausência é Igual ou Posterior a data de Hoje.';
  const todosAlert = 'Todas as requisicões de falta. Vencidos ou não.';

  const [refresh, setRefresh] = useState(false);

  const headers = [
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Data Ausência', field: 'data' },
    { name: 'Motivo Ausência', field: 'motAusencia' },
    { name: 'Descrição do Motivo', field: 'descricao' },
    { name: 'Código Motivo', field: 'codigo' },
    { name: 'Arquivo', field: 'arquivo' },
    { name: 'Validar', field: 'validar' },
    { name: 'Motivo', field: 'motivo' },
  ];
  const motivoAusencia = [
    { name: 'Atestado', id: 1 },
    { name: 'Consulta', id: 2 },
    { name: 'Indisposição', id: 3 },
    { name: 'Desistência', id: 4 },
    { name: 'Licenças', id: 6 },
    { name: 'Falta', id: 7 },
    { name: 'Outros', id: 5 },
  ];

  const dropDownFilial = [
    { valor: 'Nenhum', id: 0, codigo: '000000' },
    { valor: '01 - Servnac Segurança', id: 1, codigo: '010101' },
    { valor: '02 - Sol. Corporativas', id: 2, codigo: '020101' },
    { valor: '03 - Sol. Empresarias', id: 3, codigo: '030101' },
    { valor: '04 - Facilities', id: 4, codigo: '040101' },
    { valor: '05 - TOK Segurança', id: 5, codigo: '050101' },
    { valor: '06 - OAK SVNK Tecnologia', id: 6, codigo: '999999' },
  ];

  const transfomaNomeToCodigo = useCallback(
    (value: string): string => {
      const data = dropDownFilial.filter((r) => r.valor === value);
      if (data.length > 0) {
        return data[0].codigo;
      }
      return '';
    },
    [dropDownFilial],
  );

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((u) => {
      access = setores.filter(
        (setor) => setor.id === u.SetorID && u.NivelACesso >= setor.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`/programacaoAusencia/showAusencia`)
        .then((data) => {
          setResponse(data.data);
          // console.log(data.data);
          setCopiaResponse(JSON.parse(JSON.stringify(data.data)));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }
  }, [history, refresh, userAcesso]);

  useMemo(() => {
    response.forEach((data: AusenciaCiente) => {
      if (data.ID === IdAusencia) {
        // eslint-disable-next-line no-param-reassign
        data.CIENTE_OP = dadoIcon;
      }
      return data;
    });
    setResponse(response);
  }, [response, IdAusencia, dadoIcon]);

  const handlePreencherESalvarDados = useCallback(async () => {
    const arrayIdAusencia: number[] = [];
    const arrayIdUserAusencia: number[] = [];
    const arrayAusenciaCiente: boolean[] = [];
    const arrayMotivo: string[] = [];
    let motivoVazio = false;
    response.forEach((data: AusenciaCiente) => {
      copiaResponse.forEach((dados) => {
        if (
          data.CIENTE_OP !== null &&
          (data.CIENTE_OP !== dados.CIENTE_OP ||
            data.DESC_MOTIVO_OP !== dados.DESC_MOTIVO_OP) &&
          data.ID === dados.ID &&
          data.CPF === dados.CPF
        ) {
          if (data.CIENTE_OP !== null) {
            arrayIdAusencia.push(data.ID);
            arrayIdUserAusencia.push(+data.Id_UsersAppColaborador);
            arrayAusenciaCiente.push(data.CIENTE_OP);
            if (data.DESC_MOTIVO_OP === '' || data.DESC_MOTIVO_OP === null) {
              motivoVazio = true;
            } else {
              arrayMotivo.push(data.DESC_MOTIVO_OP);
            }
          }
        }
      });
    });
    if (motivoVazio) {
      Swal.fire({
        icon: 'error',
        title: 'Preencha o Motivo!',
        showConfirmButton: false,
        timer: 1500,
      });
      motivoVazio = false;
      return;
    }
    if (arrayAusenciaCiente.length <= 0) {
      Swal.fire({
        icon: 'info',
        title: 'Sem dados a salvar',
        showConfirmButton: false,
        timer: 1500,
      });
      motivoVazio = false;
      return;
    }
    try {
      setLoading(true);
      await api
        .post('programacaoAusencia/cienteAusencia', {
          Id_UsersAppColaborador: arrayIdUserAusencia,
          ID: arrayIdAusencia,
          CIENTE_OP: arrayAusenciaCiente,
          NOME_USER_CIENTE_OP: user.nome,
          USER_CIENTE_OP: user.id,
          DESC_MOTIVO_OP: arrayMotivo,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Registro salvo com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setRefresh(!refresh);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao salvar Registro.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro, ao salvar o Registro. Tente Novamente.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
    setResponse(response);
  }, [response, copiaResponse, refresh, user]);

  const responseData = useMemo(() => {
    let computedResponses: AusenciaCiente[] = [];
    computedResponses = response;
    const dateHoje = new Date();
    const codigoEmpresa = transfomaNomeToCodigo(filial);
    if (codigoEmpresa !== '000000' && codigoEmpresa !== '') {
      computedResponses = computedResponses.filter(
        (res: AusenciaCiente) =>
          res.FILIAL !== null && res.FILIAL.includes(codigoEmpresa),
      );
    }
    if (dataInicialFilter && dataFinalFilter) {
      const dateInitFilter = new Date(dataInicialFilter).getTime();
      const dataFimFilter = new Date(dataFinalFilter).getTime();
      computedResponses = computedResponses.filter((res: AusenciaCiente) => {
        const dateResDataProgramada = new Date(res.DATA_PROGRAMADA).getTime();
        return (
          dateResDataProgramada >= dateInitFilter &&
          dateResDataProgramada <= dataFimFilter
        );
      });
    }

    if (ciente) {
      computedResponses = computedResponses.filter(
        (res) => res.CIENTE_OP === true || res.CIENTE_OP === false,
      );
    }
    if (!ciente) {
      computedResponses = computedResponses.filter(
        (res) => res.CIENTE_OP === null,
      );
    }
    if (vencido) {
      computedResponses = computedResponses.filter((res) => {
        if (
          dateHoje.getFullYear() >
            new Date(res.DATA_PROGRAMADA).getFullYear() ||
          dateHoje.getMonth() > new Date(res.DATA_PROGRAMADA).getMonth() ||
          (dateHoje.getFullYear() ===
            new Date(res.DATA_PROGRAMADA).getFullYear() &&
            dateHoje.getMonth() === new Date(res.DATA_PROGRAMADA).getMonth() &&
            dateHoje.getDate() > new Date(res.DATA_PROGRAMADA).getDate() + 1)
        ) {
          return res;
        }
        return null;
      });
    }
    if (!(vencido || todos)) {
      computedResponses = computedResponses.filter((res) => {
        if (
          dateHoje.getFullYear() <=
            new Date(res.DATA_PROGRAMADA).getFullYear() &&
          dateHoje.getMonth() <= new Date(res.DATA_PROGRAMADA).getMonth() &&
          dateHoje.getDate() <= new Date(res.DATA_PROGRAMADA).getDate() + 1
        ) {
          return res;
        }
        return null;
      });
    }
    if (search) {
      computedResponses = computedResponses.filter((res: AusenciaCiente) =>
        res.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: AusenciaCiente) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    return computedResponses;
  }, [
    response,
    transfomaNomeToCodigo,
    filial,
    dataInicialFilter,
    dataFinalFilter,
    ciente,
    vencido,
    todos,
    search,
    searchCPF,
  ]);

  const handlePreencherMotivo = useCallback(
    (dado: string, dadosCadatrais: AusenciaCiente) => {
      // eslint-disable-next-line no-param-reassign
      dadosCadatrais.DESC_MOTIVO_OP = dado;
    },
    [],
  );

  function motivoAusenciaNumberToString(id: number): string {
    const data = motivoAusencia.filter((dados) => id === dados.id);
    return data[0].name;
  }

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      <Container>
        <ContainerSupSearch>
          <DropDown
            onChangeItems={(value: string) => {
              setFilial(value);
            }}
            objetoEnum={dropDownFilial}
          />
          <div className="searchDate">
            <DatePicker
              locale="pt-BR"
              selected={dataInicial}
              onChange={(date) => {
                setDataInicialFilter(formatarData(date?.toLocaleString()));
                setDataInicial(date);
                setMaxDate(formatarMaxData(date?.toLocaleString()));
              }}
              selectsStart
              startDate={dataInicial}
              endDate={dataFinal}
              dateFormat="P"
              placeholderText="Data Inicial"
            />
          </div>
          <div className="searchDateFim">
            <DatePicker
              locale="pt-BR"
              selected={dataFinal}
              onChange={(date) => {
                setDataFinalFilter(formatarData(date?.toLocaleString()));
                setDataFinal(date);
              }}
              selectsEnd
              startDate={dataInicial}
              endDate={dataFinal}
              minDate={dataInicial}
              maxDate={new Date(maxDate)}
              dateFormat="P"
              placeholderText="Data Final"
            />
          </div>
        </ContainerSupSearch>
        <ContainerSearch>
          <div className="searchNome">
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder="Buscar Nome"
            />
          </div>
          <div className="searchCpf">
            <Search
              onSearch={(value: string) => {
                setSearchCPF(value);
              }}
              nomePlaceHolder="Buscar CPF"
            />
          </div>
          <Aba>
            <ContainerAba className="buttonVencido" cor={vencido}>
              <button
                style={{ borderTopLeftRadius: 7 }}
                type="button"
                onClick={() => {
                  setVencido(true);
                  setTodos(false);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${vencidos}`}
                      className="Branco"
                      tamanho={400}
                      marginTopComponent={-17}
                      marginRightComponent={5}
                    >
                      <BsQuestionCircleFill
                        className="iconVencidos"
                        size={17}
                        style={{ marginLeft: -8 }}
                      />
                    </Tooltip>
                    <p
                      style={{
                        width: 75,
                        paddingTop: 7,
                      }}
                    >
                      Vencido
                    </p>
                  </div>
                </div>
              </button>
            </ContainerAba>
            <ContainerAba className="buttonVencido" cor={!(vencido || todos)}>
              <button
                type="button"
                onClick={() => {
                  setVencido(false);
                  setTodos(false);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${naoVencidos}`}
                      className="Branco"
                      tamanho={400}
                      marginTopComponent={-17}
                      marginRightComponent={5}
                    >
                      <BsQuestionCircleFill
                        className="iconVencidos"
                        size={17}
                        style={{ marginLeft: -8 }}
                      />
                    </Tooltip>
                    <p
                      style={{
                        width: 110,
                        paddingTop: 7,
                      }}
                    >
                      Não Vencido
                    </p>
                  </div>
                </div>
              </button>
            </ContainerAba>

            <ContainerAba className="buttonAba" cor={todos}>
              <button
                style={{ borderTopRightRadius: 7 }}
                type="button"
                onClick={() => {
                  setTodos(true);
                  setVencido(false);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${todosAlert}`}
                      className="Branco"
                      tamanho={400}
                      marginTopComponent={-17}
                      marginRightComponent={5}
                    >
                      <BsQuestionCircleFill
                        className="iconVencidos"
                        size={17}
                        style={{ marginLeft: -8 }}
                      />
                    </Tooltip>
                    <p
                      style={{
                        width: 75,
                        paddingTop: 7,
                      }}
                    >
                      Todos
                    </p>
                  </div>
                </div>
              </button>
            </ContainerAba>

            <ContainerAba
              style={{ marginRight: 0 }}
              className="buttonAba"
              cor={ciente}
            >
              <button
                style={{ borderTopLeftRadius: 7, marginRight: 0 }}
                type="button"
                onClick={() => setCiente(true)}
              >
                Ciente
              </button>
            </ContainerAba>
            <ContainerAba cor={!ciente}>
              <button
                style={{ borderTopRightRadius: 7, marginLeft: 0 }}
                type="button"
                onClick={() => setCiente(false)}
              >
                Pendente
              </button>
            </ContainerAba>
          </Aba>
        </ContainerSearch>
        <ContainerTable>
          <div style={{ display: 'none' }}>
            <TableAusencia dados={responseData} />
          </div>
          <table id="cientes">
            <HeaderTable headers={headers} />
            <tbody>
              {responseData.map((res: AusenciaCiente) => (
                <tr key={res.CPF + res.ID}>
                  <td style={{ minWidth: 200 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>{maskCpf(res.CPF)}</td>
                  <td>{formatarDataAnoMesDia(res.DATA_PROGRAMADA)}</td>
                  <td>
                    {motivoAusenciaNumberToString(res.Id_MotivosAusencia)}
                  </td>
                  <td style={{ minWidth: 350 }}>
                    {res.DESC_MOTIVO === '' ? '-' : res.DESC_MOTIVO}
                  </td>
                  <td>{res.ID}</td>
                  <td style={{ width: 30 }}>
                    {res.LINK === null ? (
                      '-'
                    ) : (
                      <a
                        href={res.LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiFileText />
                      </a>
                    )}
                  </td>
                  <td style={{ minWidth: 90 }}>
                    <button
                      type="button"
                      onClick={() => {
                        setDadoIcon(false);
                        setIdAusencia(res.ID);
                      }}
                    >
                      {(res.CIENTE_OP === null || res.CIENTE_OP === true) && (
                        <FiXCircle
                          style={{
                            color: '#737373',
                            marginTop: -1,
                            marginLeft: -1,
                          }}
                          size={25}
                        />
                      )}
                      {!(res.CIENTE_OP === null || res.CIENTE_OP === true) && (
                        <ButtonBorderIconFalse>
                          <FiXCircle style={{ color: '#f2f2f2' }} size={25} />
                        </ButtonBorderIconFalse>
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setDadoIcon(true);
                        setIdAusencia(res.ID);
                      }}
                    >
                      {res.CIENTE_OP && (
                        <ButtonBorderIconTrue>
                          <FiCheckCircle
                            style={{ color: '#f2f2f2' }}
                            size={25}
                          />
                        </ButtonBorderIconTrue>
                      )}
                      {!res.CIENTE_OP && (
                        <FiCheckCircle
                          style={{
                            color: '#737373',
                            marginTop: -1,
                            marginLeft: -1,
                          }}
                          size={25}
                        />
                      )}
                    </button>
                  </td>
                  <td style={{ minWidth: 100 }}>
                    <textarea
                      rows={3}
                      maxLength={255}
                      style={{ resize: 'none', minWidth: 100 }}
                      defaultValue={res.DESC_MOTIVO_OP}
                      onBlur={(e) => handlePreencherMotivo(e.target.value, res)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContainerTable>
        {!(responseData.length > 0) && (
          <ContainerVisualizarVazio>
            {ciente ? (
              <h3>Não existe Registro de Ausência Ciente.</h3>
            ) : (
              <h3>Não existe Registro de Ausência Pendente.</h3>
            )}
          </ContainerVisualizarVazio>
        )}
        {responseData.length > 0 && (
          <div
            style={{
              display: 'flex',
              width: '90%',
              margin: '0px auto',
              marginTop: 20,
            }}
          >
            <ContainerExport>
              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="AusenciaExcel"
                filename="Ausencias"
                sheet="tablexls"
                buttonText={<RiFileExcel2Line />}
              />
              {/* <button
                className="buttonPdf"
                type="button"
                onClick={() => {
                  console.log('pdf');
                }}
              >
                <GoFilePdf />
              </button> */}
            </ContainerExport>
            <ButtonBorder>
              <button
                type="button"
                onClick={() => {
                  handlePreencherESalvarDados();
                }}
              >
                <FiSave />
                Salvar
              </button>
            </ButtonBorder>
          </div>
        )}
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default Ausencia;
