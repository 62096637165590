import React, { useState, useCallback, useEffect } from 'react';
import Swal from 'sweetalert2';
import { FiCheckSquare, FiFileText, FiSquare } from 'react-icons/fi';
import api from '../../../services/api';
import { Loading } from '../../../components/Loading';
import { ResponseGet } from '../Interface/index';
import { formatarDataAnoMesDia } from '../../../components/Formulas/formulas';
import HeaderTable from '../../../components/HeaderTable/index';
import DropDown from '../../../components/DropDown';
import { useAuth } from '../../../hooks/auth';

import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeaderDados,
  ContainerDados,
  ContainerDadosCheck,
  ContainerButtonCancelar,
  ContainerButtonSalvar,
  ContainerTable,
  ContainerFormulario,
  ContainerInformation,
} from './styles';

interface ArrayIdsInterface {
  cpf: string;
  idColaborador: number;
  idPonto: number;
  abono: boolean;
  dataAbono: string;
  entrada: boolean;
  pausa: boolean;
  volta: boolean;
  saida: boolean;
  idMotivoEntrada: number | null;
  idMotivoPausa: number | null;
  idMotivoVolta: number | null;
  idMotivoSaida: number | null;
  idMotivoDia: number | null;
  idMotivoPeriodo: number | null;
  diaAbono: boolean;
  periodoAbono: boolean;
}

interface DropdownInterface {
  id: number;
  valor: string;
}

interface ModalArquivo {
  onClose(): void;
  onSave?(): void;
  dados: ResponseGet[];
  arrayId: number[];
  abono: DropdownInterface[];
}

const ModalAbonoPeriodo: React.FC<ModalArquivo> = ({
  dados,
  arrayId,
  abono,
  onClose = () => {
    ('');
  },
  onSave = () => {
    ('');
  },
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [refreshIconTable, setRefreshIconTable] = useState(false);
  const [checkAbonar, setCheckAbonar] = useState(false);

  const [arrayIdBatida] = useState<ArrayIdsInterface[]>([]);

  const [arrayIdPonto] = useState<number[]>([]);
  const [arrayDataAbono] = useState<string[]>([]);
  const [arrayDataFalta] = useState<string[]>([]);
  const [arrayAbono] = useState<boolean[]>([]);

  const [arrayEntradaBatida] = useState<boolean[]>([]);
  const [arrayPausaBatida] = useState<boolean[]>([]);
  const [arrayVoltaBatida] = useState<boolean[]>([]);
  const [arraySaidaBatida] = useState<boolean[]>([]);
  const [arrayDiaBatida] = useState<boolean[]>([]);
  const [arrayPeriodoBatida] = useState<boolean[]>([]);

  const [arrayIdMotivoEntrada] = useState<(number | null)[]>([]);
  const [arrayIdMotivoPausa] = useState<(number | null)[]>([]);
  const [arrayIdMotivoVolta] = useState<(number | null)[]>([]);
  const [arrayIdMotivoSaida] = useState<(number | null)[]>([]);
  const [arrayIdMotivoDia] = useState<(number | null)[]>([]);
  const [arrayIdMotivoPeriodo] = useState<(number | null)[]>([]);
  const [arrayIdMotivoFalta] = useState<(number | null)[]>([]);

  const [arrayLocalizacao] = useState<boolean[]>([]);

  const headersEscala = [
    { name: 'Data Escala', field: 'dataEscala' },
    { name: 'Latitude Ponto', field: 'latitudePonto' },
    { name: 'Longitude Ponto', field: 'longitudePonto' },
    { name: 'Tipo Ponto', field: 'tipoPonto' },
    { name: 'Data', field: 'data' },
    { name: 'Hora', field: 'hora' },
    { name: 'Foto', field: 'foto' },
    { name: 'Latitude', field: 'latitude' },
    { name: 'Longitude', field: 'longitude' },
    { name: 'Diferença', field: 'diferenca' },
  ];

  useEffect(() => {
    dados.forEach((d) => {
      if (arrayId.includes(d.Id_PontoEletronico)) {
        arrayIdBatida.push({
          cpf: d.CPFSEMFORMATO,
          idColaborador: d.Id_UsersAppColaborador,
          idPonto: d.Id_PontoEletronico,
          abono: true,
          dataAbono: d.DATA_INICIAL_DIA_AGENDA,
          entrada: false,
          pausa: false,
          volta: false,
          saida: false,
          idMotivoEntrada: null,
          idMotivoPausa: null,
          idMotivoVolta: null,
          idMotivoSaida: null,
          idMotivoDia: null,
          idMotivoPeriodo: null,
          diaAbono: false,
          periodoAbono: true,
        });
      }
    });
  }, [arrayId, arrayIdBatida, dados]);

  const handleSalvarAbono = useCallback(
    async (temFalta: boolean, temAbonoBatida: boolean) => {
      try {
        setLoading(true);

        if (temFalta) {
          await api
            .post('/pontoEletronico/insertFaltas/', {
              cpf: arrayIdBatida[0].cpf,
              dataAbono: arrayDataFalta,
              Id_Dom_MotivosAbono_FALTA: arrayIdMotivoFalta,
            })
            .then(() => {
              setLoading(false);
              if (!temAbonoBatida) {
                Swal.fire({
                  icon: 'success',
                  title: 'Abono salvo com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              onSave();
            })
            .catch(() => {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Erro, ao salvar o abono.',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            });
        }
        if (temAbonoBatida) {
          await api
            .post('/pontoEletronico/abonoFaltas/', {
              id_UsersAppColaborador: arrayIdBatida[0].idColaborador,
              dataAbono: arrayDataAbono,
              abono: arrayAbono,
              Id_UsersAppColaborador_Abono_Falta: user.id,
              id_pontoEletronico: arrayIdPonto,
              entrada_abonado: arrayEntradaBatida,
              pausa_abonado: arrayPausaBatida,
              volta_abonado: arrayVoltaBatida,
              saida_abonado: arraySaidaBatida,
              dia_abonado: arrayDiaBatida,
              periodo_abonado: arrayPeriodoBatida,
              localizacao_abonado: arrayLocalizacao,
              Id_Dom_MotivosAbono_ENTRADA: arrayIdMotivoEntrada,
              Id_Dom_MotivosAbono_PAUSA: arrayIdMotivoPausa,
              Id_Dom_MotivosAbono_VOLTA: arrayIdMotivoVolta,
              Id_Dom_MotivosAbono_SAIDA: arrayIdMotivoSaida,
              Id_Dom_MotivosAbono_DIA_ABONADO: arrayIdMotivoDia,
              Id_Dom_MotivosAbono_PERIODO_ABONADO: arrayIdMotivoPeriodo,
            })
            .then(() => {
              setLoading(false);
              Swal.fire({
                icon: 'success',
                title: 'Abono salvo com sucesso!',
                showConfirmButton: false,
                timer: 1500,
              });
              onSave();
            })
            .catch(() => {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Erro, ao salvar o abono.',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            });
        }
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: 'info',
          title: 'Erro ao carregar dados, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [
      arrayAbono,
      arrayDataAbono,
      arrayDataFalta,
      arrayDiaBatida,
      arrayEntradaBatida,
      arrayIdBatida,
      arrayIdMotivoDia,
      arrayIdMotivoEntrada,
      arrayIdMotivoFalta,
      arrayIdMotivoPausa,
      arrayIdMotivoPeriodo,
      arrayIdMotivoSaida,
      arrayIdMotivoVolta,
      arrayIdPonto,
      arrayLocalizacao,
      arrayPausaBatida,
      arrayPeriodoBatida,
      arraySaidaBatida,
      arrayVoltaBatida,
      onSave,
      user.id,
    ],
  );

  const handleAlert = useCallback(() => {
    Swal.fire({
      icon: 'info',
      title: 'Motivo é obrigatório!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }, []);

  const verificaIdArray = useCallback(
    (id: number): boolean => {
      if (arrayId.includes(id)) {
        return true;
      }
      return false;
    },
    [arrayId],
  );

  const transfomaNomeToId = useCallback(
    (value: string): number => {
      const data = abono.filter((r) => r.valor === value);
      if (data.length > 0) {
        return data[0].id;
      }
      return -1;
    },
    [abono],
  );

  const addMotivo = useCallback(
    (value: string) => {
      const id = transfomaNomeToId(value);
      arrayIdBatida.forEach((res) => {
        res.idMotivoPeriodo = id;
      });
      setRefreshIconTable(!refreshIconTable);
    },
    [arrayIdBatida, refreshIconTable, transfomaNomeToId],
  );

  const preecherArrays = useCallback(() => {
    let temFalta = false;
    let temAbonoBatida = false;
    arrayIdBatida.forEach((res) => {
      if (res.idPonto < 0) {
        arrayDataFalta.push(res.dataAbono);
        arrayIdMotivoFalta.push(res.idMotivoPeriodo);
        temFalta = true;
      } else if (res.idPonto > 0) {
        arrayIdPonto.push(res.idPonto);
        arrayDataAbono.push(res.dataAbono);
        arrayAbono.push(res.abono);
        arrayIdMotivoEntrada.push(res.idMotivoEntrada); // null
        arrayIdMotivoPausa.push(res.idMotivoPausa); // null
        arrayIdMotivoVolta.push(res.idMotivoVolta); // null
        arrayIdMotivoSaida.push(res.idMotivoSaida); // null
        arrayIdMotivoDia.push(res.idMotivoDia);
        arrayIdMotivoPeriodo.push(res.idMotivoPeriodo); // null
        arrayEntradaBatida.push(res.entrada);
        arrayPausaBatida.push(res.pausa);
        arrayVoltaBatida.push(res.volta);
        arraySaidaBatida.push(res.saida);
        arrayDiaBatida.push(res.diaAbono);
        arrayPeriodoBatida.push(res.periodoAbono);
        arrayLocalizacao.push(false);
        temAbonoBatida = true;
      }
    });
    handleSalvarAbono(temFalta, temAbonoBatida);
  }, [
    arrayAbono,
    arrayDataAbono,
    arrayDataFalta,
    arrayDiaBatida,
    arrayEntradaBatida,
    arrayIdBatida,
    arrayIdMotivoDia,
    arrayIdMotivoEntrada,
    arrayIdMotivoFalta,
    arrayIdMotivoPausa,
    arrayIdMotivoPeriodo,
    arrayIdMotivoSaida,
    arrayIdMotivoVolta,
    arrayIdPonto,
    arrayLocalizacao,
    arrayPausaBatida,
    arrayPeriodoBatida,
    arraySaidaBatida,
    arrayVoltaBatida,
    handleSalvarAbono,
  ]);

  const verificaMotivoPreenchidos = useCallback((): boolean => {
    let result = false;
    arrayIdBatida.forEach((res) => {
      if (
        res.idMotivoEntrada === null &&
        res.idMotivoPausa === null &&
        res.idMotivoVolta === null &&
        res.idMotivoSaida === null &&
        res.idMotivoDia === null &&
        (res.entrada || res.pausa || res.volta || res.saida || res.diaAbono)
      ) {
        result = true;
      }
    });
    return result;
  }, [arrayIdBatida]);

  return (
    <>
      <div>
        <ContainerModal id="modal">
          <Container>
            <ContainerButton>
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                X
              </button>
            </ContainerButton>
            <ContainerHeaderDados>
              <p>{`Abonar batidas do(a) ${dados[0].NOME}.`}</p>
            </ContainerHeaderDados>
            <ContainerDados>
              {arrayId.length > 0 && (
                <ContainerTable>
                  <table id="EscalaEsp">
                    <HeaderTable headers={headersEscala} />
                    <tbody>
                      {dados.map(
                        (res: ResponseGet, index) =>
                          verificaIdArray(res.Id_PontoEletronico) && (
                            <tr key={res.CPFSEMFORMATO + index.toString()}>
                              <td style={{ minWidth: 100 }}>
                                {formatarDataAnoMesDia(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                )}
                              </td>
                              <td>{res.LOCAL_LATITUDE}</td>
                              <td>{res.LOCAL_LONGITUDE}</td>
                              <td>
                                <p>Entrada</p>
                                <p>Pausa</p>
                                <p>Volta</p>
                                <p>Saída</p>
                              </td>
                              <td style={{ minWidth: 100 }}>
                                <div>
                                  <p>
                                    {res.PONTO_DATA_ENTRADA
                                      ? formatarDataAnoMesDia(
                                          res.PONTO_DATA_ENTRADA,
                                        )
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_DATA_PAUSA
                                      ? formatarDataAnoMesDia(
                                          res.PONTO_DATA_PAUSA,
                                        )
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_DATA_VOLTA
                                      ? formatarDataAnoMesDia(
                                          res.PONTO_DATA_VOLTA,
                                        )
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_DATA_SAIDA
                                      ? formatarDataAnoMesDia(
                                          res.PONTO_DATA_SAIDA,
                                        )
                                      : '-'}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>
                                    {res.PONTO_HORA_ENTRADA
                                      ? res.PONTO_HORA_ENTRADA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_HORA_PAUSA
                                      ? res.PONTO_HORA_PAUSA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_HORA_VOLTA
                                      ? res.PONTO_HORA_VOLTA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_HORA_SAIDA
                                      ? res.PONTO_HORA_SAIDA
                                      : '-'}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>
                                    <a
                                      href={res.PONTO_LINK_FOTO_ENTRADA}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FiFileText />
                                    </a>
                                  </p>
                                  <p>
                                    <a
                                      href={res.PONTO_LINK_FOTO_PAUSA}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FiFileText />
                                    </a>
                                  </p>
                                  <p>
                                    <a
                                      href={res.PONTO_LINK_FOTO_VOLTA}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FiFileText />
                                    </a>
                                  </p>
                                  <p>
                                    <a
                                      href={res.PONTO_LINK_FOTO_SAIDA}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FiFileText />
                                    </a>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>
                                    {res.PONTO_LATITUDE_ENTRADA
                                      ? res.PONTO_LATITUDE_ENTRADA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_LATITUDE_PAUSA
                                      ? res.PONTO_LATITUDE_PAUSA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_LATITUDE_VOLTA
                                      ? res.PONTO_LATITUDE_VOLTA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_LATITUDE_SAIDA
                                      ? res.PONTO_LATITUDE_SAIDA
                                      : '-'}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>
                                    {res.PONTO_LONGITUDE_ENTRADA
                                      ? res.PONTO_LONGITUDE_ENTRADA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_LONGITUDE_PAUSA
                                      ? res.PONTO_LONGITUDE_PAUSA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_LONGITUDE_VOLTA
                                      ? res.PONTO_LONGITUDE_VOLTA
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_LONGITUDE_SAIDA
                                      ? res.PONTO_LONGITUDE_SAIDA
                                      : '-'}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>
                                    {res.PONTO_DIFERENCA_METROS_ENTRADA
                                      ? `${res.PONTO_DIFERENCA_METROS_ENTRADA} m`
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_DIFERENCA_METROS_PAUSA
                                      ? `${res.PONTO_DIFERENCA_METROS_PAUSA} m`
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_DIFERENCA_METROS_VOLTA
                                      ? `${res.PONTO_DIFERENCA_METROS_VOLTA} m`
                                      : '-'}
                                  </p>
                                  <p>
                                    {res.PONTO_DIFERENCA_METROS_SAIDA
                                      ? `${res.PONTO_DIFERENCA_METROS_SAIDA} m`
                                      : '-'}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ),
                      )}
                    </tbody>
                  </table>
                </ContainerTable>
              )}
              {!(arrayId.length > 0) && (
                <ContainerInformation>
                  <p>Não tem dados para abonar.</p>
                </ContainerInformation>
              )}
            </ContainerDados>
            {arrayId.length > 0 && (
              <ContainerDadosCheck>
                {(refreshIconTable || !refreshIconTable) && (
                  <div>
                    <ContainerFormulario cor={checkAbonar}>
                      <p>Abonar:</p>
                      <button
                        className="buttonOptions"
                        type="button"
                        onClick={() => {
                          setCheckAbonar(!checkAbonar);
                        }}
                      >
                        {checkAbonar && <FiCheckSquare />}
                        {!checkAbonar && <FiSquare />}
                      </button>
                    </ContainerFormulario>
                  </div>
                )}
                {(refreshIconTable || !refreshIconTable) && (
                  <ContainerFormulario cor={checkAbonar}>
                    {checkAbonar && (
                      <DropDown
                        onChangeItems={(value: string) => {
                          addMotivo(value);
                        }}
                        objetoEnum={abono}
                      />
                    )}
                    {!checkAbonar && <p> </p>}
                  </ContainerFormulario>
                )}
              </ContainerDadosCheck>
            )}
            <ContainerButtonCancelar>
              <button type="button" onClick={() => onClose()}>
                Cancelar
              </button>
            </ContainerButtonCancelar>
            <ContainerButtonSalvar>
              <button
                type="button"
                onClick={() => {
                  if (verificaMotivoPreenchidos()) {
                    handleAlert();
                  } else {
                    preecherArrays();
                  }
                }}
              >
                Salvar
              </button>
            </ContainerButtonSalvar>
          </Container>
        </ContainerModal>
      </div>
      {loading && <Loading />}
    </>
  );
};

export default ModalAbonoPeriodo;
