import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 244px;
  height: 40px;

  margin-left: 40px;
  #export-excel {

    &:hover {
      background-color: #57bd42;
    }
    width: 244px;
    height: 40px;

    background-color: #006600;
    color: #ffffff;

    display: flex;
    justify-content: center;
    border-radius: 4px;
    border: none;
    align-items: center;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;

    svg {
      margin-right: 14px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;
export const ButtonExport = styled.button`
  width: 244px;
  height: 40px;

  background-color: #393b41;
  color: #ffffff;

  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: none;
  align-items: center;

  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;

  svg {
    margin-right: 14px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;
