import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

export const Container = styled.div`
  position: relative;
  padding-left: 30px;
  padding-right: 30px;

  width: 80%;
  height: 400px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;

  button {
    margin: 10px;
    height: 30px;
    width: 30px;
    color: #ffffff;
    background-color: #800000;
    border: transparent;
    border-radius: 50px;
    padding: 2px;
    margin-left: auto;
    text-align: center;
    font-size: 12px;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  svg {
    margin: 0px auto;
    height: 100px;
    width: 100px;
    color: gray;
  }
`;

export const ContainerHeaderDados = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 0px auto;
  justify-content: center;
  align-items: center;

  .titulo {
    font-weight: bold;
    margin-top: 20px;
    font-size: 24px;
  }
`;

export const ContainerSupFilter = styled.div`
  display: flex;
  width: 330px;
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: center !important;
  align-content: center !important;
`;

export const ContainerSupFilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ContainerSupFilterP = styled.p`
  font-size: 15px;
  font-family: Arial;
  margin-right: 5px;
  padding-top: 15px;
`;

export const ContainerSupFilterInput = styled.input`
  background-color: #f1f1f1;
  color: #439133;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
  padding: 10px;
  height: 40px;
  width: 120px;
`;

export const StrongBlack = styled.strong`
  color: #000000;
  margin-right: 5px;
`;

export const ContainerButtonAtualizar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;
  button {
    padding: 10px;
    background-color: #006600;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
  }
`;

export const ContainerInfFilter = styled.div`
  button {
    height: 40px;
    padding: 0px 10px;

    background-color: #439133;
    color: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
  }
`;

export const ContainerDivColumnDadosUser = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: #f2f2f2;
  padding: 10px 30px 10px 30px;
`;

export const ContainerDivRowDadosUser = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 30px 10px 30px;
`;

export const ContainerDivText = styled.div`
  display: flex;
  width: 400px;
  line-height: 15px;
`;
