import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface Acesso {
  ID: number;
  Nome: string;
  CPF: number;
  EMAIL: string;
  SetorID: number;
  Setor: string;
  NivelACesso: number;
  idUsuario: number;
  Nome_NivelAcesso: string;
}

interface User {
  ativo: number;
  avatar_url: string;
  cpf: string;
  dataNascimento: string;
  email: string;
  id: string;
  nome: string;
  telefone: string;
}

interface AuthState {
  token: string;
  user: User;
  userAcesso: Acesso[];
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  userAcesso: Acesso[];
  signIn(credentials: SignInCredentials): Promise<boolean>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    // console.log(token);
    // console.log(user);
    // console.log(userAcesso);
    const token = localStorage.getItem('@Colaborador:token');
    const user = localStorage.getItem('@Colaborador:user');
    const userAcesso = localStorage.getItem('@Colaborador:userAcesso');

    if (token && user && userAcesso) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        userAcesso: JSON.parse(userAcesso),
      };
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    const token = localStorage.removeItem('@Colaborador:token'); // eslint-disable-line
    const user = localStorage.removeItem('@Colaborador:user');// eslint-disable-line
    const userAcesso = localStorage.removeItem('@Colaborador:userAcesso');// eslint-disable-line

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(async ({ email, password }): Promise<boolean> => {
    // console.log(`Email: ${email}`);
    // console.log(`Password: ${password}`);
    // console.log('Entrou no SignIn');
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user, userAcesso } = response.data;
    // console.log(user);
    // console.log(userAcesso);
    localStorage.setItem('@Colaborador:token', token);
    localStorage.setItem('@Colaborador:user', JSON.stringify(user));
    localStorage.setItem('@Colaborador:userAcesso', JSON.stringify(userAcesso));

    // console.log(`Bearer ${token}`);
    api.defaults.headers.authorization = `Bearer ${token[1]}`;
    // teste de nivel de acesso
    // if (response.data.userAcesso[0].Setor === 'Nenhum') {
    //   signOut();
    //   return false;
    // }
    setData({ token, user, userAcesso });
    return true;
  }, []);

  const updateUser = useCallback(
    async (user: User) => {
      // console.log(user);
      localStorage.setItem('@Colaborador:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      } as AuthState);
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        userAcesso: data.userAcesso,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
