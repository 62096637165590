import React, { useState } from 'react';
import Swal from 'sweetalert2';
import api from '../../services/api';
import ModalConexao from '../../components/ModalConexao';
import { Loading } from '../../components/Loading';
import DrawerApp from '../../components/Drawer';
import ReactDatePicker from 'react-datepicker';
import {
  Container,
  ContainerPesquisaGeral,
  ContainerSupFilter,
  ContainerInfFilter,
  ContainerTable,
} from './styles';
import moment from 'moment';
import HeaderTable from '../../components/HeaderTable';
import { IBeneficio } from '../../models/Beneficios';
  

const Beneficios: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataInicial, setDataInicial] = useState(new Date());
  const [beneficios, setBeneficios] = useState<IBeneficio[]>([])

  const headers = [
    { name: 'Nome', field: 'name'},
    { name: 'Valor', field: 'value'},
  ];

  function retornarEndereco(endereco: string): string[] {
    return endereco.split(',');
  }

  function moedaReal(moeda: number): string {
    return moeda.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }


  const handleSubmit = () => {
    setLoading(true);
    api.get(
        `beneficios/${moment(dataInicial).format("YYYYMM")}`,
      )
      .then((Response) => {
        setBeneficios(Response.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false);
        setBeneficios([]);
        Swal.fire({
          icon: 'error',
          title:
            'Não foi possível encontrar os Benefícios do período informado.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      });
  };

  return (
    <Container>
      <ModalConexao />
      <DrawerApp />
      <ContainerPesquisaGeral>
        <ContainerSupFilter>
          <p style={{ marginRight: -3, marginLeft: 5 }}>Período: </p>
          <ReactDatePicker
            locale="pt-BR"
            selected={dataInicial}
            onChange={(date) => {
              // @ts-ignore
              setDataInicial(date);
              

            }}
            placeholderText="mm/aaaa"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
          <ContainerInfFilter>
            <button type="button" onClick={() => handleSubmit()}>
              Pesquisar
            </button>
          </ContainerInfFilter>
          
        </ContainerSupFilter>
        
      </ContainerPesquisaGeral>

      <ContainerTable>
        {beneficios.length > 0 && (
          <table id="tableListagemUsuariosQrCode">
          <HeaderTable headers={headers} />
          <tbody>
            { beneficios.map((item)=>{
                return item.beneficioTransporte.map((i, index)=>{
                    return (
                      <tr>
                        <td>{i}</td>
                        <td>{"R$ "+moedaReal(item.valorTransporte[index])}</td>
                      </tr>
                    );
                })
            })}
            { beneficios.map((item)=>{
                return item.descBeneficioAlimentacao.map((i, index)=>{
                    return (
                      <tr>
                        <td>{i}</td>
                        <td>{"R$ "+moedaReal(item.valorBeneficioAlimentacao[index])}</td>
                      </tr>
                    );
                })
            })}
            { beneficios.map((item)=>{
                return item.descBeneficioCesta.map((i, index)=>{
                    return (
                      <tr>
                        <td>{i}</td>
                        <td>{"R$ "+moedaReal(item.valorBeneficioCEsta[index])}</td>
                      </tr>
                    );
                })
            })}
          </tbody>
        </table>
        )}
      </ContainerTable>

      {/* <div>
        {beneficios.map(e=>{
          return (<h1>{e.CNPJ}</h1>)
        })}
      </div> */}
      {loading && <Loading />}
    </Container>
  );
};

export default Beneficios;
