import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/Signup';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import Profile from '../pages/Profile';
import ResetPassword from '../pages/ResetPassword';
import AutoDeclaracaoDeSaude from '../pages/AutoDeclaracaoDeSaude';
import AutoDeclaracaoDeSaudeAgradecimento from '../pages/AutoDeclaracaoDeSaudeAgradecimento';
import AutoDeclaracaoAnalise from '../pages/AutoDeclaracaoAnalise';
import UserAccess from '../pages/UserAccess';
import RestorePassword from '../pages/RestorePassword';
import UploadPost from '../pages/UploadPost';
import CadastroAdmissao from '../pages/CadastroAdmissao';
import ValidacaoDocumentosAdmissao from '../pages/ValidacaoDocumentoDeAdmissao';
import VisualizarPonto from '../pages/VisualizarPonto';
import ViewPonto from '../pages/VFiltroPonto';
import CadastroAbono from '../pages/CadastroAbono';
import ValidacaoDocumentosFuncionarios from '../pages/ValidacaoDocumentosDeFuncionarios';
import Ausencia from '../pages/Ausencia';
import TermoConsentimento from '../pages/Termo';
import SolicitacaoVaga from '../pages/ValidacaoSolicitacaoVaga';
import ListagemUsuariosQrCode from '../pages/ListagemUsuariosQrCode';
import DeclaracaoVacinacao from '../pages/DeclaracaoVacinacao';
import CadastroCargo from '../pages/CadastroCargo';
import AtestadosMedicos from '../pages/AtestadosMedicos';
import DecimoTerceiro from '../pages/DecimoTerceiro';
import ContraCheque from '../pages/ContraCheque';
import Beneficios from '../pages/Beneficios';
import ErrorLogs from '../pages/ErrorLogs';
import GestaoVagas from '../pages/GestaoVagas';
import { Candidaturas } from '../pages/Candidaturas';
import CurriculoShow from '../pages/CurriculoShow';

import ImpostoDeRenda from '../pages/ImpostoDeRenda';
import Indicadores from '../pages/Indicadores';
import { PagesAcesso } from '../pages/PagesAcesso';
import { DeleteAccount } from '../pages/DeleteAccount';
import { DeleteAccountMenu } from '../pages/DeleteAccountMenu';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password/" component={ResetPassword} />
    <Route
      path="/auto-declaracao-de-saude-agradecimento"
      component={AutoDeclaracaoDeSaudeAgradecimento}
      isPrivate
    />
    <Route
      path="/auto-declaracao-analise"
      component={AutoDeclaracaoAnalise}
      isPrivate
    />
    <Route path="/beneficios" component={Beneficios} isPrivate />
    <Route path="/logs" component={ErrorLogs} isPrivate />

    <Route
      path="/auto-declaracao-de-saude"
      component={AutoDeclaracaoDeSaude}
      isPrivate
    />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/reset-password" component={ResetPassword} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/user-access" component={UserAccess} isPrivate />
    <Route path="/restore-password" component={RestorePassword} isPrivate />
    <Route path="/upload-post" component={UploadPost} isPrivate />
    <Route path="/cadastro-admissao" component={CadastroAdmissao} isPrivate />
    <Route path="/decimo-terceiro" component={DecimoTerceiro} isPrivate />
    <Route
      path="/validacao-documentos-admissao"
      component={ValidacaoDocumentosAdmissao}
      isPrivate
    />
    <Route
      path="/validacao-documentos-funcionarios"
      component={ValidacaoDocumentosFuncionarios}
      isPrivate
    />
    <Route path="/solicitacao-vaga" component={SolicitacaoVaga} isPrivate />
    <Route path="/visualizar-ausencia" component={Ausencia} isPrivate />
    <Route
      path="/visualizar-atestados-medicos"
      component={AtestadosMedicos}
      isPrivate
    />
    <Route path="/visualizar-ponto" component={VisualizarPonto} isPrivate />
    <Route path="/view-ponto" component={ViewPonto} isPrivate />
    <Route path="/cadastro-abono" component={CadastroAbono} isPrivate />
    <Route path="/termo-de-consentimento" component={TermoConsentimento} />
    <Route
      path="/list-users-qrcode"
      component={ListagemUsuariosQrCode}
      isPrivate
    />
    <Route
      path="/declaracao-vacinacao"
      component={DeclaracaoVacinacao}
      isPrivate
    />
    <Route path="/cadastro-cargo" component={CadastroCargo} isPrivate />
    <Route path="/contra-cheque" component={ContraCheque} isPrivate />
    <Route path="/imposto-de-renda" component={ImpostoDeRenda} isPrivate />
    <Route exact path="/vagas" component={GestaoVagas} isPrivate />
    <Route path="/curriculos" component={CurriculoShow} isPrivate />
    <Route path="/vagas/:id/candidaturas" component={Candidaturas} isPrivate />
    <Route path="/indicadores" component={Indicadores} isPrivate />
    <Route path="/pages-acesso" component={PagesAcesso} isPrivate />
    <Route path="/apagar-conta" component={DeleteAccountMenu} isPrivate />
    <Route path="/deletar-conta" component={DeleteAccount} />
  </Switch>
);

export default Routes;
