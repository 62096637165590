import React, { useState, useEffect } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { ContainerAlert } from './styles';

interface AlertEmailInterface {
  email: string;
  // onEmail: (arg: boolean) => void;
}

const AlertEmail: React.FC<AlertEmailInterface> = ({ email }) => {
  const [isUpper, setIsUpper] = useState(false);
  const [textAlert, setTextAlert] = useState<string>();

  useEffect(() => {
    if (email !== email.toLowerCase()) {
      setIsUpper(true);
      setTextAlert(
        'Seu e-mail possui letra maiúscula, por favor verifique se está correto!',
      );
    } else if (email.match(/[áàãâäéèêëíìîïóòõôöúùûü]/)) {
      setIsUpper(true);
      setTextAlert(
        'Seu e-mail possui acento, por favor verifique se está correto!',
      );
    } else if (email.match(/[ç]/)) {
      setIsUpper(true);
      setTextAlert(
        'Seu e-mail possui "ç", por favor verifique se está correto!',
      );
    } else {
      setIsUpper(false);
    }
  }, [email]);
  return (
    <>
      {' '}
      {isUpper && (
        <ContainerAlert>
          <FiAlertCircle size={40} />
          <p>{textAlert}</p>
        </ContainerAlert>
      )}
    </>
  );
};
export default AlertEmail;
