import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import {
  FiMail,
  FiUser,
  FiLock,
  FiPhone,
  FiCamera,
  FiCalendar,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ModalConexao from '../../components/ModalConexao';
import { useAuth } from '../../hooks/auth';
import avatar from '../../assets/avatar2.png';
import { maskTel, maskDateOfBirth } from '../../components/Input/mask';
import AlertEmail from '../../components/AlertEmail';
import DrawerApp from '../../components/Drawer';

import { Container, Content, AnimationContainer } from './styles';

interface SignUpFormData {
  nome: string;
  email: string;
  dataNascimento: string;
  telefone: string;
  passwordOld: string;
  password: string;
  confirmarSenha: string;
}

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState(user.nome);
  const [email, setEmail] = useState(user.email);
  const [telefone, setTelefone] = useState(user.telefone);

  function formatarData(data: string): string {
    const ano = data.substring(0, 4);
    const mes = data.substring(5, 7);
    const dia = data.substring(8, 10);
    return `${dia}-${mes}-${ano}`;
  }
  const [dataNascimento, setDataNascimento] = useState<string>(
    user.dataNascimento ? formatarData(user.dataNascimento) : '',
  );

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório!'),
          email: Yup.string()
            .required('E-mail obrigatório!')
            .email('Digite um email válido!'),
          passwordOld: Yup.string(),
          password: Yup.string().when('passwordOld', {
            is: (val) => !!val.length,
            then: Yup.string().required('Campo Obrigatório'),
            otherwise: Yup.string(),
          }),
          confirmarSenha: Yup.string()
            .when('passwordOld', {
              is: (val) => !!val.length,
              then: Yup.string().required('Campo Obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'Confirmação Incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { passwordOld, password, confirmarSenha } = data;

        const dataNasc = data.dataNascimento
          ? `${data.dataNascimento
              .trim()
              .substr(6, 4)}-${data.dataNascimento
              .trim()
              .substr(3, 2)}-${data.dataNascimento.trim().substr(0, 2)}`
          : '';
        // console.log(user);

        await api
          .put('/profile', {
            user_id: user.id,
            name: nome.trim(),
            email: email.trim(),
            ...(telefone ? { telefone } : {}),
            ...(data.dataNascimento ? { dataNascimento: dataNasc } : {}),
            ...(passwordOld
              ? {
                  oldPassword: passwordOld.trim(),
                  password: password.trim(),
                  password_confirmation: confirmarSenha.trim(),
                }
              : {}),
          })
          .then(() => {
            user.nome = nome.trim();
            user.email = email.trim();
            telefone && (user.telefone = telefone);
            dataNasc && (user.dataNascimento = dataNasc);
            updateUser(user);
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Dados Atualizados!',
            });
            // console.log('Sucess!');
            const timer = setTimeout(() => {
              history.go(0);
            }, 500);
            return () => clearTimeout(timer);
          })
          .catch((error) => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro ao Atualizar!',
              description: `${error.response.data.errors}`,
            });
          });
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            'Ocorreu um erro ao fazer o Cadastro, Por favor verifique os campos.',
        });
      } finally {
        // setLoading(false);
      }
    },
    [user, nome, email, telefone, updateUser, addToast, history],
  );
  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setLoading(true);
        const data = new FormData();
        data.append('avatar', e.target.files[0]);
        data.append('user_id', user.id);
        api
          .patch('/users/avatar', data)
          .then((response) => {
            updateUser(response.data);
            Swal.fire({
              icon: 'success',
              title: 'Foto salva com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setLoading(false);
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao salvar a Foto!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setLoading(false);
          });
      }
    },
    [updateUser, user.id],
  );

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      {/* <Header /> */}
      <Container>
        <Content>
          <AnimationContainer>
            <div className="ContainerImage">
              <h1>Perfil</h1>
              <img
                src={user.avatar_url ? user.avatar_url : avatar}
                style={{ objectFit: 'cover' }}
                alt="Imagem profile"
              />
              <label htmlFor="avatar">
                <FiCamera />
                <input type="file" id="avatar" onChange={handleAvatarChange} />
              </label>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="nome"
                icon={FiUser}
                placeholder="Nome Completo"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <AlertEmail email={email} />
              <Input
                name="dataNascimento"
                icon={FiCalendar}
                placeholder="Data de Nascimento"
                value={maskDateOfBirth(dataNascimento)}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask="dateOfBirth"
              />
              <Input
                name="telefone"
                icon={FiPhone}
                placeholder="Telefone"
                mask="phone"
                value={telefone}
                onChange={(e) => setTelefone(maskTel(e.target.value))}
              />
              <div className="ContainerSenhas">
                <h3>Opcional</h3>
                <Input
                  name="passwordOld"
                  icon={FiLock}
                  tipo="password"
                  placeholder="Senha Atual"
                />
                <Input
                  name="password"
                  icon={FiLock}
                  tipo="password"
                  placeholder="Nova Senha"
                />
                <Input
                  name="confirmarSenha"
                  icon={FiLock}
                  tipo="password"
                  placeholder="Confimar Senha"
                />
              </div>
              <Button type="submit">Atualizar</Button>
            </Form>
          </AnimationContainer>
        </Content>
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default Profile;
