import React from 'react';
import 'react-day-picker/lib/style.css';
// import Swal from 'sweetalert2';
import ModalConexao from '../../components/ModalConexao';
import DrawerApp from '../../components/Drawer';
import { Container } from './styles';
import logoImg from '../../assets/logo.png';

// const setores = [{ valor: 'Nenhum', id: 1, NivelAcesso: 1 }];

const Dashboard: React.FC = () => {
  // const { signOut, userAcesso } = useAuth();

  // useEffect(() => {
  //   let semAcesso = false;
  //   let access;
  //   userAcesso.forEach((user) => {
  //     access = setores.filter(
  //       (setor) =>
  //         setor.id === user.SetorID && user.NivelACesso === setor.NivelAcesso,
  //     );
  //     if (access.length > 0) {
  //       semAcesso = true;
  //     }
  //   });
  //   if (semAcesso) {
  //     Swal.fire({
  //       icon: 'info',
  //       title:
  //         'Usuário não tem acesso ao Sistema. Por favor entre em contato com o administrador do Sistema.',
  //       showConfirmButton: false,
  //       timer: 4000,
  //     });
  //     const vis = setTimeout((): void => {
  //       signOut();
  //     }, 10);
  //     return () => {
  //       clearTimeout(vis);
  //     };
  //     // console.log("saindo");
  //   }
  // }, [signOut, userAcesso]);

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      <Container>
        <header>
          <img src={logoImg} alt="Logo Servnac" />
        </header>
      </Container>
    </>
  );
};

export default Dashboard;
