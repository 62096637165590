import styled from 'styled-components';

export const ContainerAlert = styled.div`
  background: #232129;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;

  border: 2px solid #232129;

  display: flex;
  align-items: center;
  color: #cc7a00;
  border-color: #cc7a00;

  svg {
    margin-right: 16px;
  }
  p {
    margin: 0;
    font-size: 14px;
  }
`;
