import React, { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from '../../components/Pagination';
import Search from '../../components/Search';
import HeaderTable from '../../components/HeaderTable';
import DrawerApp from '../../components/Drawer';
import DropDown from '../../components/DropDown';
import api from '../../services/api';
import { maskCpf } from '../../components/Input/mask';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import ModalConexao from '../../components/ModalConexao';

import { Container, ContainerTable, ContainerBusca } from './styles';

interface PersonGet {
  RA_FILIAL: string;
  RA_NOMECMP: string;
  RA_CIC: string;
  IDADE: number;
  ABS_DESCRI: string;
  ID: number;
  NOME: string;
  EMAIL: string;
  CPF: string;
  DATA_NASCIMENTO: string;
  DOENCA_PULMONAR: number;
  DOENCA_CARDIOVASCULERES: number;
  DIABETE_INSULINO_DEPENDENTE: number;
  HIPERTENSAO: number;
  DOENCA_RENAL_CRONICA: number;
  DOENCA_ONCOLOGICA: number;
  SISTEMA_IMUNOLOGICO_ENFRAQUECIDO: number;
  OBESIDADE_MORBIDA: number;
  DOENCA_NEOPLASIAS_MALIGNAS: number;
  ASMA_GRAVE: number;
  OUTRAS_ENFERMIDADES: number;
  JUSTIFICAR_OUTRAS_ENFERMIDADES: string;
  USO_MEDICAMENTOS_IMUNOSSUPRESSORES: number;
  GESTANTE: number;
  EH_LACTANTE: number;
  TESTOU_POSITIVO_COVID19: number;
  DATA_INICIAL_TESTOU_POSITIVO_COVID19: string;
  DATA_FINAL_TESTOU_POSITIVO_COVID19: string;
  TEVE_RESIDENTE_POSITIVO_COVID19: number;
  DATA_INICIAL_TEVE_RESIDENTE_POSITIVO_COVID19: string;
  DATA_FINAL_TEVE_RESIDENTE_POSITIVO_COVID19: string;
  DECLAROU: number;
}

const setores = [
  { valor: 'Administrativo', id: 7, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const AutoDeclaracaoAnalise: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const [comments, setComments] = useState<PersonGet[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [porcentagem, setPorcentagem] = useState(0);
  const [loading, setLoading] = useState(false);

  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '30', id: 30 },
    { valor: '40', id: 40 },
    { valor: '50', id: 50 },
    { valor: 'todos', id: 1 },
  ];
  const headers = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Data Nascimento', field: 'dataNascimento' },
    { name: 'Declarou', field: 'declarou' },
    { name: 'Doença Pulmonar', field: 'doencaPulmonar' },
    { name: 'Doença Cardiovasculares', field: 'doencaCardiovasculares' },
    {
      name: 'Diabete Dependente de Insulina',
      field: 'diabeteInsulinaDependente',
    },
    { name: 'Hipertensão', field: 'hipertensao' },
    { name: 'Doença Renal Crônica', field: 'doencaRenalCronica' },
    { name: 'Doença Oncológica', field: 'doencaOncologica' },
    {
      name: 'Sistema Imunológico Enfraquecido',
      field: 'sistemaImunologicoEnfraquecico',
    },
    { name: 'Obesidade Morbida', field: 'obesidadeMorbida' },
    { name: 'Doença Neoplasias Malignas', field: 'doencaNeoplasiasMalignas' },
    { name: 'Asma Grave', field: 'asmaGrave' },
    { name: 'Outras Enfermidades', field: 'outrasEnfermidades' },
    {
      name: 'Justificar Outras Enfermidades',
      field: 'justificarOutrasEnfermidades',
    },
    {
      name: 'Uso de Medicamentos Imunossupressores',
      field: 'medicamentosImunossupressores',
    },
    { name: 'Gestante', field: 'gestante' },
    { name: 'Lactante', field: 'lactante' },
    { name: 'Testou Positivo Covid-19', field: 'positivoCovid19' },
    {
      name: 'Data Inicial, Testou Positivo Covid-19',
      field: 'dataInicialPositivo',
    },
    {
      name: 'Data Final, Testou Positivo Covid-19',
      field: 'datafinalPositivo',
    },
    {
      name: 'Teve Residente com Teste Positivo Covid-19',
      field: 'residenteTestePositivo',
    },
    {
      name: 'Data Inicial, Residente Testou Positivo Covid-19',
      field: 'dataInicialResidentePositivo',
    },
    {
      name: 'Data Final, Residente Testou Positivo Covid-19',
      field: 'dataFinalResidentePositivo',
    },
  ];

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (s) => s.id === user.SetorID && user.NivelACesso >= s.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      setLoading(true);
      api
        .get(`seducapp/usuarios`, {
          onDownloadProgress(progress) {
            setPorcentagem((progress.loaded * 100) / progress.total);
          },
        })
        .then((response) => {
          setComments(response.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao Carregar dados! Tente Regarregar a Página.',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }
  }, [history, userAcesso]);

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter((comment: PersonGet) =>
        comment.RA_NOMECMP.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedComments = computedComments.filter((comment: PersonGet) =>
        comment.RA_CIC.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    setTotalItems(computedComments.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedComments;
    }

    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [comments, currentPage, search, searchCPF, ITEMS_PER_PAGE]);

  function formatarData(data: string): string {
    const dia = data.split('-')[2];
    const mes = data.split('-')[1];
    const ano = data.split('-')[0];

    return `${dia}/${mes}/${ano}`;
  }

  return (
    <>
      <ModalConexao />
      <DrawerApp />
      <ContainerBusca>
        <Search
          onSearch={(value: string) => {
            setSearch(value);
            setCurrentPage(1);
          }}
          nomePlaceHolder="Buscar Nome"
        />
        <Search
          onSearch={(value: string) => {
            setSearchCPF(value);
            setCurrentPage(1);
          }}
          nomePlaceHolder="Buscar CPF"
        />
      </ContainerBusca>
      <ContainerTable>
        <table id="autoDeclaracaoAnalise">
          <HeaderTable headers={headers} />
          <tbody>
            {commentsData.map((comment: PersonGet) => (
              <tr key={comment.RA_CIC}>
                <td style={{ minWidth: 300 }}>{comment.RA_NOMECMP}</td>
                <td>{maskCpf(comment.RA_CIC)}</td>
                <td>
                  {comment.DATA_NASCIMENTO === null
                    ? '-'
                    : formatarData(comment.DATA_NASCIMENTO)}
                </td>
                <td>
                  {comment.DECLAROU === 1
                    ? 'sim'
                    : comment.DECLAROU === 0
                    ? 'sim'
                    : comment.DECLAROU === null
                    ? 'não'
                    : 'não'}
                </td>
                <td>{comment.DOENCA_PULMONAR === 1 ? 'sim' : 'não'}</td>
                <td>{comment.DOENCA_CARDIOVASCULERES === 1 ? 'sim' : 'não'}</td>
                <td>
                  {comment.DIABETE_INSULINO_DEPENDENTE === 1 ? 'sim' : 'não'}
                </td>
                <td>{comment.HIPERTENSAO === 1 ? 'sim' : 'não'}</td>
                <td>{comment.DOENCA_RENAL_CRONICA === 1 ? 'sim' : 'não'}</td>
                <td>{comment.DOENCA_ONCOLOGICA === 1 ? 'sim' : 'não'}</td>
                <td>
                  {comment.SISTEMA_IMUNOLOGICO_ENFRAQUECIDO === 1
                    ? 'sim'
                    : 'não'}
                </td>
                <td>{comment.OBESIDADE_MORBIDA === 1 ? 'sim' : 'não'}</td>
                <td>
                  {comment.DOENCA_NEOPLASIAS_MALIGNAS === 1 ? 'sim' : 'não'}
                </td>
                <td>{comment.ASMA_GRAVE === 1 ? 'sim' : 'não'}</td>
                <td>{comment.OUTRAS_ENFERMIDADES === 1 ? 'sim' : 'não'}</td>
                <td>
                  {comment.JUSTIFICAR_OUTRAS_ENFERMIDADES === null
                    ? '-'
                    : comment.JUSTIFICAR_OUTRAS_ENFERMIDADES === ''
                    ? '-'
                    : comment.JUSTIFICAR_OUTRAS_ENFERMIDADES === '3'
                    ? '-'
                    : comment.JUSTIFICAR_OUTRAS_ENFERMIDADES}
                </td>
                <td>
                  {comment.USO_MEDICAMENTOS_IMUNOSSUPRESSORES === 1
                    ? 'sim'
                    : 'não'}
                </td>
                <td>{comment.GESTANTE === 1 ? 'sim' : 'não'}</td>
                <td>{comment.EH_LACTANTE === 1 ? 'sim' : 'não'}</td>
                <td>{comment.TESTOU_POSITIVO_COVID19 === 1 ? 'sim' : 'não'}</td>
                <td>
                  {comment.DATA_INICIAL_TESTOU_POSITIVO_COVID19 === null
                    ? '-'
                    : comment.DATA_INICIAL_TESTOU_POSITIVO_COVID19 === ''
                    ? '-'
                    : comment.DATA_INICIAL_TESTOU_POSITIVO_COVID19 === '3'
                    ? '-'
                    : formatarData(
                        comment.DATA_INICIAL_TESTOU_POSITIVO_COVID19,
                      )}
                </td>
                <td>
                  {comment.DATA_FINAL_TESTOU_POSITIVO_COVID19 === null
                    ? '-'
                    : comment.DATA_FINAL_TESTOU_POSITIVO_COVID19 === ''
                    ? '-'
                    : comment.DATA_FINAL_TESTOU_POSITIVO_COVID19 === '3'
                    ? '-'
                    : formatarData(comment.DATA_FINAL_TESTOU_POSITIVO_COVID19)}
                </td>
                <td>
                  {comment.TEVE_RESIDENTE_POSITIVO_COVID19 === 1
                    ? 'sim'
                    : 'não'}
                </td>
                <td>
                  {comment.DATA_INICIAL_TEVE_RESIDENTE_POSITIVO_COVID19 === null
                    ? '-'
                    : comment.DATA_INICIAL_TEVE_RESIDENTE_POSITIVO_COVID19 ===
                      ''
                    ? '-'
                    : comment.DATA_INICIAL_TEVE_RESIDENTE_POSITIVO_COVID19 ===
                      '3'
                    ? '-'
                    : formatarData(
                        comment.DATA_INICIAL_TEVE_RESIDENTE_POSITIVO_COVID19,
                      )}
                </td>
                <td>
                  {comment.DATA_FINAL_TEVE_RESIDENTE_POSITIVO_COVID19 === null
                    ? '-'
                    : comment.DATA_FINAL_TEVE_RESIDENTE_POSITIVO_COVID19 === ''
                    ? '-'
                    : comment.DATA_FINAL_TEVE_RESIDENTE_POSITIVO_COVID19 === '3'
                    ? '-'
                    : formatarData(
                        comment.DATA_FINAL_TEVE_RESIDENTE_POSITIVO_COVID19,
                      )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>
      <Container>
        <Pagination
          total={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentPage={currentPage}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
        <DropDown
          onChangeItems={(value: string) => {
            setCurrentPage(1);
            if (value === 'todos') {
              setITEMS_PER_PAGE(1);
            } else {
              setITEMS_PER_PAGE(Number(value));
            }
          }}
          objetoEnum={dropDown}
          minWidth={30}
        />

        <ReactHTMLTableToExcel
          id="export-excel"
          className="btn"
          table="autoDeclaracaoAnalise"
          filename="auto-declaracao-analise"
          sheet="tablexls"
          buttonText="Exportar Excel"
        />
        {loading && <Loading porcentagem={porcentagem} />}
      </Container>
    </>
  );
};

export default AutoDeclaracaoAnalise;
