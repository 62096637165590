import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FiUser, FiFileMinus, FiPhone, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import api from '../../services/api';

import getValidationErrors from '../../utils/getValidationErrors';
import ModalConexao from '../../components/ModalConexao';
import Input from '../../components/Input';
import Button from '../../components/Button';
import DrawerApp from '../../components/Drawer';

import {
  Content,
  AnimationContainer,
  ContainerInput,
  ContainerCodigo,
} from './styles';

interface SignUpFormData {
  nome: string;
  cpf: string;
  telefone: string;
  code: string;
}

interface ResponseGetOffice {
  ID: number;
  NOME: string;
}

const setores = [
  { valor: 'Recursos Humanos', id: 13, NivelAcesso: 1 },
  { valor: 'Todos', id: 15, NivelAcesso: 1 },
];

const CadastroAdmissao: React.FC = () => {
  const { userAcesso } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [nomeInput, setNomeInput] = useState('');
  const [cpfInput, setCpfInput] = useState('');
  const [responsePositions, setResponsePositions] = useState<
    ResponseGetOffice[]
  >([]);
  const [cargoSelect, setCargoSelect] = useState<number>(0);

  const [selectSelecionado, setSelectSelecionado] = useState(false);

  useEffect(() => {
    let semAcesso = true;
    let access;
    userAcesso.forEach((user) => {
      access = setores.filter(
        (setor) =>
          setor.id === user.SetorID && user.NivelACesso >= setor.NivelAcesso,
      );
      if (access.length > 0) {
        semAcesso = false;
      }
    });
    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title:
          'Seu nível de acesso não tem permissão para acessar essa página!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [history, userAcesso]);

  useEffect(() => {
    setLoading(true);

    async function getItems(): Promise<void> {
      try {
        const dataPositions = await api.get('cargos/');

        setResponsePositions(dataPositions.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);

        Swal.fire({
          icon: 'info',
          title: 'Listagem de cargos vazia!',
          text:
            'Favor realizar o primeiro cadastro. Caso o erro persista, atualize a página.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    }

    getItems();
  }, []);

  function formatCPF(str: string): string {
    const CPF: string = str.replace('.', '').replace('.', '').replace('-', '');
    return CPF;
  }

  function formatTelefone(str: string): string {
    const telefone: string = str
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
      .replace(' ', '');
    return telefone;
  }

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório !'),
          cpf: Yup.string().required('CPF obrigatório !'),
          telefone: Yup.string().required('Telefone obrigatório !'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { nome } = data;
        let { cpf } = data;
        let { telefone } = data;
        const code = nomeInput;

        cpf = formatCPF(cpf);
        telefone = formatTelefone(telefone);

        await api
          .post('/preCadastro/insertFuncionario', {
            nome,
            cpf,
            telefone,
            idCargo: cargoSelect,
            codigoValidacao: code,
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Usuário pré-cadastrado com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setHiddenButton(true);
          })
          .catch((e) => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: `${e.response.data.errors}`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao pré-cadastrar usuário!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } finally {
        // setLoading(false);
      }
    },
    [cargoSelect, nomeInput],
  );

  function hashCode(str: string): number {
    return str.split('').reduce(
      (prevHash, currVal) =>
        // eslint-disable-next-line no-bitwise
        (prevHash << 5) - prevHash + currVal.charCodeAt(0) || 0,
      0,
    );
  }

  const handleGerarCodigo = useCallback(() => {
    if (nomeInput === '' || cpfInput === '' || cargoSelect === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Campos Nome, CPF e Cargo são obrigatórios!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setHiddenButton(true);
      return;
    }
    let hash: string = cpfInput.slice(4, 7);
    hash = nomeInput + hash;
    hash = hashCode(hash).toString();
    if (hash[0] === '-') {
      hash = hash.replace('-', 'A').replace('1', 'E').replace('5', 'C');
    } else {
      hash = hash.replace('2', 'D').replace('6', 'F').replace('9', 'H');
    }
    if (hash.length >= 9) {
      hash = hash.substring(0, 8);
    }
    setNomeInput(hash);
  }, [nomeInput, cpfInput, cargoSelect]);

  return (
    <>
      <DrawerApp />
      <ModalConexao />
      <Content>
        <AnimationContainer>
          <h1>Cadastro de Admissão</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <ContainerInput select={selectSelecionado}>
              <div className="divInput">
                <Input
                  name="nome"
                  icon={FiUser}
                  placeholder="Nome Completo"
                  onValue={(value) => setNomeInput(value)}
                />
              </div>
              <div className="divInput">
                <Input
                  name="cpf"
                  icon={FiFileMinus}
                  placeholder="CPF"
                  mask="cpf"
                  onValue={(value) => setCpfInput(value)}
                />
              </div>
              <div className="divInput">
                <Input
                  name="telefone"
                  icon={FiPhone}
                  placeholder="Telefone"
                  mask="phone"
                />
              </div>
              <div className="divInput">
                <select
                  name="cargo"
                  className="selectCargo"
                  onFocus={() => setSelectSelecionado(true)}
                  onBlur={() => setSelectSelecionado(false)}
                  onChange={(e) =>
                    setCargoSelect(
                      parseInt(e.currentTarget.value.split('-')[0], 10),
                    )
                  }
                >
                  {responsePositions.map((cargo, index) => {
                    return (
                      <>
                        {index === 0 && (
                          <option disabled selected>
                            Cargo
                          </option>
                        )}

                        <option key={cargo.ID}>
                          {`${cargo.ID} - ${cargo.NOME}`}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              {hiddenButton && (
                <button
                  type="button"
                  onClick={() => {
                    setHiddenButton(false);
                    handleGerarCodigo();
                  }}
                >
                  Gerar Código
                </button>
              )}
              {!hiddenButton && (
                <ContainerCodigo>
                  <label htmlFor="codigo" className="divInput">
                    <FiLock size={20} />
                    {nomeInput}
                  </label>
                  <div>
                    <Button type="submit">Cadastrar</Button>
                  </div>
                </ContainerCodigo>
              )}
            </ContainerInput>
          </Form>
        </AnimationContainer>
      </Content>
      {loading && <Loading />}
    </>
  );
};

export default CadastroAdmissao;
