import React, { useRef, useCallback, useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Loading } from '../../components/Loading';
// import { LoadingTime } from '../../components/LoadingTime';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import ModalConexao from '../../components/ModalConexao';
import logoImg from '../../assets/logo.png';
import Input from '../../components/Input';
import Button from '../../components/Button';
import AlertEmail from '../../components/AlertEmail';

import { Container, Content, AnimationContainer } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>('');
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido!'),
          password: Yup.string().required('Senha Obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        // console.log(data.email);
        // console.log(data.password);
        await signIn({
          email: data.email,
          password: data.password,
        })
          .then((a) => {
            setLoading(false);
            if (!a) {
              Swal.fire({
                icon: 'info',
                title:
                  'Usuário não tem acesso ao Sistema. Por favor entre em contato com o administrador do Sistema.',
                showConfirmButton: true,
                timer: 4000,
              });
            } else {
              history.go(0);
            }

            // history.push('/dashboard');
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description:
                'Ocorreu um erro ao fazer login, cheque as credenciais.',
            });
          });

        // console.log('Chegou aqui e tals');
        // history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setLoading(false);
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="Logo Servnac" />
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Login</h1>

              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <AlertEmail email={email} />
              <Input
                name="password"
                icon={FiLock}
                tipo="password"
                placeholder="Senha"
              />

              <Button type="submit">Entrar</Button>

              <Link to="forgot-password">Esqueci minha senha</Link>
            </Form>

            <Link to="/signup">
              <FiLogIn />
              Criar conta
            </Link>
          </AnimationContainer>
        </Content>
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default SignIn;
