import styled from 'styled-components';

export const Container = styled.div`
  max-width: auto;
  display: flex;
  padding: 0px 20px;
  justify-content: start;
  align-items: center;

  .btn {
    height: 40px;
    background-color: #006600;
    color: #ffffff;
    border-width: 0px;
    padding: 10px;
    margin-left: 40px;
    border-radius: 7px;
    margin-top: 2px;
  }
`;

export const ContainerTable = styled.div`
  padding: 0px 20px 20px;
  align-items: center;
  justify-content: center;
  max-width: 98%;
  max-height: 60vh;
  overflow-x: auto; /** table fica do tamanho da tela com scroll. */
  table {
    border-collapse: collapse;
    border: 1px solid #666666;
    border-top: none;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #004d00;
      color: #ffffff;
      min-width: 200px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    border-bottom: 2px solid #666666;
    td {
      border-bottom: 1px solid #004600;
      margin: 20px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
    }
    tr {
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerBusca = styled.div`
  max-width: auto;
  display: flex;
  padding: 0px 20px;
  justify-content: initial;
  margin-top: 20px;
`;
