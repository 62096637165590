import React, { useCallback, useState, useRef } from 'react';
import Swal from 'sweetalert2';
// import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { Loading } from '../../../components/Loading';
import {
  ContainerModal,
  Container,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerSupFilter,
  ContainerInfFilter,
  ContainerSupFilterDiv,
  ContainerSupFilterP,
  ContainerSupFilterInput,
  StrongBlack,
  ContainerDivColumnDadosUser,
  ContainerDivRowDadosUser,
  ContainerDivText,
} from './styles';

import { maskCpf } from '../../../components/Input/mask';

interface ModalConexao {
  onClose?(): void;
}

interface ResponseFindUser {
  CNPJ: string;
  CPF: string;
  EMAIL: string;
  CPFSEMFORMATO: string;
  EMPRESA: string;
  NOME: string;
  MATRICULA: string;
  NOME_EMPRESA: string;
  Telefone: string;
}

interface FindUser {
  user: ResponseFindUser[];
}

const Modal: React.FC<ModalConexao> = ({
  onClose = () => {
    ('');
  },
}) => {
  // const [conexao, setConexao] = useState(navigator.onLine);
  const [whatMask, setWhatMask] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [findUserObj, setFindUserObj] = useState<ResponseFindUser[]>([]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        // setConexao(true);
        onClose();
      }
    },
    [onClose],
  );

  const handleInputChange = useCallback((e) => {
    setWhatMask(maskCpf(e.target.value));
  }, []); // eslint-disable-line

  const handleFindUser = useCallback(() => {
    console.log(whatMask);
    console.log(whatMask.length);
    if (whatMask.length < 14) {
      Swal.fire({
        icon: 'info',
        text: 'O CPF devem conter 11 caracteres.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
    setLoading(true);
    const cpf = whatMask
      .replace('.', '')
      .replace('.', '')
      .replace('.', '')
      .replace('-', '');
    console.log(cpf);
    api
      .get(`/pontoEletronico/getFindUsersPontoEletronico/${cpf}`)
      .then((dados) => {
        console.log('THEN');
        console.log(dados.data);
        setLoading(false);
        setFindUserObj(dados.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        Swal.fire({
          icon: 'info',
          text: 'Erro ao consultar CPF.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      });
  }, [whatMask]);

  return (
    <>
      <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
        <Container>
          {/* <ContainerButton>
            <button
              type="button"
              onClick={() => {
                setConexao(true);
                onClose();
              }}
            >
              X
            </button>
          </ContainerButton> */}
          <ContainerHeaderDados>
            <p className="titulo">Procurar Usuário</p>
          </ContainerHeaderDados>

          <ContainerHeaderDados>
            <ContainerSupFilter>
              <ContainerSupFilterP>*CPF: </ContainerSupFilterP>
              <ContainerSupFilterDiv>
                <ContainerSupFilterInput
                  ref={inputRef}
                  placeholder="000.000.000-00"
                  onChange={(eForChange) => {
                    handleInputChange(eForChange);
                  }}
                  value={whatMask}
                  style={{ width: '100%' }}
                />
                <ContainerInfFilter>
                  <button type="button" onClick={() => handleFindUser()}>
                    Procurar
                  </button>
                </ContainerInfFilter>
              </ContainerSupFilterDiv>
            </ContainerSupFilter>
          </ContainerHeaderDados>

          {findUserObj !== undefined && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-evenly',
                alignContent: 'flex-evenly',
              }}
            >
              {findUserObj.map((user) => (
                <ContainerDivColumnDadosUser key={user.CPFSEMFORMATO}>
                  <ContainerDivRowDadosUser>
                    <ContainerDivText>
                      <StrongBlack>Filial: </StrongBlack> {user.EMPRESA}
                    </ContainerDivText>
                    <ContainerDivText>
                      <StrongBlack>Empresa: </StrongBlack>
                      {user.NOME_EMPRESA.trim()}
                    </ContainerDivText>
                    <ContainerDivText>
                      <StrongBlack>CNPJ: </StrongBlack> {user.CNPJ}
                    </ContainerDivText>
                  </ContainerDivRowDadosUser>
                  <ContainerDivRowDadosUser>
                    <ContainerDivText>
                      <StrongBlack>Matricula: </StrongBlack> {user.MATRICULA}
                    </ContainerDivText>
                    <ContainerDivText>
                      <StrongBlack>Nome: </StrongBlack> {user.NOME.trim()}
                    </ContainerDivText>
                    <ContainerDivText>
                      <StrongBlack>CPF: </StrongBlack> {user.CPF}
                    </ContainerDivText>
                  </ContainerDivRowDadosUser>
                  <ContainerDivRowDadosUser>
                    <ContainerDivText>
                      <StrongBlack>E-mail: </StrongBlack> {user.EMAIL.trim()}
                    </ContainerDivText>
                    <ContainerDivText>
                      <StrongBlack />
                    </ContainerDivText>
                    <ContainerDivText>
                      <StrongBlack>Telefone: </StrongBlack> {user.Telefone}
                    </ContainerDivText>
                  </ContainerDivRowDadosUser>
                </ContainerDivColumnDadosUser>
              ))}
            </div>
          )}

          <ContainerButtonAtualizar>
            <button type="button" onClick={onClose}>
              Fechar
            </button>
          </ContainerButtonAtualizar>
          {loading && <Loading />}
        </Container>
      </ContainerModal>
    </>
  );
};

export default Modal;
